import { useEffect } from 'react'
import {STAGING_DOMAIN, DEV_DOMAIN, DOMAIN, FIELD_TYPES, FILES_URL} from 'config'
import image_placeholder from 'assets/anymeets_placeholder.svg'
import image_placeholder_wide from 'assets/anymeets_placeholder_wide.svg'
import {eachDayOfInterval, isSameDay} from "date-fns"
import parseISO from "date-fns/parseISO"
import {palette} from 'theme'

export const deserializeToken = jwt => {
  return JSON.parse(atob(jwt
    .split('.')[1]
    .replace('-', '+')
    .replace('_', '/')
  ))
}

if (!String.prototype.format) {
  // eslint-disable-next-line
  String.prototype.format = function() {
    let str = this.toString()
    if (!arguments.length) return str;
    let args = typeof arguments[0]
    args = (("string" === args || "number" === args) ? arguments : arguments[0]);
    for (let arg in args)
      str = str.replace(RegExp("\\{" + arg + "\\}", "gi"), args[arg]);
    return str;
  }
}

/**
 * Calculate the center/average of multiple GeoLocation coordinates
 * Expects an array of objects with .latitude and .longitude properties
 *
 * @url http://stackoverflow.com/a/14231286/538646
 */
export const averageGeolocation = (coords) => {
  if (coords.length === 1) {
    return coords[0]
  }

  let x = 0.0
  let y = 0.0
  let z = 0.0

  for (let coord of coords) {
    let latitude = coord.lat * Math.PI / 180
    let longitude = coord.lng * Math.PI / 180

    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }

  let total = coords.length

  x = x / total
  y = y / total
  z = z / total

  let centralLongitude = Math.atan2(y, x)
  let centralSquareRoot = Math.sqrt(x * x + y * y)
  let centralLatitude = Math.atan2(z, centralSquareRoot)

  return {
    lat: centralLatitude * 180 / Math.PI,
    lng: centralLongitude * 180 / Math.PI
  }
}

export const imageFile = (filename, wide=false) => {
  if (filename) return `${FILES_URL}/${filename}`
  return wide?image_placeholder_wide:image_placeholder
}

export const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export const whichDayInInterval = (startDate, endDate, date) => {
  const days = eachDayOfInterval({start: parseISO(startDate), end: parseISO(endDate)})
  const day = parseISO(date)
  return days.findIndex((d)=>isSameDay(d,day)) + 1
}
export const lightOrDark = (color) => {

  // Variables for red, green, blue values
  let r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    // eslint-disable-next-line no-mixed-operators
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp<128.8) {

    return 'white';
  }
  else {

    return palette['greyish-brown'];
  }
}
export const filterList = (q, fields, list) => {

  if (!list) return []

  function escapeRegExp(s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }
  const words = q
    .split(/\s+/g)
    .map(s => s.trim())
    .filter(s => !!s);

  const hasTrailingSpace = q.endsWith(" ");
  const searchRegex = new RegExp(
    words
      .map((word, i) => {
        //checking if word is greek

        const Regex = /^[Α-Ωα-ωίϊΐόάέύϋΰήώΆΈΊΌΎΏΉ\s]+$/;
        if (Regex.test(word)) {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
             return `[Α-Ωα-ωίϊΐόάέύϋΰήώΆΈΊΌΎΏΉ]`;
          } else {
            // Not the last word - expect the whole word exactly
            
            return `[Α-Ωα-ωίϊΐόάέύϋΰήώΆΈΊΌΎΏΉ]`;
          }
        } else {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
            //return escapeRegExp(word);
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        }
      })
      .join("") + ".+",
    "gi"
  );
console.log(searchRegex);
  return list.map(item=>{
    if (!q) return item
    // const matched = searchRegex.test(item.title)||searchRegex.test(item.description)||searchRegex.test(JSON.stringify(item.participants))
    let matched = false
    if (!fields) {      
      matched = searchRegex.test(JSON.stringify(item))
    } else {
      console.log(matched);
      for (let f of fields) {
        if ((typeof(item[f]==='object'))||(Array.isArray(item[f]))) {
          matched = searchRegex.test(JSON.stringify(item[f]))
        } else {
          matched = searchRegex.test(item[f])
        }

        if (matched) {
          break
        }
      }

    }
    return {...item, matched}
  })
}

export const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url

export const transformForm = (fields) => {

  const newFormObj = {
    form : [],
    files: []
  }

  // Transform form
  const fileTypes = [FIELD_TYPES.FILE, FIELD_TYPES.FILE_CAMERA_READY, FIELD_TYPES.COPYRIGHT]; 
  fields.forEach((f)=>{

    // Handle only not null
    const isFile = fileTypes.includes(f.type);
    if (f.value === null && !isFile) {
      return
    }

    const newField = {...f}

    if (isFile&&f.value) {

      // Added file
      if (f.value.file) {
        // Set value required by BE
        newField.value = `file_${newField.id}`
        // Keep file
        newFormObj.files.push({
          key: newField.value,
          data: f.value.file
        })
      }

      // Existing file
      if (newField.value.name&&newField.value.id) {
        newField.value = newField.value.id
      }

      // Deleted file should be already null
    }

    if (!f.value) return

    newFormObj.form.push(newField)
  })

  return newFormObj
}

export const truncate = (input, limit) => input.length > limit ? `${input.substring(0, limit)}...` : input

// https://gist.github.com/solotimes/2537334
export const truncateFilename = (n, len) => {
  var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  var filename = n.replace('.' + ext,'');
  if(filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
  return filename + '.' + ext;
}

export const decodeToken = (token) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  } catch (e) {
    return false
  }
}

export const getUserToken = () => {
  return localStorage.getItem('access_token')
}

export const getUserId = () => {
  return decodeToken(getUserToken())
}

export const checkHost = () => {
  const hostname = window.location.hostname
  return ![DOMAIN,`www.${DOMAIN}`,DEV_DOMAIN,`www.${DEV_DOMAIN}`,STAGING_DOMAIN,`www.${STAGING_DOMAIN}`,'localhost'].includes(hostname)
}
