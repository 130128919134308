import React, {useCallback, useEffect, useRef, useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from "@material-ui/core/Toolbar";
import Box from '@material-ui/core/Box'
import {Link, NavLink, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import {useAuth} from 'auth/AuthController'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { ReactComponent as Logo } from 'assets/anymeets-logo.svg'
import {ReactComponent as LogoWhite} from "assets/anymeets-logo-white.svg";
import AppSmartBanner from 'components/core/AppSmartBanner'
// import GuestMenu from 'components/core/Header/GuestMenu'
import UserMenu from 'components/core/Header/UserMenu'
import MobileUserMenu from 'components/core/Header/MobileUserMenu'
import MobileGuestMenu from 'components/core/Header/MobileGuestMenu'
import EventMenu from 'components/core/Header/EventMenu'
import {checkHost, imageFile} from 'utils'
import SessionMenu from 'components/core/Header/SessionMenu'
import {format, parseISO} from 'date-fns'
import Chip from 'components/core/Chip'
import {SPONSOR_TYPES} from 'config'
import clsx from "clsx";
import FullWidthMenu from "components/core/Header/Dropdown/FullWidthMenu"
import {Transition} from "react-spring/renderprops-universal";
import BrowserSuggestion from 'components/core/BrowserSuggestion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from "@material-ui/icons/Menu";
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from "@material-ui/core/ListSubheader";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { Container } from '@material-ui/core';


const styles = (theme) => ({
  menuHeader: {
    background: `${palette["white"]}`,
    transition: "background-color 200ms linear , box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0 0px 2px 0 rgba(0, 0, 0, 0.1)",
    //borderBottom: '1px solid #eee',
    color: palette["greyish-brown"],
    "& a": {
      color: palette["black"],
    },
    "& a:after": {
      backgroundColor: `${palette["greyish-brown"]}`,
    },
    "& a:before": {
      backgroundColor: `${palette["greyish-brown"]}`,
    },
    [theme.breakpoints.down("xs")]: {},
    //boxShadow: 'none',
  },
  transparent: {
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none",
    // transition: 'all 0.4s',
    color: "white",
    "& a": {
      color: "white",
    },
    "& a > *": {
      color: "white !important",
    },
    "& a:after": {
      backgroundColor: "white",
    },
    "& a:before": {
      backgroundColor: "white",
    },
  },
  noShadow: {
    background: "transparent",
    boxShadow: "none",
    "& a": {
      color: `${palette["white"]}`,
    },
    "& span": {
      color: `${palette["white"]}`,
    },
  },
  list: {
    width: 280,
    height: "100%",
    backgroundColor: "white",
  },
  logo: {
    marginTop: theme.spacing(2),
    display: "block",
    textDecoration: "none",
    color: "#000",
    fontWeight: "bold",
    [theme.breakpoints.down(680)]: {
      width: "40px",
      overflow: "hidden",
    },
  },
  sessionInfo: {
    paddingLeft: theme.spacing(2),
    color: palette["greyish-brown"],
    fontWeight: "bold",
  },
  sessionTime: {
    fontSize: "14px",
    fontWeight: "normal",
  },
  menuLeft: {
    float: "right",
    marginLeft: "45px",
    "& a": {
      margin: "0 36px 0 0",
      fontFamily: "Karla",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      textAlign: "center",
      transition: "0.5s",
    }
  },
  menuListItem: {
    fontFamily: "Karla",
    fontSize: "15px",
    color: palette["black"],
  },
  dropdownMenu: {
    "&& div:nth-child(3)": {
      borderRadius: "5px",
      boxShadow: "0px 1px 6px 0px #d9d9d9",
    },
  },
});


const Header = withStyles(styles)((props) => {

  const {classes, animate, event, isEvent, virtualRoomData, noShadow, isPosterType,openPosterView,setOpenPosterView, session, isSession, extras, canManage, hasRooms=true, isSponsor, isSponsorHost, isHost} = props

  const [isAuthenticated, , , userInfo] = useAuth()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const [isAnimated, setIsAnimated] = useState(animate)
  const [hasShadow, setHasShadow] = useState(!noShadow)

  const userName = userInfo && userInfo.info ? `${userInfo.info.title ? userInfo.info.title : ''} ${userInfo.info.firstName} ${userInfo.info.lastName}` : ''

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({...state, [side]: open})
  }

  const sideList = side => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {isAuthenticated&&<MobileUserMenu userInfo={userInfo} userName={userName} event={event} extras={extras}/>}
      {!isAuthenticated&&<MobileGuestMenu event={event} extras={extras} />}
    </div>
  )

  const leftMenu = (side) => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
      <Box className={classes.navLinksDrawer} flexDirection="column">
        <Box display="flex" p={2} alignItems="center"></Box>
        <Box display="flex" width="100%">
          <List
            className={classes.fullWidth}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Link to="/" className={classes.logo}>
                  {" "}
                  <Logo />{" "}
                </Link>
              </ListSubheader>
            }>
            {/* <Link to="/how-it-works">
              <ListItem button key={"How it Works "}>
                <ListItemText primary={"How it Works "} style={{color: "#000"}} />
              </ListItem>
            </Link> */}
            {/* <ListItem button>
              <ListItemText primary="Features" />
              <ExpandLess />
            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button style={{marginLeft: "15px"}} component="a" href="/conference" className={classes.menuListItem} onClick={handleClose}>
                  Conference Page
                </ListItem>
                <ListItem button style={{marginLeft: "15px"}} component="a" href="/papers" className={classes.menuListItem} onClick={handleClose}>
                  Call for Papers
                </ListItem>
                <ListItem button style={{marginLeft: "15px"}} component="a" href="/virtual-events" className={classes.menuListItem} onClick={handleClose}>
                  Virtual Event
                </ListItem>
                <ListItem button style={{marginLeft: "15px"}} component="a" href="/post-events" className={classes.menuListItem} onClick={handleClose}>
                  Post Event
                </ListItem>
              </List>
            </Collapse> */}
            {/* <Link to="/pricing">
              <ListItem button key={"Pricing"}>
                <ListItemText primary={"Pricing"} style={{color: "#000"}} />
              </ListItem>
            </Link> */}
            {/* <Link to="/about">
              <ListItem button key={"About"}>
                <ListItemText primary={"About"} style={{color: "#000"}} />
              </ListItem>
            </Link> */}
          </List>
        </Box>
      </Box>
    </div>
  );
  
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

  let last_known_scroll_position = 0
  let ticking = false

  const checkScrollTop = useCallback((scroll_pos) => {
    // console.log(scroll_pos)

    if (animate) {
      if (scroll_pos===0) {
        setIsAnimated(true)
      } else {
        setIsAnimated(false)
      }
    }

    if (noShadow) {
      if (scroll_pos===0) {
        setHasShadow(false)
      } else {
        setHasShadow(true)
      }
    }
    // eslint-disable-next-line
  },[isAnimated])

  const scrollTopThrottler = function(e) {
    last_known_scroll_position = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function() {
        checkScrollTop(last_known_scroll_position);
        ticking = false;
      });

      ticking = true;
    }
  }

  useEffect(()=>{
    if (animate||noShadow) {
      document.addEventListener('scroll', scrollTopThrottler)
    }

    return () => {
      if (animate||noShadow) {
        document.removeEventListener('scroll',scrollTopThrottler)
      }
    }
    // eslint-disable-next-line
  },[])

  const handleAnimated = (state) => {
    setIsAnimated(state)
  }

  const [openPopper, setOpenPopper] = useState(false)

  const timeoutRef = useRef()

  const handlePopperOpen = (state) => {
    if (!state) {
      if (animate&&window.scrollY===0) {
        setIsAnimated(true)
      }
      setOpenPopper(false)
      timeoutRef.current = setTimeout(()=>{

      },300)
    } else {
      clearTimeout(timeoutRef.current)
      setOpenPopper(state)
      setIsAnimated(false)
    }

    // setIsAnimated(state)
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleRotate()
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleRotate()
  };
    const [rotateChevron, setRotateChevron] = useState(false);

    const handleRotate = () => setRotateChevron(!rotateChevron);

    const rotate = rotateChevron ? "rotate(180deg)" : "rotate(0)"

  const {slug} = useParams()
  const host = checkHost()

  let baseURL = `/event/${event?.eventInfo?.id}`

  if (slug) {
    baseURL = `/${slug}`
  } else if (host) {
    baseURL = ``
  }

  return <>
    <AppBar position='fixed' className={clsx(classes.menuHeader, isAnimated&&!openPopper&&classes.transparent, !hasShadow&&classes.noShadow)}>
      <AppSmartBanner/>
      <BrowserSuggestion />
      <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row' flexBasis='100%'>
        <Box display='inline-block' pl={2} alignItems='center' justifyContent='flex-start' flexDirection='row' width={"100%"}>

          <Box display='inline-block'>
            {!isEvent&&!isSession&&<Link to='/' className={classes.logo}> {!hasShadow&&classes.noShadow  ? (<LogoWhite/>) : (<Logo/>) }  </Link>}
            {isEvent&&event&&<Link to={`${baseURL}/info`}>
              <img src={imageFile(event.eventInfo.logoId)} alt={event.eventInfo.name} width={170} height={40} />
            </Link>}
            {isSession&&session&&<Link to={`/event/${session.eventId}/info`}>
              <img src={imageFile(session.eventLogoId)} alt={session.title} width={170} height={40} title={`Go back to the event page`}/>
            </Link>}
          </Box>

          {!isEvent&& !isSession &&<Box className={classes.menuLeft} display="inline-block">
            {/*<Link to='#'
              onMouseEnter={handlePopperOpen}
            >
              How it works
            </Link>*/}
           {/* <AppBar position="static" style={{background:"transparent", float:"right", boxShadow:"none"}}> */}
            <Container maxWidth="md">
              <Toolbar disableGutters>
                  <Hidden smDown>
                    {/* <NavLink to='/how-it-works'> How it Works </NavLink>
                    <NavLink
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onMouseEnter={handleClick}
                      anchorel={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      to="#"
                    >
                      Features <ExpandMoreIcon style={{transform: rotate, transition: "all 0.2s linear",verticalAlign: 'middle'}}/>
                    </NavLink> */}
                    {/* <NavLink to='/pricing'> Pricing </NavLink> */}
                    {/* <NavLink to='/about'> About </NavLink> */}
                    <Menu
                      className={classes.dropdownMenu}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem component="a" href='/conference' className={classes.menuListItem} onClick={handleClose}>Conference Page</MenuItem>
                      <MenuItem component="a" href='/papers' className={classes.menuListItem} onClick={handleClose}>Call for Papers</MenuItem>
                      <MenuItem component="a" href='/virtual-events' className={classes.menuListItem} onClick={handleClose}>Virtual Event</MenuItem>
                      <MenuItem component="a" href='/post-events' className={classes.menuListItem} onClick={handleClose}>Post Event</MenuItem>
                        </Menu>
                      </Hidden>
                      <Hidden mdUp>
                    <IconButton onClick={() => {  setState({...state, left: true});}}><MenuIcon/></IconButton>
                      </Hidden>
              </Toolbar>
            </Container>
           {/* </AppBar> */}
          </Box>}

          {isSession&&session&&<Box display='flex' pl={2} alignItems='flex-start' justifyContent='center' flexDirection='column' className={classes.sessionInfo}>
            <Box display='fex'>
              {session.title}
            </Box>
            <Box display='flex' className={classes.sessionTime}>
              {session.startDate&&format(parseISO(session.startDate), 'hh:mm aa')} - {session.endDate&&format(parseISO(session.endDate), 'hh:mm aa')} <br/>
            </Box>
          </Box>}

          {isSponsor&&event&&<Box display='flex' pl={2} alignItems='flex-start' justifyContent='center' className={classes.sessionInfo}>
            <Box display='fex'>
              {event.sponsor.name} <Box display='inline' pl={1}> <Chip label={SPONSOR_TYPES[event.sponsor.type]}/> </Box>
            </Box>
            {/*<Box display='flex' className={classes.sessionTime} p={1}>*/}
            {/*  <Chip label={SPONSOR_TYPES[event.sponsor.type]}/>*/}
            {/*</Box>*/}
          </Box>}
        </Box>

        {!isEvent&&!isSession&&<UserMenu toggleDrawer={toggleDrawer} userInfo={userInfo} animated={isAnimated&&!openPopper} setAnimated={handleAnimated} setPopper={handlePopperOpen} headerClasses={!hasShadow&&classes.noShadow}/>}
        {isAuthenticated&&isSession&&session&&<SessionMenu toggleDrawer={toggleDrawer}
                                                  userInfo={userInfo} session={session} isPosterType={isPosterType} openPosterView={openPosterView} setOpenPosterView={setOpenPosterView} />}
        {/*{!isAuthenticated&&!isEvent&&<GuestMenu animated={isAnimated&&!openPopper} />}*/}
        {isEvent&&<EventMenu virtualRoomData={virtualRoomData} event={event} toggleDrawer={toggleDrawer} extras={extras}
                          userInfo={userInfo} canManage={canManage} hasRooms={hasRooms} isSponsor={isSponsor} isSponsorHost={isSponsorHost} sponsorId={event&&event.sponsor?event.sponsor.id:null} isHost={isHost} />}

      </Box>

      <Transition
        config={{duration: 200}}
        items={openPopper}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}>
        {show => show && (props => <div style={props}><div style={{position: 'fixed', top: 50, left: 0, width: '100%'}} onMouseEnter={()=>handlePopperOpen(true)}
          onMouseLeave={()=>handlePopperOpen(false)}><FullWidthMenu /></div></div>)}
      </Transition>

      {/*{openPopper&&*/}
      {/*  <div style={{position: 'fixed', top: 50, left: 0, width: '100%'}} onMouseEnter={()=>handlePopperOpen(true)}*/}
      {/*       onMouseLeave={()=>handlePopperOpen(false)}><FullWidthMenu   /></div>*/}
      {/*}*/}

    </AppBar>
    <SwipeableDrawer
      anchor='right'
      open={state.right}
      onClose={toggleDrawer('right', false)}
      onOpen={toggleDrawer('right', true)}
      className={classes.sideBar}
    >
      {sideList('right')}
    </SwipeableDrawer>
    <SwipeableDrawer
      anchor='left'
      open={state.left}
      onClose={toggleDrawer('left', false)}
      onOpen={toggleDrawer('left', true)}
      className={classes.sideBar}
    >
      {leftMenu('left')}
    </SwipeableDrawer>    
  </>
})

export default withRouter(Header)
