import React from 'react'
import {ReactComponent as TopMenu} from 'assets/uiEventMenuTop.svg'
import {ReactComponent as MenuSidebar} from 'assets/uiEventMenuSidebar.svg'
import {ReactComponent as MenuDrawer} from 'assets/uiEventMenuDrawer.svg'
import {ReactComponent as MenuDrawerContained} from 'assets/uiEventMenuDrawerBackground.svg'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import {palette} from 'theme'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  toolTipBack: {
    backgroundColor: 'white',
    height: '180px',
    width: '186px',
    padding: '10px',
    border: '1px solid #f0f0f0',
    cursor: 'pointer'
  },
  opacityOnBox:{
    opacity:'0.7',
    '&:hover':{
      opacity:'1',
    }
  },
  bolderText:{
    fontWeight:'600'
  },
  selectedLayout: {
    border: '1px solid #f0f0f0',
    boxShadow: `1px 0px 4px 2px ${palette['aquamarine']}`
  },
  svgColors: {
    height: '140px',
    maxWidth: '160px',
    minWidth: '100%',
    width: 'auto',
    '& .primary': {
      fill: theme.palette.primary.main
    },
    '& .secondary': {
      fill: theme.palette.secondary.main
    },
    '& .secondaryLight': {
      fill: theme.palette.secondary.light
    },
    '& .primaryLight': {
      fill: theme.palette.primary.light
    },
    '& .primaryButton': {
      stroke: theme.palette.primary.main
    },
    '& .secondaryButton': {
      stroke: theme.palette.secondary.main
    }
  }
})


const PalettePreview = withStyles(styles)(({classes, layout, selected,themeSelected,...props}) => {
  const boxStyle = clsx({
    [classes.toolTipBack]: true,
    [classes.selectedLayout]: selected,
    [classes.opacityOnBox] :!selected
  })

  const textStrong = clsx({
    [classes.bolderText]:selected
  })

  return <Box className={boxStyle} {...props}>
      {layout === 1 && <TopMenu className={classes.svgColors}/>}
      {layout === 2 && <MenuSidebar className={classes.svgColors}/>}
      {layout === 3 && <MenuDrawer className={classes.svgColors}/>}
    {layout === 4 && <MenuDrawerContained className={classes.svgColors}/>}
    {layout === 1 && <Typography className={textStrong} align={'center'}>Top Menu</Typography>}
    {layout === 2 && <Typography className={textStrong} align={'center'}>Left Menu</Typography>}
    {layout === 3 && <Typography className={textStrong} align={'center'}>Left Sidebar</Typography>}
    {layout === 4 && <Typography className={textStrong} align={'center'}>Left Sidebar Contained</Typography>}
  </Box>
})

export default PalettePreview