import React, {useState, useEffect, useContext} from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'fixed',
    top: '0',
    left: 0,
    right: 0,
    zIndex: 9999,
    opacity: 0.3
    // [theme.breakpoints.down('sm')]: {
    //   top: '127px'
    // }
  }
}))

const ProgressIndicator = () => {

  const [completed, setCompleted] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          clearInterval(timer)
        }
        const diff = Math.random() * 10
        return Math.min(oldCompleted + diff, 100)
      })
    }

    const timer = setInterval(progress, 500)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className={classes.progress}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  )
}

export default () => {
  const [isLoading] = useLoadingIndicator()

  return <>
    {isLoading && <ProgressIndicator />}
  </>
}

const useLoadingController = () => {
  const [isLoading, setLoading] = useState(false)
  return [isLoading, setLoading]
}

const loadingContext = React.createContext()

export const useLoadingIndicator = () => {
  return useContext(loadingContext)
}

export const ProvideLoadingIndicator = ({children}) => {
  const loadingHook = useLoadingController()
  return <loadingContext.Provider value={loadingHook}>
    {children}
  </loadingContext.Provider>
}

