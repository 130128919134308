import {formatISO} from 'date-fns'

// Configuration
const SERVICE_URL_DEV = 'https://api-dev.anymeets.com'
const SERVICE_URL_TEST = 'https://live-api-dev.anymeets.com'
const SERVICE_URL_PROD = 'https://api.anymeets.com'
const SERVICE_URL_STAGING = 'https://api-staging.anymeets.com'
export const DEV_MODE = (process.env.REACT_APP_BUILD_FOR === 'development')
export const TEST_MODE = (process.env.REACT_APP_BUILD_FOR === 'test')
export const STAGING_MODE = (process.env.REACT_APP_BUILD_FOR === 'staging')
export const SERVICE_URL = DEV_MODE ? SERVICE_URL_DEV : TEST_MODE ? SERVICE_URL_TEST : STAGING_MODE ? SERVICE_URL_STAGING : SERVICE_URL_PROD 
export const FILES_URL = `${SERVICE_URL}/files`
export const GOOGLE_MAPS_API = 'AIzaSyDQoQ1w57EeHvUyImt0Ji-cvSlHbfQMdfw'
export const DOMAIN = 'anymeets.com'
export const DEV_DOMAIN = 'dev.anymeets.com'
export const STAGING_DOMAIN = 'staging.anymeets.com'
export const COPYRIGHT_SHOW = true  // Whether to show copyright in footer and/or other places
export const COPYRIGHT_TITLE = 'datawise.ai'
export const COPYRIGHT_URL = 'http://datawise.ai'
export const UPLOAD_FILE_LIMIT = 25 * 1000000
export const TITLE_TEMPLATE = `%s - Anymeets`

export const TERMS_PUBLISH = `Datawise Data Engineering LLC Terms of Service
1. Terms
By accessing the website at https://www.anymeets.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
You must be at least 18 years of age to use this website. By using this website and by agreeing to these terms and conditions you warrant and represent that you are at least 18 years of age.
This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to our Company's use of cookies in accordance with the terms of Company's privacy policy.

2. Use License
Permission is granted to temporarily download one copy of the materials (information or software) on Datawise Data Engineering LLC's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
modify or copy the materials;
use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
attempt to decompile or reverse engineer any software contained on Datawise Data Engineering LLC's website;
remove any copyright or other proprietary notations from the materials; or
transfer the materials to another person or "mirror" the materials on any other server.
This license shall automatically terminate if you violate any of these restrictions and may be terminated by Datawise Data Engineering LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
Acceptable use
You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Datawise Data Engineering LLC's express written consent.
You must not use this website to transmit or send unsolicited commercial communications.
You must not use this website for any purposes related to marketing without Datawise Data Engineering LLC's express written consent.
User content
In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.
Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or Data Engineering LLC or a third party (in each case under any applicable law).
You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.
Data Engineering LLC reserves the right to edit or remove any material submitted to this website, or stored on Data Engineering LLC's servers, or hosted or published upon this website.

No warranties
This website is provided “as is” without any representations or warranties, express or implied. Data Engineering LLC makes no representations or warranties in relation to this website or the information and materials provided on this website.
Without prejudice to the generality of the foregoing paragraph, Data Engineering LLC does not warrant that:
this website will be constantly available, or available at all; or
the information on this website is complete, true, accurate or non-misleading.
Data Engineering LLC is not responsible for loss or damages of your event organization in case of website failure or outage. An alternative/backup option should always be provided by the event organizers.
Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.
3. Disclaimer
The materials on Datawise Data Engineering LLC's website are provided on an 'as is' basis. Datawise Data Engineering LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
Further, Datawise Data Engineering LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
4. Limitations
In no event shall Datawise Data Engineering LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Datawise Data Engineering LLC's website, even if Datawise Data Engineering LLC or a Datawise Data Engineering LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
Indemnity
You hereby indemnify  Datawise Data Engineering LLC and undertake to keep  Datawise Data Engineering LLC indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Datawise Data Engineering LLC to a third party in settlement of a claim or dispute on the advice of  Datawise Data Engineering LLC's legal advisers) incurred or suffered by  Datawise Data Engineering LLC arising out of any breach by you of any provision of these terms and conditions[, or arising out of any claim that you have breached any provision of these terms and conditions.

5. Accuracy of materials
The materials appearing on Datawise Data Engineering LLC's website could include technical, typographical, or photographic errors. Datawise Data Engineering LLC does not warrant that any of the materials on its website are accurate, complete or current. Datawise Data Engineering LLC may make changes to the materials contained on its website at any time without notice. However Datawise Data Engineering LLC does not make any commitment to update the materials.
6. Links
Datawise Data Engineering LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Datawise Data Engineering LLC of the site. Use of any such linked website is at the user's own risk.
7. Modifications
Datawise Data Engineering LLC may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
8. Governing Law
These terms and conditions are governed by and construed in accordance with the laws of Georgia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
`

// 'Constant values'
export const EMAIL_TEMPLATE_NAMES = {
  8: "reviewerInvitation",
  10: "reviewerReminder",
  13: "reviewerAssignmentsAll",
  14: "reviewerAssignmentsPending",
  15: "reviewerAssignmentsNew",
  16: "acceptedSubmitters",
  17: "rejectedSubmitters",
  18: "waitlistedSubmitters",
  22: "acceptedPresenters",
  23: "rejectedPresenters",
  24: "waitlistedPresenters",
  25: "withdrawnPresenters",
  26: "acceptedPresenters",
  27: "rejectedPresenters",
  28: "waitlistedPresenters",
  29: "withdrawnPresenters",
  30: "generic",
  31: "generic",
  32: "submittersAll",
};

export const EMAIL_TEMPLATE = {
  'INVITE':8,
  'REMINDER':10,
  'REVIEWER_ASSIGNMENTS_ALL': 13,
  'REVIEWER_ASSIGNMENTS_PENDING': 14,
  'REVIEWER_ASSIGNMENTS_NEW': 15,
  'ACCEPTED_SUBMITTERS' : 16,
  'REJECTED_SUBMITTERS': 17,
  'WAIT_LISTED_SUBMITTERS': 18,
  'ACCEPTED_PRESENTERS' : 22,
  'REJECTED_PRESENTERS' : 23,
  'WAIT_LISTED_PRESENTERS' : 24,
  'WITHDRAWN_PRESENTERS': 25,
  'NEW_ACCEPTED_PRESENTERS' : 26,
  'NEW_REJECTED_PRESENTERS' : 27,
  'NEW_WAIT_LISTED_PRESENTERS' : 28,
  'NEW_WITHDRAWN_PRESENTERS': 29,
  'REGISTERED_ACCEPTED_PRESENTERS': 30,
  'NEW_REGISTERED_ACCEPTED_PRESENTERS': 31,
  'SUBMITTERS_ALL': 32,
}


export const CATEGORIES = {
  1: 'Academic Conference',
  2: 'Conference',
  3: 'Seminar',
  4: 'Meeting',
  5: 'Team Building',
  6: 'Networking',
  7: 'Business',
  8: 'Workshop'
}

export const STREAM_OPTIONS = [
  { id:0, name: 'None'},
  { id:1, name: 'Anymeets Broadcast'},
  { id:2, name: 'YouTube'},
  { id:3, name: 'Vimeo'},
  { id:4, name: 'Zoom'},
  { id:5, name: 'Google Meets / Hangouts'},
  { id:8, name: 'RTMP' },
  { id:6, name: 'Other'},
  { id:7, name: 'Jitsi'},
  { id:9, name: 'Anymeets Live'},
]

export const STREAM_OPTIONS_POSTER = [
  { id:0, name: 'None'},
  { id:7, name: 'Jitsi'},
  { id:9, name: 'Anymeets Live'}
]

export const STREAM_VIDEO_TYPES = {
  'NONE' : 0,
  'ANYMEETS_BROADCAST': 1,
  'YOUTUBE': 2,
  'VIMEO' : 3,
  'ZOOM' : 4,
  'GOOGLE': 5,
  'OTHER': 6,
  'ANYMEETS_LIVE': 7,
  'RTMP': 8,
  'ANYMEETS_LIVE_NEW': 9
}

export const ROLES = [
  {id: 1, name: 'Admin', description: 'Admin has full access to all resources'},
  {id: 2, name: 'Manager', description: 'Manager can edit all parts of the event but cannot publish'},
  {id: 3, name: 'Moderator', description: 'Moderator can only moderate users content like QA, Social feed messages'}
]

// NOTE: If one is added or removed, check SponsorsLogoList for grouping (won't build properly otherwise)
export const SPONSOR_TYPES = {
  1: 'Platinum',
  2: 'Gold',
  3: 'Silver',
  4: 'Bronze',
  5: 'Exhibitor',
  6: 'Media Partner',
  7: 'Research Sponsor',
  8: 'Diversity Sponsor',
  9: 'Lanyard Sponsor',
  10: 'Community Partner',
  11: 'Presenting Sponsor',
  12: 'Partner',
  13: 'With the support of'
}

export const PRICING_VALUES = {
  1: '$',
  2: '$$',
  3: '$$$'
}

export const PRICING_CATEGORIES = [
  {
    id: 1,
    category: '$'
  },
  {
    id: 2,
    category: '$$'
  },
  {
    id: 3,
    category: '$$$'
  }
]

export const CHART_TYPES = [
  {
    id: 1,
    name: 'Bar chart'
  },
  {
    id: 2,
    name: 'Pie chart'
  }
]

export const DUMMY_CHART_DATA = {
  1: [22, 50, 43],
  2: [10, 50, 43]
}

export const TITLES = ['-', 'Dr.', 'Mr.', 'Ms.', 'Mx.', 'Prof.']

export const PASSWORD_CALLBACKS = ['Had an epiphany?', 'Had a memory lapse?', 'Is it password123?', 'Wait... is it your pet\'s name?', 'Was it perhaps your birth-date?', 'Don\'t tell me... is it your last name?!']

export const PRESENTATION_TYPES = {
  1: 'Oral',
  2: 'Poster'
}

export const LAYOUT_TYPES = {
    'SINGLE_PAGE': 1,
    'WITH_MENU': 2,
    'WITH_DRAWER': 3,
    'WITH_DRAWER_CONTAINED': 4
}

export const VIEWER_TYPES = {
  // 'POSTER' : 'Poster session',
  // 'POSTER_ONLINE' : 'Online poster session',
  // 'WORKSHOP' : 'Anymeets Live (up to 50 attendees)',
  // 'ONLINE' : 'Oral Session',
  'STANDARD': 1,
  'POSTER' : 2,
  'BREAK': 3,
  'SOCIAL': 4
}

export const SESSION_TYPES = [
  {id: 1, option: 'Standard'},
  {id: 2, option: 'Poster'},
  {id: 3, option: 'Break'},
  {id: 4, option: 'Social'}
]

export const SESSION_LIVE = {
  PAST : 1,
  LIVE : 2,
  FUTURE: 3
}

export const ROOM_USER_STATUS = {
  'KICKED' : -1,
  'VISITOR' : 0,
  'WAITING' : 1,
  'ADMITTED' : 2,
  'JOINED' : 3
}

export const FIELD_TYPES = {
  'LABEL':1,
  'TEXT':2,
  'SINGLE_SELECT':3,
  'MULTI_SELECT':4,
  'TEXT_AREA':5,
  'RICH_TEXT':6,
  'BOOLEAN':7,
  'NUMBER':8,
  'THEMATIC_CODE':9,
  'FILE':10,
  'AUTHORS':11,
  'GRADE': 12,
  'TITLE': 13,
  'ABSTRACT': 14,
  'FILE_CAMERA_READY': 15,
  'COPYRIGHT': 16,
}

export const FIELD_TYPES_LIST = {
  1: 'Label',
  2: 'Text',
  3: 'Single select',
  4: 'Multi select',
  5: 'Text area',
  6: 'Rich text',
  7: 'Boolean',
  8: 'Number',
  9: 'Thematic code',
  10: 'File',
  11: 'Authors',
  12: 'Grade',
  13: 'Title',
  14: 'Abstract',
  15: 'File Camera Ready',
  16: 'Copyright',
}
// export const FIELD_PAPERSUBMISSION_FIELD_TYPE = {
//   1: '--',
//   2: 'Paper title',
//   3: 'Paper abstract'
// }
export const LANG_LIST = [
    {id: 1, name: "English", slag: "en"},
    {id: 2, name: "Greek", slag: "el"},
   // {id: 3, name: "German", slag: "de"},
   // {id: 4, name: "French", slag: "fr"},
];

export const creditsPriceList = [
  {id: 0, label: "balance",value: 1},
  {id: 1, label: "papers", value: 3},
  {id: 2, label: "posters", value: 8},
  {id: 3, label: "sponsors", value: 120},
  {id: 4, label: "emails", value: 0.1},
];

export const CONTENT_LIST = [
{id: 0, label:"Info", value: 1},
{id: 1, label:"Agenda", value: 2},
{id: 2, label:"Speakers", value: 3},
{id: 3, label:"Keynote Speakers", value: 4},
{id: 4, label:"Sponsors", value: 5},
{id: 5, label:"Restaurants", value: 6},
{id: 6, label:"Hotels", value: 7 },
{id: 7, label:"Organizers", value: 8 },
{id: 8, label:"Extras", value: 9 },
{id: 9, label:"General", value: 10},
{id: 10, label:"Proceedings", value: 11},
];

// Localization
export const CURRENCY = '$'

// Sizes
export const SIZE_ORGANIZER_LOGO = {height: 512, width: 512}
export const SIZE_PROFILE_IMAGE = {height: 128, width: 128}
export const SIZE_SPONSOR_LOGO = {height: 512, width: 512}
export const SIZE_ROOM_IMAGE = {height: 768, width: 1024}
export const SIZE_LOGO = {height: 80, width: 340}
export const SIZE_COVER = {height: 300, width: 1440}

export const SIZE_CANVAS_WIDTH = 1280
export const SIZE_CANVAS_HEIGHT = 720

export const SIZE_LENGTH_DESCRIPTION = 10000
export const SIZE_LENGTH_PARTICIPANT_SUMMARY = 5000
export const SIZE_LENGTH_HTML_CONTENT = 10000

// Times
export const TIME_GRAPH_POLLING_INTERVAL = 5000
export const TIME_SESSION_HEARTBEAT = 4000
export const TIME_AGENDA_INTERVAL = 40000
export const TIME_FADE_IN = 200

// Filetypes
export const FILETYPES_ACCEPTED = ['.xls', '.xlsx', '.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx']
export const FILETYPES_PAPER_ACCEPTED = ['.pdf']
export const FILETYPES_PRESENTATION_POSTER_ACCEPTED = ['.pdf', '.ppt', '.key']
export const FILETYPES_IMAGES_ACCEPTED = ['.png', '.jpg', '.jpeg']

// Dates
export const DATE_DEFAULT_STARTDATE = formatISO(new Date().setHours(9, 0, 0, 0))
export const DATE_DEFAULT_ENDDATE = formatISO(new Date().setHours(17, 0, 0, 0))

// Location
export const LOCATION_DEFAULT_MAP_LOCATION = {lat: 37.9871399, lng: 23.7621942}

// Mobile Apps
export const APP_APPLE_INFO = {
  imageUrl:
    'https://anymeets.com/apple-touch-icon.png',
  name: 'Anymeets',
  publisher: 'DataWise Data Engineering LLC',
  infoText: 'Free - In App Store',
  linkButtonText: 'VIEW',
  linkUrl:
    'https://apps.apple.com/us/app/anymeets/id1498185631'
}

export const APP_ANDROID_INFO = {
  imageUrl:
    'https://anymeets.com/apple-touch-icon.png',
  name: 'Anymeets',
  publisher: 'DataWise Data Engineering LLC',
  infoText: 'Free - In Google Play',
  linkButtonText: 'VIEW',
  linkUrl:
    'https://play.google.com/store/apps/details?id=com.anymeets'
}

// Session Time durations
const durationInHours = 8
const stepInMinutes = 5

export const TIME_DURATION = new Array(durationInHours * (60 / stepInMinutes)).fill().map((o, i) => {
  const step = i + 1
  let hours = (step * stepInMinutes) / 60
  hours = hours < 0 ? 0 : parseInt(hours)

  let minutes = (step * stepInMinutes)
  minutes = minutes >= 60 ? minutes - hours * 60 : minutes
  minutes = minutes < 0 ? 0 : parseInt(minutes)

  return {
    label: `${hours ? `${hours}${hours > 1 ? ' hours' : ' hour'}` : ''}${hours && minutes ? ' ' : ''}${minutes ? `${minutes}${minutes > 1 ? ' minutes' : ' minute'}` : ''}`,
    value: step * stepInMinutes * 60 * 1000 // In ms (value * minutes * 1 hr * 1000ms)
  }
})
