import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  title: {
    backgroundColor: palette['cerulean-blue'],
    fontWeight: 'bold',
    fontSize: '36px',
    color: 'white'
  }
})

const HeaderTitle = withStyles(styles)(({classes, children, ...restProps}) => {
  return <Box p={4} textAlign='center' colour='white' className={classes.title}>{children}</Box>
})

export default HeaderTitle
