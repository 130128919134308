import React, {useState} from 'react'
import {withStyles} from '@material-ui/styles'
import {isIOS, isAndroid} from 'react-device-detect'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import {IconXBorderDark} from 'components/core/icons'
import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = theme => ({
  mainBox : {
    backgroundColor: palette['very-light-pink'],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #cdcdcd',
    zIndex: 99999
  },
  appIcon: {
    borderRadius: '15%',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    width: '48px',
    height: '48px',
    border: '1px solid #efefef',
    boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.03)',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  name: {
    padding: theme.spacing(1),
    paddingBottom: 0,
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  publisher: {
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5),
    fontSize: '12px',
    color: palette['greyish-brown'],
  },
  price: {
    padding: theme.spacing(1),
    paddingTop: 0,
    fontSize: '14px',
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  link: {
    paddingRight: theme.spacing(2),
    textAlign: 'right',
    '& a': {
      fontSize: '16px',
      color:'#0070c9'
    }
  }
})

const Banner = withStyles(styles)(({classes,appStoreInfo, closeBanner}) => {
  return <Grid container className={classes.mainBox} alignContent='center' alignItems='center'>
    <Grid item onClick={()=>{closeBanner()}}> <Box p={0.5}><IconXBorderDark/></Box> </Grid>
    <Grid item>
      <Box className={classes.appIcon}>
        <a href={appStoreInfo.linkUrl} title={appStoreInfo.name}>
          <img src={appStoreInfo.imageUrl} alt={appStoreInfo.name} />
        </a>
      </Box>
    </Grid>
    <Grid item>
      <a href={appStoreInfo.linkUrl} title={appStoreInfo.name}>
        <Grid item className={classes.name}> {appStoreInfo.name}</Grid>
        <Grid item> <Box className={classes.publisher}>{appStoreInfo.publisher}</Box></Grid>
        <Grid item> <Box className={classes.price}>{appStoreInfo.infoText}</Box></Grid>
      </a>
    </Grid>
    <Grid item className={classes.link} xs> <a href={appStoreInfo.linkUrl} title={appStoreInfo.name} onClick={()=>{closeBanner()}}>{appStoreInfo.linkButtonText}</a> </Grid>
  </Grid>
})

const AppSmartBanner = ({classes}) => {

  const [isOpen, setOpen] = useState(!localStorage.getItem('hasClosedAppBanner'))

  const closeBanner = () => {
    localStorage.setItem('hasClosedAppBanner', true)
    setOpen(false)
  }

  if (isIOS&&isOpen) {
    return <Banner appStoreInfo={APP_APPLE_INFO} closeBanner={closeBanner}/>
  }

  if (isAndroid&&isOpen) {
    return <Banner appStoreInfo={APP_ANDROID_INFO} closeBanner={closeBanner}/>
  }

  return <> </>
}

export default withStyles(styles)(AppSmartBanner)
