import {useState, useCallback, useRef} from 'react'
import {useAuth} from 'auth/AuthController'
import {useSnackbar} from 'notistack'
import {useLoadingIndicator} from 'components/core/LoadingIndicator'
import axios from 'axios'

/**
 * The default Project-wide options for fetch regardless of method
 * @type {{mode: string, redirect: string, headers: {"Content-Type": string}, referrer: string, cache: string, credentials: string}}
 */
const defaultOptions = {
  mode: 'cors',               // no-cors, *cors, same-origin
  cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
  // credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'follow',         // manual, *follow, error
  referrer: 'no-referrer',    // no-referrer, *client,
  timeout: 180000
}

/**
 * Custom Http Hook, uses API Endpoint Objects, used to make any kind of request project-wide.
 * @returns {[fetchData, data, isLoading, error]}
 */
export const useHttp = (background=false) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [extra, setExtra] = useState(null)
  const [progress, setProgress] = useState(0)
  const [,setLoadingIndicator] = useLoadingIndicator()

  const [,,logout] = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const controller = useRef()

  // Error Code handling
  const handleErrorCodes = (errorObj={}, status, endpoint) => {
    switch (status) {
      case 401:
        enqueueSnackbar(errorObj.message, {variant: 'error'})
        logout()
        break
      case 403:
        if (endpoint.redirect) {
          setTimeout(()=>{window.location = '/'},1300)
        }
        enqueueSnackbar(errorObj.message, {variant: 'error'})

        break
      default:
        if ((status+'')[0]!=='2') { // if erroneous Status that is not handled
          if ('message' in errorObj) {
            enqueueSnackbar(errorObj.message, {variant: 'error'})
          } else {
            enqueueSnackbar(`An error occurred with no error message. Server returned code ${status}`, {variant: 'error'})
          }
        }
        break
    }
  }

  const cancelRequest = useCallback(()=>{
    if (controller.current) {
      controller.current.abort()
    }
  },[])

  const fetchData = useCallback(async(endpoint)=>{

    const accessToken = localStorage.getItem('access_token')
    const authorizedHeaders = accessToken?{...defaultOptions.headers, 'Authorization': `Bearer ${accessToken}` }:{...defaultOptions.headers}

    if (endpoint.formData) {
      delete authorizedHeaders['Content-Type']
    }

    try {

      const ctrl = new AbortController()
      controller.current = ctrl

      const data = endpoint.data?(endpoint.formData?endpoint.data:JSON.stringify(endpoint.data)):null
      const reqData = endpoint.reqData?endpoint.reqData:{}
      setExtra(endpoint.reqData?endpoint.reqData:undefined)
      const options = {...defaultOptions, headers: authorizedHeaders, ...endpoint.options, body:data, signal: controller.current.signal}
      setIsLoading(true)
      if (!background) setLoadingIndicator(true)

      let response
      const useAxios = (options.body instanceof FormData)

      // Handle upload differently (axios)
      if (useAxios) {

        options.onUploadProgress = function(progressEvent) {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentCompleted)
        }

        try {
          if (options.method === 'PUT') {
            response = await axios.put(endpoint.uri, options.body, options)
          } else {
            response = await axios.post(endpoint.uri, options.body, options)
          }
          response.ok = true
        } catch (error)
        {
          if (error)
          {
            response = error.response
            response.ok = false
          }
        }

      } else {
        response = await fetch(endpoint.uri, options)
      }
      // If response returned actionable data
      const contentType = response.headers.get?response.headers.get('Content-Type'):response.headers['content-type']
      if (contentType) {
        if (contentType.includes('application/json')) {
          if (!response.ok) {
            const errorObj = useAxios ? response.data : await response.json();

            setError({...errorObj, payload:data, reqData})
            if (endpoint.errorHandling) handleErrorCodes(errorObj, response.status, endpoint)
          } else {
            setData(useAxios?response.data:await response.json())
          }
        } else if (contentType.includes('text/csv')) {
          if (!response.ok) {
            const errorObj = useAxios?response.data:await response.json()
            setError({...errorObj, payload:data, reqData})
            if (endpoint.errorHandling) handleErrorCodes(errorObj, response.status, endpoint)
          } else {
            setData([useAxios?response.data:await response.text()])
          }
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          if (!response.ok) {
            const errorObj = useAxios?response.data:await response.json()
            setError({errorObj,payload:data, reqData})
            if (endpoint.errorHandling) handleErrorCodes(errorObj, response.status, endpoint)
          } else {
            setData([useAxios?response.data:await response.blob()])
          }
        } else if (contentType.includes('application/zip')) {
          if (!response.ok) {
            const errorObj = useAxios?response.data:await response.json()
            setError({errorObj,payload:data, reqData})
            if (endpoint.errorHandling) handleErrorCodes(errorObj, response.status, endpoint)
          } else {
            setData([useAxios?response.data:await response.blob()])
          }
        } else {
          setData({status:response.status})
        }
      } else {
        if (endpoint.errorHandling) handleErrorCodes(null,response.status, endpoint)
        setData({status:response.status})
      }
    } catch (e) {
      if (e.name !== 'AbortError') {
        enqueueSnackbar('There might be a network issue, please try again.', {variant: 'error',preventDuplicate:true})
        console.log(e)
      }
      setError(e)
    } finally {
      setIsLoading(false)
      if (!background) setLoadingIndicator(false)
    }
    // eslint-disable-next-line
  },[background, enqueueSnackbar, logout, setLoadingIndicator])

  return [fetchData, data, isLoading, error, cancelRequest, extra, progress]
}

/***
 * Accepts a URL and a set of parameters {key:value,...} , it will create a query string and return the URL with it.
 * @param url
 * @param params
 * @returns {string|*}
 */
export const addParams = (url, params) => {
  // Return just the URL if no parameters present
  if (!params || Object.keys(params).length===0) return url

  const paramURL = []
  for (let param in params) paramURL.push(`${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`)
  return `${url}?${paramURL.join('&')}`
}

/***
 * The main API object that will hold all entities and methods
 * @param url
 * @returns {{createEndpoint: createEndpoint, entities: {}, createDefaultEndpoints: createDefaultEndpoints}}
 */
export const defaultApi = (url) => {

  let entities = {}

  const createDefaultEndpoints = (name, resourceURL=url) => {
    const entityURL = `${resourceURL}/${name}`

    entities[name] = {
      getOne : (id, params) => ({ options: { method: 'GET' }, uri: addParams(`${entityURL}/${id}`,params) }),
      getMany: (params) => ({ options: { method: 'GET' }, uri: addParams(entityURL,params) }),
      create : (data, params) => ({ options: { method: 'POST' }, uri: addParams(entityURL,params), data}),
      update : (id, data, params) => ({ options: { method: 'PUT' }, uri: addParams(`${entityURL}/${id}`,params), data}),
      delete : (id , params) => ({ options: { method: 'DELETE' }, uri: addParams(`${entityURL}/${id}`,params)}),
    }
  }

  const createEndpoint = (name, action, struct=[], params=[], options= { method: 'GET' }, uri=`${url}/${name}${struct.length?'':'/'+action}${struct.length?'/':''}${struct.length?struct.join('/'):''}`) => {
    entities[name] = {
      ...entities[name],
      [action] : (data, params) => ({ options, uri: addParams(uri,params), data})
    }
  }

  const parseEndpoint = (endpointObj) => {
    const options = { method: endpointObj.method}
    const uri = `${url}${endpointObj.path}`
    return (data, params, vars, formData=false, reqData) => ({ options, redirect:endpointObj.redirect, errorHandling: endpointObj.errorHandling===false?endpointObj.errorHandling:true , uri: addParams(uri.format(vars),params), data, formData, reqData})
  }

  const createEntity = (name, entitiesObj) => {
    Object.keys(entitiesObj).forEach((key)=>{
      entities[name] = {
        ...entities[name],
        [key]: parseEndpoint(entitiesObj[key])
      }
    })
  }

  return {
    createEntity,
    createDefaultEndpoints,
    createEndpoint,
    entities
  }
}
