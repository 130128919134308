import {Link, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import React, {useEffect} from 'react'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import {FILES_URL} from 'config'
import MenuIcon from '@material-ui/icons/Menu'
import {palette} from 'theme'
import clsx from "clsx";
import {useAuth} from 'auth/AuthController'
import {Button as MUIButton} from '@material-ui/core'

// import List from '@material-ui/core/List'

const styles = theme => ({
  navLinks: {
    '& a': {
      fontSize: 16,
      fontWeight: 'normal',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  userBlock: {
    backgroundColor: 'rgba(0,0,0,0)',
    height: '64px',
    maxWidth: '94px',
    width: '94px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    '& a': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:hover': {
      /*backgroundColor: palette['pale-grey'],*/
      transition: 'background-color 300ms linear'
    },
  },
  transparent: {
    borderColor: 'rgba(0, 0, 0, 0)',
    backgroundColor: 'rgba(0,0,0,0)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
      transition: 'background-color 300ms linear'
    }
  },
  profileButton: {
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  avatar: {
    width: '36px',
    height: '36px',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  avatarEmphasized: {
    borderColor: 'white'
  },
  placeholder: {
    overflow: 'hidden',
    borderRadius: '50%',
    /*backgroundColor: palette['pale-grey'],*/
    border: '1px solid',
    borderColor: palette['pale-grey'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  menuNav: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  menuButton: {
    color: theme.palette.primary.main,
    '& > svg > g':{
      fill:theme.palette.primary.main
    },
    [theme.breakpoints.up(1150)]: {
      display: 'none'
    }
  },
  hideMobile: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  signIn: {
    '& span':{
      color: palette['black'],
    }
  }
})

const UserMenu = withRouter(withStyles(styles)(({classes, userInfo, toggleDrawer, history, animated, setAnimated, setPopper,headerClasses}) => {
  const [isAuthenticated] = useAuth()

  const userName = userInfo && userInfo.info ? userInfo.info.firstName : ''
  const shortName = userInfo && userInfo.info && userInfo.info.firstName ? userInfo.info.firstName[0] : ''
  const shortLastName = userInfo && userInfo.info && userInfo.info.lastName ? userInfo.info.lastName[0] : ''
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
/*
  const handlePopperOpen = (event) => {
    // setOpenPopper(true)
    setPopper(true)
  }

  const handlePopperClose = () => {
    // setOpenPopper(false)
    setPopper(false)
  }*/

  useEffect(() => {
    handleClose()
  }, [history.location])

  return <Box display='flex' alignItems='center'>

    <Box className={classes.navLinks} id='anymeets-menu'>

      {/*<Link to='/joined-events'> Joined Events </Link>*/}
      {/*<Link to='/my-tickets'> My tickets </Link>*/}
      {/*<Link to='/favorites'> Favorites </Link>*/}

    {/*<Link to='#' onMouseEnter={handlePopperOpen}
            onMouseLeave={handlePopperClose}>

        How it works

      </Link>

      <NavLink to='/features'> Features </NavLink>
      <NavLink to='/about'> About </NavLink>*/}

      {isAuthenticated&&<Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menuNav}
      >
        {/* <Link to='/create-event'><MenuItem>Create Event</MenuItem></Link> */}
        {/* <Link to='/select-package'><MenuItem>Create Event</MenuItem></Link> */}
        <Link to='/joined-events'><MenuItem>Joined Events</MenuItem></Link>

        {/* <Link to='/search'><MenuItem>Search Events</MenuItem></Link> */}
        {/* <Link to='/my-tickets'><MenuItem>My tickets</MenuItem></Link> */}
        <Link to='/manage-events'><MenuItem>Manage Events</MenuItem></Link>
        {/* <Link to='/favorites'><MenuItem>Favorites</MenuItem></Link> */}
        {/* <Link to='/profile/settings'><MenuItem>Profile</MenuItem></Link> */}
        {/* <a href='https://docs.anymeets.com/' target={'blank'}><MenuItem>Help</MenuItem></a> */}

        <Link to='/sign-out'><MenuItem>Sign Out</MenuItem></Link>
      </Menu>}

    </Box>

    {/*<Box mr={2}><Link to='/search'><IconSearchBorderDark/></Link></Box>*/}
    {/*<Box mr={2} className={classes.hideMobile}><Link to='/notifications'><IconBellBorderDark/></Link></Box>*/}

    {/*<Box className={classes.hideMobile}>*/}
    {/*  <Box mr={2}>*/}
    {/*    <Link to='/manage-events'><Button size='smaller' variant='outlined'> Manage events </Button></Link>*/}
    {/*  </Box>*/}
    {/*</Box>*/}

    {/*<Box mr={2}>*/}
    {/*  <Link to='/create-event'><Button color='primary' size='smaller'> Create event </Button></Link>*/}
    {/*</Box>*/}

    <Box className={clsx(classes.userBlock, animated&&classes.transparent)}>
      {isAuthenticated&&<Box>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.profileButton}
        >
          {userInfo && userInfo.info && userInfo.info.pictureId ?
            <img className={clsx(classes.avatar,animated&&classes.avatarEmphasized)} title={userName} alt={userName}
                 src={`${FILES_URL}/${userInfo.info.pictureId}`}/> :
            <Box width={36} height={36} className={classes.placeholder}> {shortName} {shortLastName} </Box>}
        </IconButton>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={toggleDrawer('right', true)}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
      </Box>}
      {!isAuthenticated&&<Box className={clsx(classes.registerBlock, animated&&classes.transparent)} >
        <Box> <Link to='/login'> <MUIButton component={Box} className={clsx(classes.registerButton,animated&&classes.transparentSignIn,classes.signIn,headerClasses.noShadow)} color='default'>Sign in</MUIButton></Link> </Box>
      </Box>}
    </Box>


  </Box>
}))

export default UserMenu
