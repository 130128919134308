import LocalizedStrings from "react-localization";

let language = new LocalizedStrings({
  en: {
    //General Page
    details: "Details",
    dateAndTime: "Date & time",
    location: "Location",
    typeAddressCityOrZipCodeToLocatEvenueMap: "Type address, city or Zip code to locate venue in map",
    fillinAddressDetailsIfNecessary: "Fill in address details if necessary",
    eventURL: "Event URL",
    customURL: "Custom URL",
    customBrandedDomain: "Custom/Branded Domain",
    webUrl: "Web URL",
    exportAgenda: "Export Agenda",
    searchLocation: "Search location",
    link: "Link",
    eventName: "Event Name",
    eventAcronym: "Event Acronym",
    category: "Category",
    description: "Description",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    timezone: "Timezone",
    isThisAnOnlineOnlyEvent: "Is this an online only event?",
    venueLocation: "Venue Location",
    venue: "Venue",
    venueName: "Venue Name",
    venueDetails: "Venue Details",
    address: "Address",
    city: "City",
    zipCode: "Zip Code",
    country: "Country",
    saveChanges: "Save Changes",
    publish: "Publish",
    exportAgendaAsDocx: " Export Agenda as .docx",
    addaConferenceLinkForAnOnlineMeeting: " Add a conference link for an online meeting",
    yourEventWillBeAssignedTheURLAnymeetscom: "Your event will be assigned the URL: <b>anymeets.com/my-event.</b><br/>This cannot be changed once it has been assigned.",
    pleaseMakeSureYouOwnTheDomainYouWouldLikeToAssociateWithYourEvent: "Please make sure you own the domain you would like to associate with your event.<br/> Your event will appear with the URL: <b>my-event.com</b>",
    theEndingDateTimeMustBeLaterThanTheStartingDateTime: "The ending date/time must be later than the starting date/time",
    language: "Language",
    event: "Event",
    eventis: "Event",
    //Event Page Side Menou
    info: "Info",
    about: "About",
    agenda: "Agenda",
    speakers: "Speakers",
    keynoteSpeakers: "Keynote Speakers",
    sponsors: "Sponsors",
    organizer: "Organizer",
    organizers: "Organizers",
    proceedings: "Proceedings",
    hotels: "Hotels",
    restaurants: "Restaurants",
    socialFeed: "Social Feed",
    allTimesDisplayedHaveBeenConvertedToYourLocalTimezone: "All times displayed have been converted to your local timezone.",
    aCodeWillBeProvidedDuringTheSession: "A code will be provided during the session",
    information: "Information",
    cost: "Description",
    registrationsClosed: "Registrations Closed",
    registration: "Registration",
    registrations: "Registrations",
    submit: "Submit",
    callForPapersProposals: "Call for Papers/Proposals",
    reviewSubmissions: "Review submissions",
    submitReviews: "Submit reviews",
    theCodeWillBeProvidedDuringTheSession: "The code will be provided <br/> during the session",
    go: "Go",
    viewEvent: "View Event",
    previewEvent: "Preview event",
    youAreViewingAnEventWithUnpublishedChanges: "You are viewing an event with unpublished changes",
    leaveTheEvent: "Leave the event",
    joinTheEvent: "Join the event",
    noResultsFound: "No results found",
    reviews: "Reviews",
    submissions: "Submissions",
    noSubmissionsAvailableYet: "No submissions available yet",
    visitUs: "Visit us",
    noTicketsAvailableYet: "No tickets available yet",
    feedback: "Feedback",
    insertCode: "Insert Code",
    viewResults: "View results",
    thereAreCurrentlyNoLivePollsAvailable: "There are currently no live polls available",
    searchSessions: "Search sessions",
    all: "ALL",
    mySchedule: "My Schedule",
    day: "Day",
    keynote: "Keynote",
    room: "Room",
    close: "Close",
    posters: "Posters",
    presentations: "Presentations",
    watchRecording: "Watch recording",
    private: "Private",
    going: "Going",
    live: "Live",
    past: "Past",
    position: "Position",
    name: "Name",
    affiliationCompany: "Affiliation / Company",
    summary: "Summary",
    searchForASpeaker: "Search for a speaker",
    paper: "Paper",
    submitResponse: "Submit response",
    goBackToReviews: "Go back to reviews",
    yourReviewWasSubmittedSuccessfully: "Your review was submitted successfully",
    cafe: "Cafe",
    brasserieBistro: "Brasserie/Bistro",
    casualDining: "Casual dining",
    fastFood: "Fast food",
    fineDining: "Fine dining",
    localCuisine: "Local cuisine",
    pub: "Pub",
    hotel: "Hotel",
    apartment: "Apartment",
    hostel: "Hostel",
    bnb: "B&B",
    abstract: "Abstract",
    presentationTitle: "Presentation title",
    authors: "Authors",
    author: "Author",
    keywords: "Keywords",
    files: "Files",
    file: "File",
    searchForAPresentation: "Search for a presentation",
    academicConference: "Academic Conference",
    conference: "Conference",
    seminar: "Seminar",
    meeting: "Meeting",
    teamBuilding: "Team Building",
    networking: "Networking",
    business: "Business",
    workshop: "Workshop",
    hideInstructions: "Hide instructions",
    showInstructions: "Show instructions",
    type: "Type",
    availableTickets: "Available Tickets",
    price: "Price",
    pers: "pers",
    alreadyBought: "Already bought",
    ticket: "Ticket",
    tickets: "Tickets",
    buy: "Buy",
    more: "more",
    soldOut: "Sold Out",
    notAvailable: "Not Available",
    availableFrom: "Available from",
    availableOnlyOn: "Available only on",
    to: "to",
    free: "FREE",
    closesOn: "Closes on",
    closed: "Closed on",
    opensOn: "Opens on",
    buyTickets: "Buy tickets",
    applyCode: "Apply code",
    enterCouponCode: "Enter coupon code",
    get: "Get",
    getNow: "Get now",
    proceedToPayment: "Proceed to payment",
    total: "Total",
    alreadyReviewed: "Already reviewed",
    grade: "Grade",
    reviewGrade: "Review Grade",
    newPaperFor: "New paper for",
    paperFor: "Paper for",
    goBack: "Go back",
    reviewAndSubmit: "Review and submit",
    reSubmit: "Resubmit",
    presenter: "Presenter",
    downloadFile: "Download file",
    addAuthor: "Add author",
    youNeedToAddAnAuthorFirst: "You need to add an author first",
    platinum: "Platinum",
    gold: "Gold",
    silver: "Silver",
    bronze: "Bronze",
    exhibitor: "Exhibitor",
    mediaPartner: "Media Partner",
    researchSponsor: "Research Sponsor",
    diversitySponsor: "Diversity Sponsor",
    lanyardSponsor: "Lanyard Sponsor",
    communityPartner: "Community Partner",
    presentingSponsor: "Presenting Sponsor",
    partner: "Partner",
    withTheSupportOf: "With the support of",
    removeFile: "Remove file",
    dragAndDropFile: "Drag & Drop file",
    selectedCodes: "Selected Codes",
    selectBetween: "Select between",
    and: "and",
    codes: "codes",
    choices: "codes",
    eos: "and",
    cameraReady: "Camera Ready",
    cameraReadySubmissionsAreOpen: "Camera Ready submissions are open",
    updateSubmission: "Update Submission",
    cameraReadySubmissions: "Camera Ready submissions",
    uploadCameraReady: " Upload Camera Ready",
    uploadCameraReadyPdf: " Upload Camera Ready PDF",
    for: "for",
    editSubmissionForm: "Edit submission form",
    uploadCopyrightForm: "Upload Copyright Form",
    submissionFlowLabel: "Submission Flow Label",
    submissionFlowButtonLabel: "Submission Flow Button Label",
    showSpeakersForNonRegisteredUsers: "Show speakers for non-registered users",
  },
  el: {
    //General Page
    details: "Λεπτομέρειες",
    dateAndTime: "Ημερομηνία & Ώρα",
    location: "Τοποθεσία",
    typeAddressCityOrZipCodeToLocatEvenueMap: "Πληκτρολογήστε  διεύθυνση, πόλη ή Τ.Κ. για να εντοπίσετε το χώρο εκδήλωσης στο χάρτη",
    fillinAddressDetailsIfNecessary: "Συμπληρώστε λεπτομέρειες διεύθυνσης εφόσον είναι απαραίτητες",
    eventURL: "URL εκδήλωσης",
    customURL: "Προσαρμοσμένο URL",
    customBrandedDomain: "Προσαρμοσμένος Τομέας",
    webUrl: "Τομέας διαδικτύου",
    exportAgenda: "Εξαγωγή Προγράμματος",
    searchLocation: "Αναζήτηση τοποθεσίας",
    link: "Σύνδεσμος",
    eventName: "Όνομα Εκδήλωσης",
    eventAcronym: "Ακρωνύμιο Εκδήλωσης",
    category: "Κατηγορία",
    description: "Περιγραφή",
    startDate: "Ημερομηνία Έναρξης",
    endDate: "Ημερομηνία Λήξης",
    startTime: "Ώρα Έναρξης",
    endTime: "Ώρα Λήξης",
    timezone: "Ζώνη Ώρας",
    isThisAnOnlineOnlyEvent: "Η εκδήλωση διεξάγεται αποκλειστικά ψηφιακά;",
    venueLocation: "Τοποθεσία Χώρου εκδήλωσης",
    venue: "Χώρος Εκδήλωσης",
    venueName: "Όνομα χώρου εκδήλωσης",
    venueDetails: "Λεπτομέρειες χώρου εκδήλωσης",
    address: "Διεύθυνση",
    city: "Πόλη",
    zipCode: "Ταχυδρομικός Κώδικας",
    country: "Χώρα",
    saveChanges: "Αποθήκευση Αλλαγών",
    publish: "Δημοσίευση",
    exportAgendaAsDocx: " Εξαγωγή προγράμματος σε .docx",
    addaConferenceLinkForAnOnlineMeeting: "Προσθήκη συνδέσμου της ιστοσελίδας του ψηφιακού συνεδρίου",
    yourEventWillBeAssignedTheURLAnymeetscom: "Για την εκδήλωσή σας θα χρησιμοποιηθεί το URL: <b>anymeets.com/my-event.</b><br/> Αυτός ο σύνδεσμος δεν μπορεί να αλλάξει εφόσον δημιουργηθεί.",
    pleaseMakeSureYouOwnTheDomainYouWouldLikeToAssociateWithYourEvent:
      "Πρέπει να σας ανήκει το domain το οποίο θέλετε να χρησιμοποιήσετε για την εκδήλωσή σας.<br/> Για την εκδήλωσή σας θα χρησιμοποιηθεί το URL: <b>my-event.com</b>",
    theEndingDateTimeMustBeLaterThanTheStartingDateTime: "Η ημερομηνία/ώρα λήξης πρέπει να είναι μεταγενέστερη της ημερομηνίας/ώρας έναρξης.",
    language: "Γλώσσα",
    event: "Εκδήλωση",
    eventis: "Εκδήλωσης",
    //Event Page Side Menou
    info: "Γενικές Πληροφορίες",
    about: "Γενικές Πληροφορίες",
    agenda: "Πρόγραμμα",
    speakers: "Ομιλητές",
    keynoteSpeakers: "Κεντρικοί Ομιλητές",
    sponsors: "Χορηγοί",
    organizer: "Διοργανωτής",
    organizers: "Διοργανωτές",
    proceedings: "Παρουσιάσεις",
    hotels: "Ξενοδοχεία",
    restaurants: "Εστιατόρια",
    socialFeed: "Social Feed",
    allTimesDisplayedHaveBeenConvertedToYourLocalTimezone: "Οι ώρες που εμφανίζονται στο πρόγραμμα είναι στην τοπική σας ώρα/ζώνη ώρας.",
    aCodeWillBeProvidedDuringTheSession: "Κατά τη διάρκεια της συνεδρίας θα σας δωθεί κωδικός",
    information: "Πληροφορίες",
    cost: "Περιγραφή",
    registrationsClosed: "Οι εγγραφές έχουν κλείσει",
    registration: "Εγγραφή",
    registrations: "Εγγραφές",
    submit: "Υποβολή",
    callForPapersProposals: "Υποβολή Εργασιών/Περιλήψεων",
    reviewSubmissions: "Αξιολόγηση Εργασιών/Περιλήψεων",
    submitReviews: "Υποβολή αξιολόγησης",
    theCodeWillBeProvidedDuringTheSession: "Ο κωδικός θα δωθεί<br/> κατά τη διάρκεια της συνεδρίας",
    go: "Μετάβαση",
    viewEvent: "Επισκόπηση Εκδήλωσης",
    previewEvent: "Προεπισκόπηση Εκδήλωσης",
    youAreViewingAnEventWithUnpublishedChanges: "Η σελίδα της εκδήλωσης που βλέπετε περιέχει αλλάγες που δεν έχουν δημοσιευτεί",
    leaveTheEvent: "Αποχώρηση",
    joinTheEvent: "Συμμετοχή",
    noResultsFound: "Δε βρέθηκαν αποτελέσματα",
    reviews: "Αξιολογήσεις",
    submissions: "Υποβολές",
    noSubmissionsAvailableYet: "Δεν υπάρχουν διαθέσιμες υποβολές για αξιολόγηση",
    visitUs: "Ιστοσελίδα διοργανωτή",
    noTicketsAvailableYet: "Δεν υπάρχουν διαθέσιμα εισιτήρια",
    feedback: "Σχόλια/Ανάδραση",
    insertCode: "Εισαγωγή κωδικού",
    viewResults: "Ανασκόπηση αποτελεσμάτων",
    thereAreCurrentlyNoLivePollsAvailable: "Δεν υπάρχουν διαθέσιμα ερωτηματολόγια αυτήν τη στιγμή",
    searchSessions: "Αναζήτηση συνεδρίας",
    all: "ΟΛΕΣ",
    mySchedule: "Το πρόγραμμά μου",
    day: "Ημέρα",
    keynote: "Βασικές",
    room: "Αίθουσα",
    close: "Κλείσιμο",
    posters: "Posters",
    presentations: "Παρουσιάσεις",
    watchRecording: "Μαγνητοσκοπημένο",
    private: "Κλειστή",
    going: "Ενδιαφέρομαι",
    live: "Σε εξέλιξη",
    past: "Τετελεσμένη",
    position: "Θέση",
    name: "Όνομα",
    affiliationCompany: "Εταιρία / Ίδρυμα",
    summary: "Περιγραφή",
    searchForASpeaker: "Αναζήτηση ομιλητή",
    paper: "Εργασία",
    submitResponse: "Υποβολή απάντησης",
    goBackToReviews: "Επιστροφή στις αξιολογήσεις",
    yourReviewWasSubmittedSuccessfully: "Η αξιολόγηση σας υποβλήθηκε επιτυχώς",
    cafe: "Καφετέρια",
    brasserieBistro: "Bistro",
    casualDining: "Απλή κουζίνα",
    fastFood: "Γρήγορο φαγητό",
    fineDining: "Fine dining",
    localCuisine: "Τοπική κουζίνα",
    pub: "Μπυραρία",
    hotel: "Ξενοδοχείο",
    apartment: "Διαμέρισμα",
    hostel: "Hostel",
    bnb: "B&B",
    abstract: "Περίληψη",
    presentationTitle: "Τίτλος παρουσίασης",
    authors: "Συγγραφείς",
    author: "Συγγραφέας",
    keywords: "Λέξεις κλειδιά",
    files: "Αρχεία",
    file: "Αρχείο",
    searchForAPresentation: "Αναζήτηση παρουσίασης",
    academicConference: "Ακαδημαϊκό Συνέδριο",
    conference: "Συνέδριο",
    seminar: "Σεμινάριο",
    meeting: "Συνάντηση",
    teamBuilding: "Ομαδική δραστηριότητα",
    networking: "Δικτύωση",
    business: "Business",
    workshop: "Εργαστήριο",
    hideInstructions: "Απόκρυψη οδηγίων",
    showInstructions: "Εμφάνιση οδηγιών",
    type: "Τύπος",
    availableTickets: "Διαθέσιμα Εισιτήρια",
    price: "Τιμή",
    pers: "άτομο",
    alreadyBought: "Έχετε ήδη αγοράσει",
    ticket: "εισιτήριο",
    tickets: "εισιτήρια",
    buy: "Αγοράστε",
    more: "περισσότερα",
    soldOut: "Εξαντλήθηκαν",
    notAvailable: "Μη διαθέσιμα",
    availableFrom: "Διαθέσιμα από",
    availableOnlyOn: "Διαθέσιμα μόνο στις",
    to: "έως",
    free: "ΔΩΡΕΑΝ",
    closesOn: "Κλείνει στις",
    closed: "Έκλεισε στις",
    opensOn: "Ανοίγει στις",
    buyTickets: "Αγοράστε εισιτήρια",
    applyCode: "Εισάγετε κωδικό",
    enterCouponCode: "Εισαγετε κωδικό κουπονιού",
    get: "Αποκτήστε",
    getNow: "Αποκτήστε τώρα",
    proceedToPayment: "Προχωρήστε στη πληρωμή",
    total: "Σύνολο",
    alreadyReviewed: "Έχει ήδη αξιολογηθεί",
    grade: "Βαθμός",
    reviewGrade: "Βαθμός αξιολόγησης",
    newPaperFor: "Νέα εργασία για",
    paperFor: "Εργασία για",
    goBack: "Προηγούμενο",
    reviewAndSubmit: "Αξιολόγηση και υποβολή",
    reSubmit: "Επανυποβολή",
    presenter: "Παρουσιαστής",
    downloadFile: "Κατεβάστε το αρχείο",
    addAuthor: "Προσθέστε συγγραφέα",
    youNeedToAddAnAuthorFirst: "Πρέπει πρώτα να προσθέσετε ένα συγγραφέα",
    platinum: "Μέγας Χορηγός",
    gold: "Χρυσός Χορηγός",
    silver: "Χορηγός",
    bronze: "Επίσημος Χορηγός",
    exhibitor: "Εκθέτης",
    mediaPartner: "Χορηγός Επικοινωνίας",
    researchSponsor: "Επιστημονικός Χορηγός",
    diversitySponsor: "Diversity Sponsor",
    lanyardSponsor: "Lanyard Sponsor",
    communityPartner: " Κοινωνικός Συνεργάτης",
    presentingSponsor: "Presenting Sponsor",
    partner: "Σε συνεργασία με",
    withTheSupportOf: "Με την υποστήριξη",
    removeFile: "Διαγραφή αρχείου",
    dragAndDropFile: "Σύρετε και αποθέστε το αρχείο",
    selectedCodes: "Επιλεγμένοι κωδικοί",
    selectBetween: "Επιλέξτε από ",
    and: "έως",
    codes: "codes",
    choices: "απο τις επιλογές",
    eos: "έως",
    cameraReady: "Camera Ready",
    cameraReadySubmissionsAreOpen: "Camera ready submissions are open",
    updateSubmission: "Update Submission",
    cameraReadySubmissions: "Camera ready submissions",
    uploadCameraReady: " Upload Camera Ready",
    uploadCameraReadyPdf: " Upload Camera Ready PDF",
    for: "for",
    editSubmissionForm: "Edit submission form",
    uploadCopyrightForm: "Upload Copyright Form",
    submissionFlowLabel: "Submission Flow Label",
    submissionFlowButtonLabel: "Submission Flow Button Label",
    showSpeakersForNonRegisteredUsers: "Εμφάνισε τους ομιλητές και σε μη εγγεγραμένους χρήστες",
  },
  de: {
    //General Page
    details: "Details",
    dateAndTime: "Date & time",
    location: "Location",
    typeAddressCityOrZipCodeToLocatEvenueMap: "Type address, city or Zip code to locate venue in map",
    fillinAddressDetailsIfNecessary: "Fill in address details if necessary",
    eventURL: "Event URL",
    customURL: "Custom URL",
    customBrandedDomain: "Custom/Branded Domain",
    webUrl: "Web URL",
    exportAgenda: "Export Agenda",
    searchLocation: "Search location",
    link: "Link",
    eventName: "Event Name",
    eventAcronym: "Event Acronym",
    category: "Category",
    description: "Description",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    timezone: "Timezone",
    isThisAnOnlineOnlyEvent: "Is this an online only event?",
    venueLocation: "Venue Location",
    venue: "Venue",
    venueName: "Venue Name",
    venueDetails: "Venue Details",
    address: "Address",
    city: "City",
    zipCode: "Zip Code",
    country: "Country",
    saveChanges: "Save Changes",
    publish: "Publish",
    exportAgendaAsDocx: " Export Agenda as .docx",
    addaConferenceLinkForAnOnlineMeeting: " Add a conference link for an online meeting",
    yourEventWillBeAssignedTheURLAnymeetscom: "Your event will be assigned the URL: <b>anymeets.com/my-event.</b><br/>This cannot be changed once it has been assigned.",
    pleaseMakeSureYouOwnTheDomainYouWouldLikeToAssociateWithYourEvent: "Please make sure you own the domain you would like to associate with your event.<br/> Your event will appear with the URL: <b>my-event.com</b>",
    theEndingDateTimeMustBeLaterThanTheStartingDateTime: "The ending date/time must be later than the starting date/time",

    //Event Page Side Menou
    info: "Info",
    about: "About",
    agenda: "Agenda",
    speakers: "Speakers",
    keynoteSpeakers: "Keynote Speakers",
    sponsors: "Sponsors",
    organizer: "Organizer",
    organizers: "Organizers",
    proceedings: "Proceedings",
    hotels: "Hotels",
    restaurants: "Restaurants",
    socialFeed: "Social Feed",
    allTimesDisplayedHaveBeenConvertedToYourLocalTimezone: "All times displayed have been converted to your local timezone.",
    aCodeWillBeProvidedDuringTheSession: "A code will be provided during the session",
    information: "Information",
    cost: "Description",
    registrationsClosed: "Registrations Closed",
    registration: "Registration",
    registrations: "Registrations",
    submit: "Submit",
    callForPapersProposals: "Call for Papers/Proposals",
    reviewSubmissions: "Review submissions",
    submitReviews: "Submit reviews",
    theCodeWillBeProvidedDuringTheSession: "The code will be provided <br/> during the session",
    go: "Go",
    viewEvent: "View Event",
    previewEvent: "Preview event",
    youAreViewingAnEventWithUnpublishedChanges: "You are viewing an event with unpublished changes",
    leaveTheEvent: "Leave the event",
    joinTheEvent: "Join the event",
    noResultsFound: "No results found",
    reviews: "Reviews",
    submissions: "Submissions",
    noSubmissionsAvailableYet: "No submissions available yet",
    visitUs: "Visit us",
    noTicketsAvailableYet: "No tickets available yet",
    feedback: "Feedback",
    insertCode: "Insert Code",
    viewResults: "View results",
    thereAreCurrentlyNoLivePollsAvailable: "There are currently no live polls available",
    searchSessions: "Search sessions",
    all: "ALL",
    mySchedule: "My Schedule",
    day: "Day",
    keynote: "Keynote",
    room: "Room",
    close: "Close",
    posters: "Posters",
    presentations: "Presentations",
    watchRecording: "Watch recording",
    private: "Private",
    going: "Going",
    live: "Live",
    past: "Past",
    position: "Position",
    name: "Name",
    affiliationCompany: "Affiliation / Company",
    summary: "Summary",
    searchForASpeaker: "Search for a speaker",
    paper: "Paper",
    submitResponse: "Submit response",
    goBackToReviews: "Go back to reviews",
    yourReviewWasSubmittedSuccessfully: "Your review was submitted successfully",
    cafe: "Cafe",
    brasserieBistro: "Brasserie/Bistro",
    casualDining: "Casual dining",
    fastFood: "Fast Food",
    fineDining: "Fine dining",
    localCuisine: "Local cuisine",
    pub: "Pub",
    hotel: "Hotel",
    apartment: "Apartment",
    hostel: "Hostel",
    bnb: "B&B",
    abstract: "Abstract",
    presentationTitle: "Presentation title",
    authors: "Authors",
    author: "Author",
    keywords: "Keywords",
    files: "Files",
    file: "File",
    searchForAPresentation: "Search for a presentation",
    academicConference: "Academic Conference",
    conference: "Conference",
    seminar: "Seminar",
    meeting: "Meeting",
    teamBuilding: "Team Building",
    networking: "Networking",
    business: "Business",
    workshop: "Workshop",
    hideInstructions: "Hide instructions",
    showInstructions: "Show instructions",
    type: "Type",
    availableTickets: "Available Tickets",
    price: "Price",
    pers: "pers",
    alreadyBought: "Already bought",
    ticket: "Ticket",
    tickets: "Tickets",
    buy: "Buy",
    more: "more",
    soldOut: "Sold Out",
    notAvailable: "Not Available",
    availableFrom: "Available from",
    availableOnlyOn: "Available only on",
    to: "to",
    free: "FREE",
    closesOn: "Closes on",
    closed: "Closed on",
    opensOn: "Opens on",
    buyTickets: "Buy tickets",
    applyCode: "Apply code",
    enterCouponCode: "Enter coupon code",
    get: "Get",
    getNow: "Get now",
    proceedToPayment: "Proceed to payment",
    total: "Total",
    alreadyReviewed: "Already reviewed",
    grade: "Grade",
    reviewGrade: "Review Grade",
    newPaperFor: "New paper for",
    paperFor: "Paper for",
    goBack: "Go back",
    reviewAndSubmit: "Review and submit",
    reSubmit: "Resubmit",
    presenter: "Presenter",
    downloadFile: "Download file",
    addAuthor: "Add author",
    youNeedToAddAnAuthorFirst: "You need to add an author first",
    platinum: "Platinum",
    gold: "Gold",
    silver: "Silver",
    bronze: "Bronze",
    exhibitor: "Exhibitor",
    mediaPartner: "Media Partner",
    researchSponsor: "Research Sponsor",
    diversitySponsor: "Diversity Sponsor",
    lanyardSponsor: "Lanyard Sponsor",
    communityPartner: "Community Partner",
    presentingSponsor: "Presenting Sponsor",
    partner: "Partner",
    withTheSupportOf: "With the support of",
    removeFile: "Remove file",
    dragAndDropFile: "Drag & Drop file",
    selectedCodes: "Selected Codes",
    selectBetween: "Select between",
    and: "and",
    codes: "codes",
    choices: "codes",
    eos: "and",
    cameraReady: "Camera Ready",
    cameraReadySubmissionsAreOpen: "Camera ready submissions are open",
    updateSubmission: "Update Submission",
    cameraReadySubmissions: "Camera ready submissions",
    uploadCameraReady: " Upload Camera Ready",
    uploadCameraReadyPdf: " Upload Camera Ready PDF",
    for: "for",
    editSubmissionForm: "Edit submission form",
    uploadCopyrightForm: "Upload Copyright Form",
    submissionFlowLabel: "Submission Flow Label",
    submissionFlowButtonLabel: "Submission Flow Button Label",
    showSpeakersForNonRegisteredUsers: "Show speakers for non-registered users",
  },
  fr: {
    //General Page
    details: "Details",
    dateAndTime: "Date & time",
    location: "Location",
    typeAddressCityOrZipCodeToLocatEvenueMap: "Type address, city or Zip code to locate venue in map",
    fillinAddressDetailsIfNecessary: "Fill in address details if necessary",
    eventURL: "Event URL",
    customURL: "Custom URL",
    customBrandedDomain: "Custom/Branded Domain",
    webUrl: "Web URL",
    exportAgenda: "Export Agenda",
    searchLocation: "Search location",
    link: "Link",
    eventName: "Event Name",
    eventAcronym: "Event Acronym",
    category: "Category",
    description: "Description",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    timezone: "Timezone",
    isThisAnOnlineOnlyEvent: "Is this an online only event?",
    venueLocation: "Venue Location",
    venue: "Venue",
    venueName: "Venue Name",
    venueDetails: "Venue Details",
    address: "Address",
    city: "City",
    zipCode: "Zip Code",
    country: "Country",
    saveChanges: "Save Changes",
    publish: "Publish",
    exportAgendaAsDocx: " Export Agenda as .docx",
    addaConferenceLinkForAnOnlineMeeting: " Add a conference link for an online meeting",
    yourEventWillBeAssignedTheURLAnymeetscom: "Your event will be assigned the URL: <b>anymeets.com/my-event.</b><br/>This cannot be changed once it has been assigned.",
    pleaseMakeSureYouOwnTheDomainYouWouldLikeToAssociateWithYourEvent: "Please make sure you own the domain you would like to associate with your event.<br/> Your event will appear with the URL: <b>my-event.com</b>",
    theEndingDateTimeMustBeLaterThanTheStartingDateTime: "The ending date/time must be later than the starting date/time",

    //Event Page Side Menou
    info: "Info",
    about: "About",
    agenda: "Agenda",
    speakers: "Speakers",
    keynoteSpeakers: "Keynote Speakers",
    sponsors: "Sponsors",
    organizer: "Organizer",
    organizers: "Organizers",
    proceedings: "Proceedings",
    hotels: "Hotels",
    restaurants: "Restaurants",
    socialFeed: "Social Feed",
    allTimesDisplayedHaveBeenConvertedToYourLocalTimezone: "All times displayed have been converted to your local timezone.",
    aCodeWillBeProvidedDuringTheSession: "A code will be provided during the session",
    information: "Information",
    cost: "Description",
    registrationsClosed: "Registrations Closed",
    registration: "Registration",
    registrations: "Registrations",
    submit: "Submit",
    callForPapersProposals: "Call for Papers/Proposals",
    reviewSubmissions: "Review submissions",
    submitReviews: "Submit reviews",
    theCodeWillBeProvidedDuringTheSession: "The code will be provided <br/> during the session",
    go: "Go",
    viewEvent: "View Event",
    previewEvent: "Preview event",
    youAreViewingAnEventWithUnpublishedChanges: "You are viewing an event with unpublished changes",
    leaveTheEvent: "Leave the event",
    joinTheEvent: "Join the event",
    noResultsFound: "No results found",
    reviews: "Reviews",
    submissions: "Submissions",
    noSubmissionsAvailableYet: "No submissions available yet",
    visitUs: "Visit us",
    noTicketsAvailableYet: "No tickets available yet",
    feedback: "Feedback",
    insertCode: "Insert Code",
    viewResults: "View results",
    thereAreCurrentlyNoLivePollsAvailable: "There are currently no live polls available",
    searchSessions: "Search sessions",
    all: "ALL",
    mySchedule: "My Schedule",
    day: "Day",
    keynote: "Keynote",
    room: "Room",
    close: "Close",
    posters: "Posters",
    presentations: "Presentations",
    watchRecording: "Watch recording",
    private: "Private",
    going: "Going",
    live: "Live",
    past: "Past",
    position: "Position",
    name: "Name",
    affiliationCompany: "Affiliation / Company",
    summary: "Summary",
    searchForASpeaker: "Search for a speaker",
    paper: "Paper",
    submitResponse: "Submit response",
    goBackToReviews: "Go back to reviews",
    yourReviewWasSubmittedSuccessfully: "Your review was submitted successfully",
    cafe: "Cafe",
    brasserieBistro: "Brasserie/Bistro",
    casualDining: "Casual dining",
    fastFood: "Fast food",
    fineDining: "Fine dining",
    localCuisine: "Local cuisine",
    pub: "Pub",
    hotel: "Hotel",
    apartment: "Apartment",
    hostel: "Hostel",
    bnb: "B&B",
    abstract: "Abstract",
    presentationTitle: "Presentation title",
    authors: "Authors",
    author: "Author",
    keywords: "Keywords",
    files: "Files",
    file: "File",
    searchForAPresentation: "Search for a presentation",
    academicConference: "Academic Conference",
    conference: "Conference",
    seminar: "Seminar",
    meeting: "Meeting",
    teamBuilding: "Team Building",
    networking: "Networking",
    business: "Business",
    workshop: "Workshop",
    hideInstructions: "Hide instructions",
    showInstructions: "Show instructions",
    type: "Type",
    availableTickets: "Available Tickets",
    price: "Price",
    pers: "pers",
    alreadyBought: "Already bought",
    ticket: "Ticket",
    tickets: "Tickets",
    buy: "Buy",
    more: "more",
    soldOut: "Sold Out",
    notAvailable: "Not Available",
    availableFrom: "Available from",
    availableOnlyOn: "Available only on",
    to: "to",
    free: "FREE",
    closesOn: "Closes on",
    closed: "Closed on",
    opensOn: "Opens on",
    buyTickets: "Buy tickets",
    applyCode: "Apply code",
    enterCouponCode: "Enter coupon code",
    get: "Get",
    getNow: "Get now",
    proceedToPayment: "Proceed to payment",
    total: "Total",
    alreadyReviewed: "Already reviewed",
    grade: "Grade",
    reviewGrade: "Review Grade",
    newPaperFor: "New paper for",
    paperFor: "Paper for",
    goBack: "Go back",
    reviewAndSubmit: "Review and submit",
    reSubmit: "Resubmit",
    presenter: "Presenter",
    downloadFile: "Download file",
    addAuthor: "Add author",
    youNeedToAddAnAuthorFirst: "You need to add an author first",
    platinum: "Platinum",
    gold: "Gold",
    silver: "Silver",
    bronze: "Bronze",
    exhibitor: "Exhibitor",
    mediaPartner: "Media Partner",
    researchSponsor: "Research Sponsor",
    diversitySponsor: "Diversity Sponsor",
    lanyardSponsor: "Lanyard Sponsor",
    communityPartner: "Community Partner",
    presentingSponsor: "Presenting Sponsor",
    partner: "Partner",
    withTheSupportOf: "With the support of",
    removeFile: "Remove file",
    dragAndDropFile: "Drag & Drop file",
    selectedCodes: "Selected Codes",
    selectBetween: "Select between",
    and: "and",
    codes: "codes",
    choices: "codes",
    eos: "and",
    cameraReady: "Camera Ready",
    cameraReadySubmissionsAreOpen: "Camera ready submissions are open",
    updateSubmission: "Update Submission",
    cameraReadySubmissions: "Camera ready submissions",
    uploadCameraReady: " Upload Camera Ready",
    uploadCameraReadyPdf: " Upload Camera Ready PDF",
    for: "for",
    editSubmissionForm: "Edit submission form",
    uploadCopyrightForm: "Upload Copyright Form",
    submissionFlowLabel: "Submission Flow Label",
    submissionFlowButtonLabel: "Submission Flow Button Label",
    showSpeakersForNonRegisteredUsers: "Show speakers for non-registered users",
  },
});

export default language;
