import React, {useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import GoogleMapReact from 'google-map-react'
import Geocode from 'react-geocode'

import {GOOGLE_MAPS_API, LOCATION_DEFAULT_MAP_LOCATION} from 'config'
import {IconMapPinFillGreen} from 'components/core/icons'
import Tooltip from '@material-ui/core/Tooltip'
import {averageGeolocation} from 'utils'
import {Link, withRouter} from 'react-router-dom'

const styles = () => ({
  eventMarker: {
    cursor: 'pointer'
  }
})

const parseAddressDetails = (location, setAddress) => {
  const addressObj = {
    zipCode: '',
    address:'',
    number:'',
    country:'',
    city:''
  }

  let number = ''
  
  // Get location information from Geocode response
  location.address_components.forEach((component, index) => {
    addressObj.zipCode = component.types.indexOf('postal_code') !== -1 ? component.long_name : addressObj.zipCode
    addressObj.address = component.types.indexOf('route') !== -1 ? component.long_name : addressObj.address
    number = component.types.indexOf('street_number') !== -1 ? component.long_name : number
    addressObj.country = component.types.indexOf('country') !== -1 ? component.long_name : addressObj.country
    addressObj.city = component.types.indexOf('locality') !== -1 && component.types.indexOf('locality') !== -1 ? component.long_name : addressObj.city
    addressObj.city = addressObj.city==='' && component.types.indexOf('administrative_area_level_1') !== -1 ? component.long_name : addressObj.city
  })

  setAddress&&setAddress({...addressObj, address: `${addressObj.address} ${number}`})
}

const Marker = withStyles(styles)(({classes, name='', id}) => {
  return <>
    {name?<Link to={`/event/${id}`}><Tooltip title={name} aria-label={name} className={classes.eventMarker}><IconMapPinFillGreen/></Tooltip></Link>:<Tooltip title={name} aria-label={name} className={classes.eventMarker}><IconMapPinFillGreen/></Tooltip>}
  </>
})

const Map = withStyles(styles)(({classes, address, setAddress, latLng, setLatLng, zoom=16, height='350px', width='100%', markers, name}) => {
  const defaultCenter = LOCATION_DEFAULT_MAP_LOCATION

  Geocode.setApiKey(GOOGLE_MAPS_API)
  useEffect(()=>{
    if (latLng) {
      Geocode.fromLatLng(latLng.lat,latLng.lng).then(
        response => {
          const address = response.results[0]
          parseAddressDetails(address, setAddress)
        },
        error => {
          console.error(error)
        }
      )
    }
  },[setAddress,latLng])

  return <Box className={classes.map} height={height} width={width}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API }}
      defaultCenter={defaultCenter}
      defaultZoom={zoom}
      center={latLng?latLng:markers?averageGeolocation(markers):null}
      onClick={({x,y,lat,lng,event})=>{
        setLatLng&&setLatLng({lat,lng})
      }}
    >
      {latLng&&<Marker {...latLng} name={name}/>}
      {markers&&markers.map((marker,i)=><Marker {...marker} />)}
    </GoogleMapReact>
  </Box>
})
export default withRouter(Map)
