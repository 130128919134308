import {createMuiTheme} from "@material-ui/core"

export const palette = {
    'black': '#000000',
    'aquamarine': '#0dc7e5',
    'cerulean-blue': '#0078ad',
    'greyish-brown': '#4a4a4a',
    'ice-blue': '#e6f4fe',
    'pale-sky-blue': '#ccdeef',
    'pale-grey': '#f0f9ff',
    'brown-grey': '#9b9b9b',
    'very-light-pink': '#efefef',
    'very-light-pink-two': '#d8d8d8',
    'white': '#ffffff',
    'imageBackgroundColor': '#fff',
    'infoPadding': 3,
    graphColors: [
      '#0078ad',
      '#557ac3',
      '#9575ca',
      '#cf6bbd',
      '#fb64a0',
      '#ff6b77',
      '#ff8448',
      '#ffa600'
    ]
}

const fontFamilies = [
  'Roboto',
  'Karla',
  'Helvetica Neue',
  'Helvetica',
  '-apple-system',
  'Arial',
  'sans-serif',
  'Nunito'
].join(',')

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: palette['pale-sky-blue'],
      main: palette['cerulean-blue'],
    },
    secondary: {
      main: palette['aquamarine'],
      light: '#9ee9f5'
    },
    text: {
      primary: palette['greyish-brown'],
      secondary: palette['aquamarine']
    },
    background: {
      // paper: palette['pale-grey']
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: palette['pale-sky-blue']
      }
    },
    MuiTextField: {
      root: {
        // borderRadius: '6px',
        // borderTopLeftRadius: '6px',
        // borderTopRightRadius: '6px',
        // borderBottomLeftRadius: '6px',
        // borderBottomRightRadius: '6px',
        // border: `1px solid ${palette['very-light-pink']}`,
      }
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey'],
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
        '&$focused' : {
          backgroundColor: '#eeeeee'
        },
        '&$disabled' : {
          backgroundColor: '#eeeeee'
        }
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background: palette.white
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme1 = createMuiTheme({
  key: 1,
  palette: {
    primary: {
      light: '#c4f6ee',
      main: '#0d87cf'
    },
    secondary: {
      main: '#50ace1',
      light: '#9ee9f5'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#50ace1'
    },
    background: {
      primary:'#0d87cf'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#ccdeef'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },

    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      },
      primary: {
        light: '#c4f6ee',
        main: '#0d87cf'
      },
      secondary: {
        main: '#50ace1',
        light: '#9ee9f5'
      },
    }
  }
})

export const theme2 = createMuiTheme({
  key: 2,
  palette: {
    primary: {
      light: '#fde1ec',
      main: '#fa5c41'
    },
    secondary: {
      main: '#ff8d76',
      light: '#e5aca9'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#e57985'
    },
    background: {
      primary:'#f8e9d4'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#e5bdcd'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        backgroundColor: 'white',
        border: `1px solid ${palette['very-light-pink']}`,
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#fde1ec',
        main: '#fa5c41'
      },
      secondary: {
        main: '#ff8d76',
        light: '#e5aca9'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme3 = createMuiTheme({
  key: 3,
  palette: {
    primary: {
      light: '#cdffd6',
      main: '#529476'
    },
    secondary: {
      main: '#6dc29c',
      light: '#bdf1bd'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#84ad84'
    },
    background: {
      primary:'#e4efc5'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        backgroundColor: 'white',
        border: `1px solid ${palette['very-light-pink']}`,
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: '#eeeeee !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#cdffd6',
        main: '#529476'
      },
      secondary: {
        main: '#6dc29c',
        light: '#bdf1bd'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme4 = createMuiTheme({
  key: 4,
  palette: {
    primary: {
      light: '#efcae8',
      main: '#dd254c'
    },
    secondary: {
      main: '#ed708a',
      light: '#e5d4f2'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#ed708a'
    },
    background: {
      primary:'#ffc8d4'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },

    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#efcae8',
        main: '#dd254c'
      },
      secondary: {
        main: '#ed708a',
        light: '#e5d4f2'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme5 = createMuiTheme({
  key: 5,
  dark: true,
  palette: {
    primary: {
      light: '#ecf0c0',
      main: '#d4a11d'
    },
    secondary: {
      main: '#eaba3e',
      light: '#d7dbac'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#eaba3e'
    },
    background: {
      primary:'#d4a11d'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        backgroundColor: 'white',
        border: `1px solid ${palette['very-light-pink']}`,
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: '#eeeeee !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#c1ebc2',
        main: '#d4a11d'
      },
      secondary: {
        main: '#eaba3e',
        light: '#d7dbac'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme6 = createMuiTheme({
  key: 6,
  dark: true,
  palette: {
    primary: {
      light: '#d9e6fa',
      main: '#234c77'
    },
    secondary: {
      main: '#4277ae',
      light: '#cbd8ec'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#2c3d4f'
    },
    background: {
      primary:'#234c77'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        backgroundColor: 'white',
        border: `1px solid ${palette['very-light-pink']}`,
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#cbd8ec',
        main: '#234c77'
      },
      secondary: {
        main: '#4277ae',
        light: '#caf1d3'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme7 = createMuiTheme({
  key: 7,
  dark: true,
  palette: {
    primary: {
      light: '#ffe0b9',
      main: '#857a4c'
    },
    secondary: {
      main: '#d5ba8d',
      light: '#f7d8b1'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#d5ba8d'
    },
    background: {
      primary:'#857a4c'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      }
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        backgroundColor: 'white',
        border: `1px solid ${palette['very-light-pink']}`,
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#f7d8b1',
        main: '#857a4c'
      },
      secondary: {
        main: '#d5ba8d',
        light: '#caf1d3'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme8 = createMuiTheme({
  key: 8,
  dark: true,
  palette: {
    primary: {
      light: '#f4f5ec',
      main: '#543a4b'
    },
    secondary: {
      main: '#7d5d72',
      light: '#eeefe6'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#7d5d72'
    },
    background: {
      primary:'#543a4b'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#eeefe6',
        main: '#543a4b'
      },
      secondary: {
        main: '#7d5d72',
        light: '#caf1d3'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const theme9 = createMuiTheme({
  key: 9,
  dark: true,
  palette: {
    primary: {
      light: '#e2e1e1',
      main: '#2a2f33'
    },
    secondary: {
      main: '#5e666d',
      light: '#cdcccc'
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#5e666d'
    },
    background: {
      primary:'#2a2f33'
    }
  },
  typography: {
    fontFamily: fontFamilies
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#c0f2c9'
      }
    },
    MuiTextField: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette['brown-grey']
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
        '&$focused' : {
          backgroundColor: '#c0f2c9'
        },
        '&$disabled' : {
          backgroundColor: '#c0f2c9'
        }
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px'
      }
    },
    // Form helper text
    MuiFormHelperText: {
    },
    MuiPopover: {
      root: {
        top: '40px !important'
      }
    },
    MuiDialog: {
      paper: {
        // color: 'white',
        // background: palette['cerulean-blue']
        borderRadius: '6px'
      }
    },
    MuiMenu: {
      paper: {
        background:'white'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        light: '#cdcccc',
        main: '#2a2f33'
      },
      secondary: {
        main: '#5e666d',
        light: '#caf1d3'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const themeSession = createMuiTheme({
  key: 'session',
  palette: {
    primary: {
      light: palette['pale-sky-blue'],
      main: palette['cerulean-blue'],
    },
    secondary: {
      main: palette['aquamarine'],
      light: '#9ee9f5'
    },
    text: {
      primary: palette['greyish-brown'],
      secondary: palette['aquamarine']
    },
    background: {
      // paper: palette['pale-grey']
    }
  },
  typography: {
    fontFamily: fontFamilies,
    h3:{
      fontSize: 16,
      letterSpacing: 0,
      color: palette['greyish-brown'],
      fontWeight: 'bold'
    }
  },
  overrides: {
    MuiAppBar: {
      root:{
        // boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: palette['pale-sky-blue']
      }
    },
    // MuiTextField: {
    //   root: {
    //     borderRadius: '6px',
    //     borderTopLeftRadius: '6px',
    //     borderTopRightRadius: '6px',
    //     borderBottomLeftRadius: '6px',
    //     borderBottomRightRadius: '6px',
    //     border: `1px solid ${palette['very-light-pink']}`,
    //   }
    // },
    // MuiInputLabel: {
    //   root: {
    //     color: palette['brown-grey'],
    //   }
    // },
    MuiFilledInput: {
      root: {
        // borderRadius: '6px',
        // borderTopLeftRadius: '6px',
        // borderTopRightRadius: '6px',
        // borderBottomLeftRadius: '6px',
        // borderBottomRightRadius: '6px',
        // border: `1px solid ${palette['very-light-pink']}`,
        backgroundColor: 'white',
        // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
        '&:hover' : {
          backgroundColor: 'white !important'
        },
        '&$focused' : {
          backgroundColor: 'white !important'
        },
        '&$disabled' : {
          backgroundColor: 'white !important'
        }
      },
      input: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: `1px solid ${palette['very-light-pink']}`,
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    // MuiListItemIcon: {
    //   root: {
    //     minWidth: '40px'
    //   }
    // },
    // // Form helper text
    MuiInputLabel: {
      filled:{
        color: palette['brown-grey']
      }
    },
    // MuiPopover: {
    //   root: {
    //     top: '40px !important'
    //   }
    // },
    // MuiDialog: {
    //   paper: {
    //     // color: 'white',
    //     // background: palette['cerulean-blue']
    //     borderRadius: '6px'
    //   }
    // },
    // MuiMenu: {
    //   paper: {
    //     background: palette.white
    //   }
    // },
    MuiButton: {
      root: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    }
  }
})

export const themesEvent = {
  theme1,
  theme2,
  theme3,
  theme4,
  theme5,
  theme6,
  theme7,
  theme8,
  theme9
}
