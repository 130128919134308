import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import HeaderTitle from 'components/core/HeaderTitle'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import {Typography} from '@material-ui/core'
import PaperText from 'components/core/PaperText'
import Page from 'components/core/Page'

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  }
})

const Help = withStyles(styles)(({classes}) => {

  return <Page title='Help'>
    <HeaderTitle>Help</HeaderTitle>
    <Container fixed>

      <Box textAlign='center' mb={4} mt={6} pr={12} pl={12}>
        <Typography variant='h4' gutterBottom>Event management </Typography>
        <Typography variant='body1' gutterBottom>This software can be used for event registration, ticketing, seat allocations, and
          onsite operations management. It can also be utilized to conduct real-time surveys and polls to engage the
          audience and to use customer feedback to enhance future events.</Typography>
      </Box>

      <Box display='flex' justifyContent='center' mb={4}>
        <PaperText title='Easy to customize'
                   body='subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur'/>
        <PaperText title='Easy to customize'
                   body='subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur'/>
        <PaperText title='Easy to customize'
                   body='subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur'/>
      </Box>

      <Box display='flex' justifyContent='center'>
        <Box className={classes.root}>
          <Typography variant='body1' gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec ante pharetra, lacinia ante id, iaculis sapien. Aliquam tincidunt elementum malesuada. Nullam felis ante, aliquam et malesuada et, faucibus maximus massa. Quisque massa lorem, pharetra vitae mi eu, malesuada interdum dolor. In accumsan auctor ex, ut aliquet sapien tristique in. Donec luctus vitae enim sed tempus. Vivamus suscipit risus magna. Curabitur dapibus in libero vel laoreet. Sed ornare facilisis elit. Aliquam erat volutpat. Suspendisse suscipit faucibus ullamcorper. Pellentesque pretium dolor id lacus finibus, non tristique tortor lacinia. Curabitur ornare tortor vel urna ultrices mattis. Ut dictum imperdiet nisi non ultrices. Cras malesuada sit amet libero hendrerit imperdiet.</Typography>
          <Typography variant='body1' gutterBottom>Nam dapibus, ligula et porttitor mollis, lectus elit tincidunt purus, a pharetra tortor dui ut justo. Nullam sollicitudin aliquam enim in porta. Donec eget magna pretium, iaculis ipsum a, facilisis augue. Donec viverra erat ac felis tempus, quis egestas urna iaculis. Maecenas tellus enim, lobortis vel luctus et, pretium quis purus. Morbi sed metus quis lorem vestibulum aliquet. Vivamus ultrices aliquam arcu, sit amet vestibulum odio facilisis ac. Nulla suscipit blandit volutpat. Aliquam varius vel odio sit amet mollis. Morbi ac felis purus. Phasellus in sapien a massa vestibulum ullamcorper accumsan sed mi. Vestibulum mauris arcu, lobortis in ex ac, imperdiet tristique velit. Nullam pharetra hendrerit consectetur.</Typography>
          <Typography variant='body1' gutterBottom>Nunc ipsum est, tincidunt eu tortor ac, cursus scelerisque enim. Nulla interdum feugiat cursus. Fusce euismod iaculis ex, non varius erat semper vitae. Integer quis justo convallis, ornare ligula et, aliquam magna. Ut convallis felis vel nisl scelerisque venenatis. Mauris est ante, viverra sit amet leo ut, fringilla maximus felis. Suspendisse ultrices libero quam. Vivamus a varius mauris, ac iaculis enim. Sed efficitur massa at diam auctor venenatis.</Typography>
        </Box>
      </Box>

    </Container>
  </Page>
})

export default Help
