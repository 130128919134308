import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Field, Form, Formik} from 'formik'
import Box from '@material-ui/core/Box'
import {FormHelperText, Typography} from '@material-ui/core'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {withRouter} from 'react-router-dom'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {CATEGORIES, SIZE_LENGTH_DESCRIPTION, TIME_FADE_IN, LANG_LIST} from 'config'
import Fade from '@material-ui/core/Fade'
import InputLabel from '@material-ui/core/InputLabel'
import api from 'api'
import { useHttp } from 'api/core'
import moment from "moment";

const styles = theme => ({
  title: {
    color: 'white',
    textAlign: 'center'
  },
  form: {
    width: '100%',
    color: 'white',
  },
  formControl: {
    width: '100%',
    textAlign: 'left'
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  showMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
})

const CreateEventInformation = withStyles(styles)(({classes, formData, setFormData, prevRoute, nextRoute, history, setZone}) => {
  const [getTimeZones, timeZones] = useHttp();
  const [acronymAvailability, setAcronymAvailability] = useState(null)
  const [getAvailability, availabilityData] = useHttp()
  const AvailabilityTimeout = useRef(null);

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
  }, [])  
  
  useEffect(() => {
    if (formData.general.timezoneId && timeZones) {
      const selectedZone = timeZones?.find((zone) => zone.id === formData.general.timezoneId);
      moment.tz.setDefault(selectedZone.name);
      setZone(selectedZone.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, timeZones]);  

  const isAvailable = (value) => {
    if (acronymAvailability&&acronymAvailability.acronym===value) {
      return
    }
    clearTimeout(AvailabilityTimeout.current)
    setAcronymAvailability({acronym:value, available: null}) // we do not know yet
    if (value) {
      AvailabilityTimeout.current = setTimeout(()=>{
        getAvailability(api.entities.events.getAcronymAvailability(null,{v:value}))
      },2000)
    }
  }

  useEffect(()=>{
    if (availabilityData) {
      setAcronymAvailability({...acronymAvailability, available: availabilityData.available})
    }
    //eslint-disable-next-line
  },[availabilityData])

  const Schema = Yup.object().shape({
    general: Yup.object().shape({
      name: Yup.string().required("This field is required"),
      acronym: Yup.string().required("This field is required"),
      description: Yup.string(),
      category: Yup.string().required("This field is required"),
      // eventLanguage: Yup.string().required("This field is required"),
      timezoneId: Yup.string().required("This field is required"),
    }),
  });

  return (
    <>
      <Formik
        initialValues={{
          ...formData
        }}
        validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          if (acronymAvailability && acronymAvailability.available === false) {
            return false;
          }
          setFormData({...formData, ...values});
          history.push(nextRoute);
        }}>
        {({isSubmitting, errors, submitForm, setFieldValue, values, touched, validateField}) => (
          <>
            <Box className={classes.hideMobile}>
              <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute} />
            </Box>
            <Fade in={true} timeout={TIME_FADE_IN}>
              <Form className={classes.form}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" pt={4} minHeight={window.innerHeight - 280}>
                  <Box display="flex" width="350px" flexDirection="column" pb={8}>
                    <Typography component="h2" variant="h5" className={classes.title}>
                      Event Information
                    </Typography>
                    <Box mt={3} mb={2}>
                      <Field type="text" name="general.name" component={TextField} label="Event Name" variant="filled" fullWidth disabled={isSubmitting} />
                    </Box>
                    <Box mb={2}>
                      <Field type="text" name="general.acronym" component={TextField} label="Event Acronym" variant="filled" fullWidth disabled={isSubmitting} validate={(v) => isAvailable(v, validateField)} />
                      {acronymAvailability && acronymAvailability.available === false && touched.general && touched.general.acronym && (
                        <FormHelperText error variant="filled">
                          {" "}
                          Acronym is already in use{" "}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box mb={2}>
                      <Field type="text" name="general.description" component={TextField} multiline rows="6" label="Description" variant="filled" fullWidth disabled={isSubmitting} maxLength={SIZE_LENGTH_DESCRIPTION} />
                    </Box>
                    <Box mb={2}>
                      <FormControl className={classes.formControl}>
                        <Field type="select" labelId="categoryLabel" displayEmpty name="general.category" component={Select} label="Category" variant="filled" fullWidth disabled={isSubmitting} defaultValue="">
                          {Object.keys(CATEGORIES).map((key, i) => {
                            return (
                              <MenuItem value={key} key={i}>
                                {CATEGORIES[key]}
                              </MenuItem>
                            );
                          })}
                        </Field>
                        {errors && errors.general && errors.general.category && touched.general && touched.general.category && (
                          <FormHelperText error variant="filled">
                            {" "}
                            {errors.general.category}{" "}
                          </FormHelperText>
                        )}
                        <InputLabel variant="filled">Category</InputLabel>
                      </FormControl>
                    </Box>
                    <Box mb={2}>
                      <FormControl className={classes.formControl}>
                        <Field type="select" labelId="timezoneId" displayEmpty name="general.timezoneId" component={Select} label="Event timezone" variant="filled" fullWidth disabled={isSubmitting} >                        
                          {timeZones?.map((lang, i) =>
                          {
                            return (
                              <MenuItem value={lang.id} key={lang.id}>
                                {lang.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                        {errors && errors.general && errors.general.timezoneId && touched.general && touched.general.timezoneId &&(
                          <FormHelperText error variant="filled">
                            {" "}
                            {errors.general.timezoneId}{" "}
                            {JSON.stringify(touched)}
                          </FormHelperText>
                        )}
                        <InputLabel variant="filled">Event Timezone</InputLabel>
                      </FormControl>
                    </Box>
                    {/* <Box mb={2}>
                      <FormControl className={classes.formControl}>
                        <Field type="select" labelId="eventLanguage" displayEmpty name="general.eventLanguage" component={Select} label="Event Language" variant="filled" fullWidth disabled={isSubmitting} defaultValue="">
                          {LANG_LIST.map((lang, i) => {
                            return (
                              <MenuItem value={lang.id} key={i}>
                                {lang.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                        {errors && errors.general && errors.general.eventLanguage && (
                          <FormHelperText error variant="filled">
                            {" "}
                            {errors.general.eventLanguage}{" "}
                          </FormHelperText>
                        )}
                        <InputLabel variant="filled">Event language</InputLabel>
                      </FormControl>
                    </Box> */}
                    <Box className={classes.showMobile}>
                      <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute} />
                    </Box>
                  </Box>
                </Box>
              </Form>
            </Fade>
          </>
        )}
      </Formik>
    </>
  );
})

export default withRouter(CreateEventInformation)
