import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {Typography} from '@material-ui/core'

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    width: theme.spacing(24),
    minHeight: theme.spacing(16),
    padding: theme.spacing(4),
    backgroundColor: 'white'
  }
})

const PaperText = withStyles(styles)(({classes, title, body}) => {
  return <><ThemeProvider theme={theme}>
    <Paper elevation={3} className={classes.root}>
      <Typography variant='subtitle1' gutterBottom>{title}</Typography>
      <Typography variant="subtitle2" gutterBottom>
        {body}
      </Typography>
    </Paper>
  </ThemeProvider>
  </>
})

export default PaperText
