import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Button from 'components/core/Button'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import {palette} from '@material-ui/system'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import api from 'api'
import {useHttp} from 'api/core'
const styles = theme => ({
  container: {
    // padding: theme.spacing(2),
    borderRadius: '6px',
    width: 220,
    overflow: 'hidden',
    border: `1px solid ${palette['very-light-pink']}`
  },
  edit: {
    marginLeft: '-20px'
  }
})

const SponsorManageButton = withStyles(styles)(({classes, data}) => {

  // const [editRoom, setEditRoom] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  // const [newRoom, setNewRoom] = useState()
  const [getCSV, dataCSV] = useHttp()
  const [CsvData, setCsvData] = useState()


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const downloadCSV = () => {
    getCSV(api.entities.sponsor.getLead(null, null, {id: data.sponsor.id}))
  }

  useEffect(() => {
    if (dataCSV) {
      setCsvData(dataCSV)
    }
  }, [dataCSV])

  useEffect(() => {
    if (CsvData) {
      const csv = `data:text/csv;charset=utf-8,${dataCSV[0]}`
      let csvURI = encodeURI(csv)
      let downloadLink = document.createElement('a')
      downloadLink.href = csvURI
      downloadLink.download = `${data.eventInfo.name} - attendees.csv`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
  }, [CsvData, data, dataCSV])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return <>
    {/*{(editRoom||newRoom)&&<RoomsDialog open={(!!editRoom)||newRoom} roomId={editRoom} handleClose={handleCloseRoom} key={editRoom} eventId={eventId} newRoom={newRoom} session={session} />}*/}
    <Button aria-describedby={id} variant="outlined" size='smaller' color="primary" onClick={handleClick}>
      Manage Sponsor
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 200,
        horizontal: 'center',
      }}
    >
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List disablePadding>
              {/*<ListItem button>*/}
              {/*  <ListItemText*/}
              {/*    primary={'Edit details'}*/}
              {/*    // secondary={secondary ? 'Secondary text' : null}*/}
              {/*  />*/}
              {/*</ListItem>*/}
              <ListItem button onClick={()=>downloadCSV()}>
                <ListItemText
                  primary={'Download leads'}
                  // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  </>
})

export default SponsorManageButton
