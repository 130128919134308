import React, {useContext, useState} from 'react'
import {Helmet} from 'react-helmet'
import {TITLE_TEMPLATE} from 'config'
import {truncate} from 'utils'

export default () => {
  const [metaData] = useMetaData()
  return <>
    {metaData && <Helmet
      titleTemplate={metaData.title?TITLE_TEMPLATE:'Anymeets'}
      title={metaData.title?truncate(metaData.title,62-TITLE_TEMPLATE.length):' '}
    >
      <meta key="content-type" http-equiv="content-type" content="text/html; charset=UTF-8"/>
      <meta name="description" content={metaData.description?truncate(metaData.description,166):'Anymeets'} />

      {metaData.image&&<meta key="og:image" property="og:image" content={metaData.image} />}
      {metaData.canonical&&<meta key="og:url" property="og:url" content={metaData.canonical} /> }

      <meta key="og:site_name"  property="og:site_name" content={'Anymeets'} />
      <meta key="og:title" property="og:title" content={metaData.title?truncate(metaData.title,62-TITLE_TEMPLATE.length):' '} />
      <meta key="og:description" property="og:description" content={metaData.description?truncate(metaData.description,166):'Anymeets'} />
      <meta key="og:type" property="og:type" content="website" />

      {metaData.canonical&&<link rel="canonical" href={metaData.canonical} />}
    </Helmet>}
  </>
}

const useMetaController = () => {
  const [metaData, setMetaData] = useState(false)
  return [metaData, setMetaData]
}

const metaContext = React.createContext()

export const useMetaData = () => {
  return useContext(metaContext)
}

export const ProvideMeta = ({children}) => {
  const metaHook = useMetaController()
  return <metaContext.Provider value={metaHook}>
    {children}
  </metaContext.Provider>
}

