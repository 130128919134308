import Box from '@material-ui/core/Box'
import {FILES_URL, TIME_FADE_IN} from 'config'
import List from '@material-ui/core/List'
import {Link, NavLink, useLocation, useParams} from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {
  IconAgendaBorderDark,
  IconBookmarkBorderDark,
  IconCalendarBorderDark,
  IconCheckCircleBorderDark,
  IconCoffeeBorderDark,
  IconFileBorderDark,
  IconHelpCircleBorderDark,
  IconInfoBorderDark,
  IconManageBorderDark,
  IconSearchBorderDark,
  IconSignOutBorderDark,
  IconSpeakersBorderDark,
  IconStarBorderDark,
  IconTagBorderGray,
  IconUserBorderDark
} from 'components/core/icons'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemText from '@material-ui/core/ListItemText'
import React, { useEffect, useState } from "react";
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import { checkHost } from 'utils'
import language from "components/language";
import { useHttp } from "api/core";
import api from "api";

const styles = theme => ({
  avatarMobile: {
    width: '28px',
    height: '28px',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  navLinks: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  navLinksDrawer: {
    color: palette['greyish-brown'],
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(4),
    }
  },
  fullWidth: {
    width: '100%'
  },
  fullName: {
    fontWeight: 'bold'
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const MobileUserMenu = withStyles(styles)(({classes, userInfo, userName, event, extras}) => {
  const [getData, data, isLoadingData] = useHttp();
  const [contentsData, setContentsData] = useState([]);

  useEffect(() => {
    if (event) {
      getData(
        api.entities.manage.getContents(null, null, {
          eventId: event.eventInfo.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    if (data) {
      setContentsData(data.content);
    }
  }, [data]); 

  const q = useQuery()
  const isPreview = q.get('preview') === 'true'
  const previewSuffix = isPreview ? '?preview=true' : ''

  const {slug} = useParams()
  const host = checkHost()

  let baseURL = `/event/${event?.eventInfo?.id}`

  if (slug) {
    baseURL = `/${slug}`
  } else if (host) {
    baseURL = ``
  }

  function createNavLink(item) {
    switch (item.contentEnumType) {
      case 1:
        return (
          <Link to={`${baseURL}/info${previewSuffix}`}>
            <ListItem button key={"Info"}>
              <ListItemIcon>
                <IconInfoBorderDark />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </Link>
        );
      case 2:
        return (
          event.sessionsCount !== 0 && (
            <Link to={`${baseURL}/agenda${previewSuffix}`}>
              <ListItem button key={"Agenda"}>
                <ListItemIcon>
                  <IconCalendarBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 3:
        return (
          event.participantsCount !== 0 && (
            <Link to={`${baseURL}/participants${previewSuffix}`}>
              <ListItem button key={"Speakers"}>
                <ListItemIcon>
                  <IconSpeakersBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 4:
        return (
          event.keynoteSpeakers.length !== 0 && (
            <Link to={`${baseURL}/keynotespeakers${previewSuffix}`}>
              <ListItem button key={"Keynote speakers"}>
                <ListItemIcon>
                  <IconSpeakersBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 5:
        return (
          event.sponsors.length !== 0 && (
            <Link to={`${baseURL}/sponsors${previewSuffix}`}>
              <ListItem button key={"Sponsors"}>
                <ListItemIcon>
                  <IconStarBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 6:
        return (
          event.eventInfo.hasRestaurants && (
            <Link to={`${baseURL}/restaurants${previewSuffix}`}>
              <ListItem button key={"Restaurants"}>
                <ListItemIcon>
                  <IconCoffeeBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 7:
        return (
          event.eventInfo.hasHotels && (
            <Link to={`${baseURL}/hotels${previewSuffix}`}>
              <ListItem button key={"Hotels"}>
                <ListItemIcon>
                  <IconAgendaBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 8:
        return (
          event.organizers.length !== 0 && (
            <Link to={`${baseURL}/organizers${previewSuffix}`}>
              <ListItem button key={"Organizers"}>
                <ListItemIcon>
                  <IconSpeakersBorderDark />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );
      case 9:
        return (
          event.eventInfo.hasExtras &&
          extras.map((extra) => (
            <Link to={`${baseURL}/extra/${extra.id}${previewSuffix}`}>
              <ListItem button key={extra.title}>
                <ListItemIcon>
                  <IconTagBorderGray />
                </ListItemIcon>
                <ListItemText primary={extra.title} />
              </ListItem>
            </Link>
          ))
        );
      case 10:
        return (
          <Link to={`${baseURL}/general-page/${item.id}${previewSuffix}`}>
            <ListItem button key={`general-page-${item.id}`}>
              <ListItemIcon>
                <IconSpeakersBorderDark />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </Link>
        );
      case 11:
        return (
          ((event.eventInfo.showProceedings === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) && (
            <Link to={`${baseURL}/proceedings${previewSuffix}`}>
              <ListItem button key={"Proceedings"}>
                <ListItemIcon>
                  <IconFileBorderDark/>
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          )
        );      
      default:
        return "";
    }
  }


  return <Box className={classes.navLinksDrawer} flexDirection='column'>

    <Box display='flex' p={2} alignItems='center'>
      {userInfo && userInfo.info && userInfo.info.pictureId ?
        <img className={classes.avatarMobile} title={userName} alt={userName}
             src={`${FILES_URL}/${userInfo.info.pictureId}`}/> : ''}
      <Box pl={1} className={classes.fullName}>{userName}</Box>
    </Box>

    <Box display='flex' width='100%'>
      <List className={classes.fullWidth}>

        {(event && extras) && <>
          {data &&
            contentsData.map((item) => {
              return createNavLink(item);
            })} 
          
          {/* <Link to={`${baseURL}/info${previewSuffix}`}>
            <ListItem button key={'Info'}>
              <ListItemIcon><IconInfoBorderDark/></ListItemIcon>
              <ListItemText primary={language.info}/>
            </ListItem>
          </Link> */}

          {/* {event.sessionsCount !== 0 &&
          <NavLink to={`${baseURL}/agenda${previewSuffix}`}>
            <ListItem button key={'Agenda'}>
              <ListItemIcon><IconCalendarBorderDark/></ListItemIcon>
              <ListItemText primary={language.agenda}/>
            </ListItem>
          </NavLink>

          } */}

          {/* {event.participantsCount !== 0 &&
          <Link to={`${baseURL}/participants${previewSuffix}`}>
            <ListItem button key={'Speakers'}>
              <ListItemIcon><IconSpeakersBorderDark/></ListItemIcon>
              <ListItemText primary={language.speakers}/>
            </ListItem>
          </Link>
          } */}

          {/* {event.keynoteSpeakers.length !== 0 &&
          <Link to={`${baseURL}/keynotespeakers${previewSuffix}`}>
            <ListItem button key={'Keynote speakers'}>
              <ListItemIcon><IconSpeakersBorderDark/></ListItemIcon>
              <ListItemText primary={language.keynoteSpeakers}/>
            </ListItem>
          </Link>
          } */}

          {/* {event.sponsors.length !== 0 &&
          <Link to={`${baseURL}/sponsors${previewSuffix}`}>
            <ListItem button key={'Sponsors'}>
              <ListItemIcon><IconStarBorderDark/></ListItemIcon>
              <ListItemText primary={language.sponsors}/>
            </ListItem>
          </Link>
          } */}

          {/* {((event.eventInfo.showProceedings === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) &&
          <Link to={`${baseURL}/proceedings${previewSuffix}`}>
            <ListItem button key={'Proceedings'}>
              <ListItemIcon><IconFileBorderDark/></ListItemIcon>
              <ListItemText primary={language.proceedings}/>
            </ListItem>
          </Link>
          } */}

          {/* {event.eventInfo.hasExtras && extras && extras.map((extra) =>
            <Link to={`${baseURL}/extra/${extra.id}${previewSuffix}`}>
              <ListItem button key={extra.title}>
                <ListItemIcon><IconTagBorderGray/></ListItemIcon>
                <ListItemText primary={extra.title}/>
              </ListItem>
            </Link>
          )} */}

          {/* {event.eventInfo.hasHotels &&
          <Link to={`${baseURL}/hotels${previewSuffix}`}>
            <ListItem button key={'Hotels'}>
              <ListItemIcon><IconAgendaBorderDark/></ListItemIcon>
              <ListItemText primary={language.hotels}/>
            </ListItem>
          </Link>
          } */}

          {/* {event.eventInfo.hasRestaurants &&
          <Link to={`${baseURL}/restaurants${previewSuffix}`}>
            <ListItem button key={'Restaurants'}>
              <ListItemIcon><IconCoffeeBorderDark/></ListItemIcon>
              <ListItemText primary={language.restaurants}/>
            </ListItem>
          </Link>
          } */}
        </>
        
        }

        <Divider className={classes.divider}/>
        {/* <Link to='/create-event'> */}
        {/* <Link to='/select-package'>
          <ListItem button key={'Create Event'}>
            <ListItemIcon><AddCircleOutlineIcon/></ListItemIcon>
            <ListItemText primary={'Create Event'}/>
          </ListItem>
        </Link> */}
        <Link to='/manage-events'>
          <ListItem button key={'Manage Events'}>
            <ListItemIcon><IconManageBorderDark/></ListItemIcon>
            <ListItemText primary={'Manage Events'}/>
          </ListItem>
        </Link>
        {/* <Link to='/search'>
          <ListItem button key={'Browse Events'}>
            <ListItemIcon><IconSearchBorderDark/></ListItemIcon>
            <ListItemText primary={'Browse Events'}/>
          </ListItem>
        </Link> */}
        <Link to='/joined-events'>
          <ListItem button key={'Joined Events'}>
            <ListItemIcon><IconCalendarBorderDark/></ListItemIcon>
            <ListItemText primary={'Joined Events'}/>
          </ListItem>
        </Link>
        {/* <Link to='/my-tickets'>
          <ListItem button key={'My Tickets'}>
            <ListItemIcon><IconCheckCircleBorderDark/></ListItemIcon>
            <ListItemText primary={'My Tickets'}/>
          </ListItem>
        </Link>
        <Link to='/favorites'>
          <ListItem button key={'Favorites'}>
            <ListItemIcon><IconBookmarkBorderDark/></ListItemIcon>
            <ListItemText primary={'Favorites'}/>
          </ListItem>
        </Link>
        <Link to='/profile/settings'>
          <ListItem button key={'Profile'}>
            <ListItemIcon><IconUserBorderDark/></ListItemIcon>
            <ListItemText primary={'Profile'}/>
          </ListItem>
        </Link>
        <a href='https://docs.anymeets.com/' target={'blank'}>
          <ListItem button key={'Help'}>
            <ListItemIcon><IconHelpCircleBorderDark/></ListItemIcon>
            <ListItemText primary={'Help'}/>
          </ListItem>
        </a> */}
        <Link to='/sign-out'>
          <ListItem button key={'Sign Out'}>
            <ListItemIcon><IconSignOutBorderDark/></ListItemIcon>
            <ListItemText primary={'Sign Out'}/>
          </ListItem>
        </Link>

      </List>
    </Box>
  </Box>
})

export default MobileUserMenu
