import {defaultApi} from 'api/core'
import {SERVICE_URL} from 'config'

const api = defaultApi(`${SERVICE_URL}`)

// API Endpoints
//////////////////

const endpoints = {
  options: {
    getSessionTypes: {
      method: 'GET',
      path: '/options/session-types'
    },
    getSessionTypesSubmissionFlow: {
      method: 'GET',
      path: '/options/session-types'
    },
    getHotelCategories: {
      method: 'GET',
      path: '/options/hotel-categories'
    },
    getRestaurantCategories: {
      method: 'GET',
      path: '/options/restaurant-categories'
    },
    getBackgrounds: {
      method: 'GET',
      path: '/options/backgrounds'
    },
    getLogos: {
      method: 'GET',
      path: '/options/logos'
    },
    getThematicCodes: {
      method: 'GET',
      path: '/events/{id}/thematic-codes'
    }
  },
  reviewers: {
    getInvitation: {
      method: 'GET',
      path: '/reviewers/invitations'
    },
    replyToInvitation: {
      method: 'PUT',
      path: '/reviewers/invitations'
    },
    getReview: {
      method: 'GET',
      path: '/reviewers/submissions/{id}'
    },
    submitReview: {
      method: 'PUT',
      path: '/reviewers/submissions/{id}'
    }
  },
  presentations: {
    getAllByEvent: {
      method: 'GET',
      path: '/manage/events/{id}/presentations'
    },
    getAllBySession: {
      method: 'GET',
      path: '/manage/sessions/{id}/presentations'
    },
    get: {
      method: 'GET',
      path: '/manage/presentations/{id}'
    },
    update: {
      method: 'PUT',
      path: '/manage/presentations/{id}'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/presentations/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/presentations'
    },
    uploadPresentationsPreview: {
      method: 'POST',
      path: '/manage/presentations/{id}/preview'
    },
  },
  participants: {
    get: {
      method: 'GET',
      path: '/participants/{id}'
    },
    update: {
      method: 'PUT',
      path: '/manage/participants/{id}'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/participants/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/participants'
    },
    uploadParticipantsPreview: {
      method: 'POST',
      path: '/manage/participants/{id}/preview'
    },
  },
  attendees:{
    addNew:{
      method: 'POST',
      path:'/registrations/manual/{id}'
    },
    uploadAttendeesPreview:{
      method:'POST',
      path:'/registrations/manual/{id}/preview'
    }
  },
  sponsors: {
    update: {
      method: 'PUT',
      path: '/manage/sponsors/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/sponsors'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/sponsors/{id}'
    },
    viewOne: {
      method: 'GET',
      path: '/sponsors/{id}'
    },
    reorder: {
      method: 'PUT',
      path: '/manage/events/{id}/sponsors/reorder'
    }
  },
  communication: {
    downloadLogs: {
      method: 'GET',
      path: '/manage/communication-messages/{id}/failed'
    },
    getCommunications: {
      method: 'GET',
      path: '/manage/communications/{id}'
    },
    getSettings: {
      method: 'GET',
      path: '/manage/communications/{id}/settings'
    },
    setSettings: {
      method: 'PUT',
      path: '/manage/communications/{id}/settings'
    },
    getGroups: {
      method: 'GET',
      path: '/manage/communications/groups'
    },
    getGroupRecipients: {
      method: 'GET',
      path: '/manage/communications/{id}/groups/{groupId}'
    },
    getTemplates: {
      method: 'GET',
      path: '/manage/communications/{id}/templates'
    },
    getTemplate: {
      method: 'GET',
      path: '/manage/communications/{eventId}/templates/{templateId}'
    },
    getTemplateFields: {
      method: 'GET',
      path: '/manage/communications/template-fields'
    },
    sendEmail: {
      method : 'POST',
      path: '/manage/communications/{eventId}'
    }
  },
  mailLists: {
    get: {
      method: 'GET',
      path: '/manage/mail-lists/{id}'
    },
    update: {
      method: 'PUT',
      path: '/manage/mail-lists/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/mail-lists'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/mail-lists/{id}'
    }
  },
  tickets: {
    update: {
      method: 'PUT',
      path: '/manage/tickets/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/tickets'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/tickets/{id}'
    }
  },
  reviews: {
    reorder:{
      method: 'PUT',
      path: '/manage/events/{id}/review-flows/reorder'
    },
  },
  submissions: {
    reorder:{
      method: 'PUT',
      path: '/manage/events/{id}/submission-flows/reorder'
    },
    getReviewSubmissions: {
      method: 'GET',
      path: '/events/{id}/submissions'
    },
    getSubmissions: {
      method: 'GET',
      path: '/events/{id}/submission-flows'
    },
    getSubmission: {
      method: 'GET',
      path: '/submission-flows/{id}'
    },
    getSubmissionAnswer: {
      method: 'GET',
      path: '/submission-flows/{id}/responses/{answerId}'
    },
    addSubmission: {
      method: 'POST',
      path: '/submission-flows/{id}'
    },
    updateSubmissionAnswer: {
      method: 'PUT',
      path: '/submission-flows/{id}/responses/{answerId}'
    }
  },
  registrations: {
    getTickets: {
      method: 'GET',
      path: '/events/{id}/tickets',
      errorHandling: false
    },
    getAllTickets:{
      method: 'GET',
      path: '/registrations'
    },
    register: {
      method: 'POST',
      path: '/registrations'
    },
    getDiscount: {
      method: 'GET',
      path: '/registrations/discount'
    }
  },
  extras: {
    update: {
      method: 'PUT',
      path: '/manage/extras/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/extras'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/extras/{id}'
    },
    reorderExtras: {
      method: 'PUT',
      path: '/manage/events/{eventId}/extras/reorder'
    },      
  },
  hotels: {
    update: {
      method: 'PUT',
      path: '/manage/hotels/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/hotels'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/hotels/{id}'
    }
  },
  restaurants: {
    update: {
      method: 'PUT',
      path: '/manage/restaurants/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/restaurants'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/restaurants/{id}'
    }
  },
  organizers: {
    update: {
      method: 'PUT',
      path: '/manage/organizers/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/organizers'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/organizers/{id}'
    },
    reorder: {
      method: 'PUT',
      path: '/manage/events/{id}/organizers/reorder'
    }
  },
  thematicCodes: {
    update: {
      method: 'PUT',
      path: '/manage/thematic-codes/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/thematic-codes'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/thematic-codes/{id}'
    }
  },
  tracks: {
    update: {
      method: 'PUT',
      path: '/manage/tracks/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/tracks'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/tracks/{id}'
    }
  },
  sessionTypes: {
    update: {
      method: 'PUT',
      path: '/manage/session-types/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/session-types'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/session-types/{id}'
    }
  },
  rooms: {
    update: {
      method: 'PUT',
      path: '/manage/rooms/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/rooms'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/rooms/{id}'
    },
    list: {
      method: 'GET',
      path: '/manage/events/{id}/rooms/list'
    }
  },
  collaborations: {
    update: {
      method: 'PUT',
      path: '/manage/collaborations/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/collaborations'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/collaborations/{id}'
    }
  },
  account: {
    activationStatus: {
     method: 'GET',
     path: '/account/activation-status'
    },
    login: {
      method: 'POST',
      path: '/account/login'
    },
    register: {
      method: 'POST',
      path: '/account/register'
    },
    confirm: {
      method: 'POST',
      path: '/account/confirm'
    },
    forgotPassword: {
      method: 'POST',
      path: '/account/forgot-password'
    },
    resetPassword: {
      method: 'POST',
      path: '/account/reset-password'
    },
    resendConfirmation: {
      method: 'POST',
      path: '/account/resend-confirmation',
      errorHandling: false
    },
    upgrade: {
      method: 'POST',
      path: '/subscriptions'
    },
    upgradeToExisting: {
      method: 'POST',
      path: '/manage/events/{id}/subscriptions'
    }
  },
  manage: {
    downloadPresenters: {
      method: 'GET',
      path: '/manage/events/{id}/presenters.xlsx'
    },
    downloadCoAuthors: {
      method: 'GET',
      path: '/manage/events/{id}/coauthors.xlsx'
    },
    downloadSubmitters: {
      method: 'GET',
      path: '/manage/events/{id}/submitters.xlsx'
    },
    getPresenters: {
      method: 'GET',
      path: '/manage/events/{id}/presenters'
    },
    getCoAuthors: {
      method: 'GET',
      path: '/manage/events/{id}/coauthors'
    },
    getSubmitters: {
      method: 'GET',
      path: '/manage/events/{id}/submitters'
    },
    stopSessions: {
      method: 'POST',
      path: '/manage/events/{id}/sessions/stop'
    },
    downloadSessions: {
      method: 'GET',
      path: '/manage/events/{id}/sessions.xlsx'
    },
    setPaperAcceptance: {
      method: 'PUT',
      path: '/manage/papers/{id}/status'
    },
    setPaperSessionType: {
      method: 'PUT',
      path: '/manage/papers/{id}/session-type'
    },
    getPaperAcceptanceReviews: {
      method: 'GET',
      path: '/manage/events/{id}/papers/acceptance'
    },
    getPaperFiltered: {
      method: 'GET',
      path: '/manage/events/{id}/papers/acceptance/filtered?grades={grades}'
    },
    setPaperStatus: {
      method: 'PUT',
      path: '/manage/papers/status?submissionIds={submissionIds}'
    },
    downloadPaperReviews: {
      method: 'GET',
      path: '/manage/events/{id}/reviews.xlsx'
    },
    getPaperReviews: {
      method: 'GET',
      path: '/manage/events/{id}/reviews'
    },
    autoAssign: {
      method: 'POST',
      path: '/manage/events/{id}/reviewers/assignments/auto'
    },
    resetAssignments: {
      method: 'DELETE',
      path: '/manage/events/{id}/reviewers/assignments'
    },
    publishAssignments: {
      method: 'PUT',
      path: '/manage/events/{id}/reviewers/assignments/publish'
    },
    unpublishAssignments: {
      method: 'DELETE',
      path: '/manage/events/{id}/reviewers/assignments/publish'
    },
    assignReviewer: {
      method: 'PUT',
      path: '/manage/reviewers/{reviewerId}/assignments/{paperId}'
    },
    deAssignReviewer: {
      method: 'DELETE',
      path: '/manage/reviewers/{reviewerId}/assignments/{paperId}'
    },

    assignSession: {
      method: 'PUT',
      path: '/manage/sessions/{sessionId}/assignments/{paperId}'
    },
    deAssignSession: {
      method: 'DELETE',
      path: '/manage/sessions/{sessionId}/assignments/{paperId}'
    },

    getReviewerReview: {
      method: 'GET',
      path: '/manage/reviews/{id}'
    },
    getReviewerResponse: {
      method: 'GET',
      path: '/manage/reviewers/{id}/response'
    },
    getReviewFlows: {
      method: 'GET',
      path: '/manage/events/{id}/review-flows'
    },
    updateReviewFlow: {
      method: 'PUT',
      path: '/manage/review-flows/{id}'
    },
    addReviewFlow: {
      method: 'POST',
      path: '/manage/review-flows'
    },
    deleteReviewFlow: {
      method: 'DELETE',
      path: '/manage/review-flows/{id}'
    },
    getSubmissionFlowResponse: {
      method: 'GET',
      path: '/manage/submission-flows/{submissionFlowId}/responses/{answerId}'
    },
    getSubmissionFlowResponses: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/responses'
    },
    getSubmissionFlows: {
      method: 'GET',
      path: '/manage/events/{id}/submission-flows'
    },
    getAcceptedSubmission: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/accepted-papers'
    },
    getAcceptedPapersXlsx: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/accepted-papers.xlsx'
    },
    getAcceptedPapersZip: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/accepted-papers.zip'
    },    
    convertSubmissionToPapers: {
      method: 'POST',
      path: '/manage/events/{id}/accepted-papers/convert/{paperId}?convertAll={convertAll}'
    },
    getSubmissionListing: {
      method: 'GET',
      path: '/manage/events/{id}/submission-flows/options'
    },
    viewSubmission: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/responses/{answerId}'
    },
    updateSubmission: {
      method: 'PUT',
      path: '/manage/submission-flows/{id}/responses/{answerId}'
    },
    updateSubmissionFlow: {
      method: 'PUT',
      path: '/manage/submission-flows/{id}'
    },
    getSubmissionFlow: {
      method: 'GET',
      path: '/manage/submission-flows/{id}'
    },    
    addSubmissionFlow: {
      method: 'POST',
      path: '/manage/submission-flows'
    },
    deleteSubmissionFlow: {
      method: 'DELETE',
      path: '/manage/submission-flows/{id}'
    },
    putForms: {
      method: 'PUT',
      path: '/manage/events/{id}/reviewers/flow'
    },
    getAcceptForm: {
      method: 'GET',
      path: '/manage/events/{id}/reviewers/flow/accept-form'
    },
    getFlow: {
      method: 'GET',
      path: '/manage/events/{id}/reviewers/flow'
    },
    setFlowOpen: {
      method: 'POST',
      path: '/manage/events/{id}/reviewers/flow/open'
    },
    delFlowOpen: {
      method: 'DELETE',
      path: '/manage/events/{id}/reviewers/flow/open'
    },
    setAccepted: {
      method: 'PUT',
      path: '/manage/reviewers/{id}/accepted'
    },
    delAccepted: {
      method: 'DELETE',
      path: '/manage/reviewers/{id}/accepted'
    },
    deleteReviewer: {
      method: 'DELETE',
      path: '/manage/reviewers/{id}'
    },
    addReviewer: {
      method: 'POST',
      path: '/manage/reviewers'
    },
    updateReviewer: {
      method: 'PUT',
      path: '/manage/reviewers/{id}'
    },
    saveReviewers: {
      method: 'PUT',
      path: '/manage/events/{id}/reviewers'
    },
    getReviewers: {
      method: 'GET',
      path: '/manage/events/{id}/reviewers'
    },
    setPcm: {
      method: 'PUT',
      path: '/manage/reviewers/{id}/pcm'
    },
    delPcm: {
      method: 'DELETE',
      path: '/manage/reviewers/{id}/pcm'
    },
    uploadReviewers: {
      method: 'POST',
      path: '/manage/events/{id}/reviewers'
    },
    getThematicCodes: {
      method: 'GET',
      path: '/manage/events/{id}/thematic-codes'
    },
    getAssignedReviewers: {
      method: 'GET',
      path: '/manage/events/{id}/reviewers/assignments'
    },
    getAssignedSessions: {
      method: 'GET',
      path: '/manage/events/{id}/sessions/assignments'
    },
    convertAssignedSessions: {
      method: 'POST',
      path: '/manage/events/{id}/sessions/assignments'
    },    
    getTracks: {
      method: 'GET',
      path: '/manage/events/{id}/tracks',
      errorHandling: false,
    },
    getSessionTypes: {
      method: 'GET',
      path: '/manage/events/{id}/session-types',
      errorHandling: false,
    },
    getRooms: {
      method: 'GET',
      path: '/manage/events/{id}/rooms'
    },
    getStatistics: {
      method: 'GET',
      path: '/manage/events/{id}/statistics'
    },
    getBoothParticipation: {
      method: 'GET',
      path: '/manage/events/{id}/sponsors/user-participation'
    },
    getRoomParticipation: {
      method: 'GET',
      path: '/manage/events/{id}/virtual-rooms/user-participation'
    },
    getParticipation: {
      method: 'GET',
      path: '/manage/events/{id}/statistics/user-participation'
    },
    downloadCSV: {
      method: 'GET',
      path: '/manage/events/{id}/attendees.csv'
    },
    downloadCSVFlow: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/responses.xlsx'
    },
    downloadZIPFlow: {
      method: 'GET',
      path: '/manage/submission-flows/{id}/responses.zip'
    },
    downloadCSVAttendeesPerSession: {
      method: 'GET',
      path: '/manage/sessions/{id}/attendees.xlsx'
    },
    downloadCSVAttendeesPerSessionPerMinute: {
      method: 'GET',
      path: '/manage/sessions/{id}/user-participation.xlsx'
    },
    downloadCSVAttendeesPerRoom: {
      method: 'GET',
      path: '/manage/virtual-rooms/{code}/user-participation.xlsx'
    },
    downloadCSVAttendeesPerBooth: {
      method: 'GET',
      path: '/manage/sponsors/{id}/user-participation.xlsx'
    },
    downloadReviewers: {
      method: 'GET',
      path: '/manage/events/{id}/reviewers.xlsx'
    },
    getGeneral: {
      method: 'GET',
      path: '/manage/events/{id}/general',
      redirect: true
    },
    putGeneral: {
      method: 'PUT',
      path: '/manage/events/{id}/general'
    },
    getLayout: {
      method: 'GET',
      path: '/manage/events/{id}/page',
      redirect: true
    },
    putLayout: {
      method: 'PUT',
      path: '/manage/events/{id}/page'
    },
    getContents: {
      method: 'GET',
      path: '/manage/events/{eventId}/content'
    },
    addContent: {
      method: 'POST',
      path: '/manage/events/{eventId}/content'
    },
    getContent: {
      method: 'GET',
      path: '/manage/events/{eventId}/content/{id}'
    },
    updateContent: {
      method: 'PUT',
      path: '/manage/events/{eventId}/content/{id}'
    },    
    deleteContent: {
      method: 'DELETE',
      path: '/manage/events/{eventId}/content/{id}'
    },        
    reorderContents: {
      method: 'PUT',
      path: '/manage/events/{eventId}/content/reorder'
    },        
    getTickets: {
      method: 'GET',
      path: '/manage/events/{id}/access-tickets',
      errorHandling: false,
      redirect: true
    },
    putTickets: {
      method: 'PUT',
      path: '/manage/events/{id}/access-tickets'
    },
    getSessions: {
      method: 'GET',
      path: '/manage/events/{id}/sessions',
      redirect: true
    },
    getParticipants: {
      method: 'GET',
      path: '/manage/events/{id}/participants',
      redirect: true
    },
    getSponsors: {
      method: 'GET',
      path: '/manage/events/{id}/sponsors',
      redirect: true
    },
    getExtras: {
      method: 'GET',
      path: '/manage/events/{id}/extras',
      redirect: true
    },
    getHotels: {
      method: 'GET',
      path: '/manage/events/{id}/hotels',
      redirect: true
    },
    getRestaurants: {
      method: 'GET',
      path: '/manage/events/{id}/restaurants',
      redirect: true
    },
    getCollaborations: {
      method: 'GET',
      path: '/manage/events/{id}/collaborations',
      redirect: true
    },
    getUsers: {
      method: 'GET',
      path: '/manage/events/{id}/users',
      redirect: true      
    },
    deleteUser: {
      method: 'PUT',
      path: '/manage/event/{eventId}/user/{userId}', 
    },
    getAttendees: {
      method: 'GET',
      path: '/manage/events/{id}/attendees',
      redirect: true
    },
    getAllAttendees: {
      method: 'GET',
      path: '/manage/registration/event/{id}/attendees',
      redirect: true      
    },
    getAttended: {
      method: 'GET',
      path: '/manage/registration/event/{id}/attended',
      redirect: true      
    },    
    addUserAttendeed: {
      method: 'POST',
      path: '/manage/registration/event/{id}/attending/{userId}',
      redirect: true      
    },   
    removeUserAttendeed: {
      method: 'DELETE',
      path: '/manage/registration/event/{id}/not-attending/{userId}',
      redirect: true      
    },      
    getFeedback: {
      method: 'GET',
      path: '/manage/events/{id}/feedback',
      redirect: true
    },
    getOrganizers: {
      method: 'GET',
      path: '/manage/events/{id}/organizers',
      redirect: true
    },
    getEventFlow: {
      method: 'GET',
      path: '/manage/events/{id}/flow-state',      
    },
    updateEventFlow: {
      method: 'PUT',
      path: '/manage/events/{id}/flow-state/{states}',      
    },
  },
  user: {
    getInfo: {
      method: 'GET',
      path: '/user'
    },
    updateInfo: {
      method: 'PUT',
      path: '/user'
    },
    getByEmail: {
      method: 'GET',
      path: '/user', // ?email=,
      errorHandling:false
    },
    getByEmailMultiple: {
      method: 'GET',
      path: '/users'
    },
    getUserOccurences: {
      method: 'GET',
      path: '/profile/user-occurrences'
    }
  },
  events: {
    publish: {
      method: 'PUT',
      path: '/manage/events/{id}/publish'
    },
    unpublish: {
      method: 'DELETE',
      path: '/manage/events/{id}/publish'
    },
    clearcache: {
      method: 'DELETE',
      path: '/manage/events/{eventId}/cache'
    },
    join: {
      method: 'POST',
      path: '/events/{id}/join'
    },
    leave: {
      method: 'DELETE',
      path: '/events/{id}/join'
    },
    getHappeningNow: {
      method: 'GET',
      path: '/events/{id}/happening-now'
    },
    setBookmark: {
      method: 'POST',
      path: '/events/{id}/bookmark'
    },
    delBookmark: {
      method: 'DELETE',
      path: '/events/{id}/bookmark'
    },
    search: {
      method: 'GET',
      path: '/events/search'
    },
    getInfo: {
      method: 'GET',
      path: '/events/{id}/info',
      redirect: true
    },
    getContents: {
      method: 'GET',
      path: '/events/{eventId}/content'
    },  
    getContent: {
      method: 'GET',
      path: '/events/{eventId}/content/{id}'
    },    
    getPopular: {
      method: 'GET',
      path: '/events/popular'
    },
    getParticipants: {
      method: 'GET',
      path: '/events/{id}/participants'
    },
    getSponsors: {
      method: 'GET',
      path: '/events/{id}/sponsors'
    },
    getAgenda: {
      method: 'GET',
      path: '/events/{id}/agenda'
    },
    getUpcoming: {
      method: 'GET',
      path: '/events/upcoming'
    },
    manage: {
      method: 'GET',
      path: '/manage/events/'
    },
    addNew: {
      method: 'POST',
      path: '/manage/events'
    },
    getPast: {
      method: 'GET',
      path: '/events/past'
    },
    getFuture: {
      method: 'GET',
      path: '/events/future'
    },
    getPresent: {
      method: 'GET',
      path: '/events/present-and-future'
    },
    getAcronymAvailability: {
      method: 'GET',
      path: '/manage/events/acronym-availability'
    },
    getIdBySlug: {
      method: 'GET',
      path: '/events/slug/{eventSlug}'
    },
    getIdByHost: {
      method: 'GET',
      path: '/events/host/{eventHost}'
    },
    getBookmarked: {
      method: 'GET',
      path: '/events/bookmarked'
    },
    getProceedings: {
      method: 'GET',
      path: '/events/{id}/proceedings',
      redirect: true
    },
    getExtras: {
      method: 'GET',
      path: '/events/{id}/extras',
      redirect: true
    },
    getExtra: {
      method: 'GET',
      path: '/extras/{id}',
      redirect: true
    },
    getHotels: {
      method: 'GET',
      path: '/events/{id}/hotels',
      redirect: true
    },
    getRestaurants: {
      method: 'GET',
      path: '/events/{id}/restaurants',
      redirect: true
    },
    searchAttendees: {
      method: 'GET',
      path: '/events/{id}/attendees'
    },
    searchAttendeesInSession: {
      method: 'GET',
      path: '/online-rooms/{id}/attendees'
    },
    plans: {
      method: 'GET',
      path: '/manage/events/{id}/plans'
    },
    getSocialFeedHistory: {
      method: 'GET',
      path: '/event/{id}/chat?page={page}'
    }
  },
  credits: {
    getDefaultCredits: {
      method: 'GET',
      path: '/manage/events/default-credits'
    },     
    getEventCredits: {
      method: 'GET',
      path: '/manage/events/{id}/credits'
    },  
    updateBalance: {
      method: 'PUT',
      path: '/manage/events/{id}/credits/buy/{addBalance}'
    },
    updateCredits: {
      method: 'PUT',
      path: '/manage/events/{id}/credits'
    },
  },
  preview: {
    getAgenda: {
      method: 'GET',
      path: '/preview/{id}/agenda',
      redirect: true
    },
    getParticipants: {
      method: 'GET',
      path: '/preview/{id}/participants',
      redirect: true
    },
    getSponsors: {
      method: 'GET',
      path: '/preview/{id}/sponsors',
      redirect: true
    },
    getInfo: {
      method: 'GET',
      path: '/preview/{id}/info',
      redirect: true
    },
    getProceedings: {
      method: 'GET',
      path: '/preview/{id}/proceedings',
      redirect: true
    },
    getExtras: {
      method: 'GET',
      path: '/preview/{id}/extras',
      redirect: true
    },
    getExtra: {
      method: 'GET',
      path: '/preview/extras/{id}',
      redirect: true
    },
    getHotels: {
      method: 'GET',
      path: '/preview/{id}/hotels',
      redirect: true
    },
    getRestaurants: {
      method: 'GET',
      path: '/preview/{id}/restaurants',
      redirect: true
    }
  },
  sponsor: {
    getLead: {
      method: 'GET',
      path: '/sponsors/{id}/attendees.csv'
    }
  },
  virtualRooms: {
    sendFileMessage: {
      method: 'POST',
      path: '/online-rooms/{id}/chat'
    },
    getAllByEvent: {
      method: 'GET',
      path: '/events/{id}/virtual-rooms'
    },
    getAllBySession: {
      method: 'GET',
      path: '/sessions/{id}/virtual-rooms'
    },
    getAllForSponsor: {
      method: 'GET',
      path: '/sponsors/{id}/virtual-rooms'
    },
    addForSponsor: {
      method: 'POST',
      path: '/sponsors/{id}/virtual-rooms'
    },
    getOne: {
      method: 'GET',
      path: '/manage/virtual-rooms/{id}'
    },
    viewOne: {
      method: 'GET',
      path: '/virtual-rooms/{id}'
    },
    save: {
      method: 'PUT',
      path: '/manage/virtual-rooms/{id}'
    },
    add: {
      method: 'POST',
      path: '/manage/events/{id}/virtual-rooms'
    },
    addSession: {
      method: 'POST',
      path: '/manage/sessions/{id}/virtual-rooms'
    },
    getHost: {
      method: 'GET',
      path: '/virtual-rooms/{code}/host',
    },
    getAttendee: {
      method: 'GET',
      path: '/virtual-rooms/{code}/attendee',
    },
    admit: {
      method: 'PUT',
      path: '/online-rooms/{code}/attendee/{id}'
    },
    remove: {
      method: 'DELETE',
      path: '/online-rooms/{code}/attendee/{id}'
    },
    autoAdmit: {
      method: 'PUT',
      path: '/virtual-rooms/{code}/auto-admit'
    },
    removeAutoAdmit: {
      method: 'DELETE',
      path: '/virtual-rooms/{code}/auto-admit'
    },
    addToWaitingList: {
      method: 'POST',
      path: '/virtual-rooms/{code}/wait'
    }
  },
  contact: {
    sendMessage: {
      method: 'POST',
      path: '/contact'
    }
  },
  plans: {
    getPlans: {
      method: 'GET',
      path: '/plans'
    },
    getSubscriptions: {
      method: 'GET',
      path: '/subscriptions'
    },
    getAvailableSubscriptions: {
      method: 'GET',
      path: '/subscriptions/available'
    },
    buyHours: {
      method: 'POST',
      path: '/manage/events/{id}/subscription-hours'
    },
    getHourPlans: {
      method: 'GET',
      path: '/subscriptions-hours'
    }
  },
  admin: {
    getUsers: {
      method: 'GET',
      path: '/admin/users/'
    },
    getSponsors: {
      method: 'GET',
      path: '/admin/sponsors/'
    },
    updateUser: {
      method: 'PUT',
      path: '/admin/users/{id}'
    },
    activateUser: {
      method: 'PUT',
      path: '/admin/users/{id}/activate'
    },
    downloadSponsorData: {
      method: 'GET',
      path: '/admin/sponsors/{id}/clicks.xlsx'
    },
    getEvents: {
      method: 'GET',
      path: '/admin/events'
    },
    deleteEvents: {
      method: 'DELETE',
      path: '/admin/events/{id}/delete'
    },
    getEmails: {
      method: 'GET',
      path: '/admin/emails'
    },
    getJoindEvents: {
      method: 'GET',
      path: '/admin/users/{id}/joined-events'
    },
  },
  virtualRoom: {
    // Common with "Virtual Room"
    get: {
      method: 'GET',
      path: '/virtual-rooms/{id}/live',
      errorHandling: true
    },
    heartbeat: {
      method: 'GET',
      path: '/virtual-rooms/{id}/status',
      errorHandling: false
    },
    raiseHand: {
      method: 'PUT',
      path: '/online-rooms/{id}/raise-hand'
    },
    dropHand: {
      method: 'DELETE',
      path: '/online-rooms/{id}/raise-hand'
    },
    setLive: {
      method: 'POST',
      path: '/online-rooms/{id}/live'
    },
    delLive: {
      method: 'DELETE',
      path: '/online-rooms/{id}/live'
    },
    setStarted: {
      method: 'POST',
      path: '/online-rooms/{id}/started'
    },
    delStarted: {
      method: 'DELETE',
      path: '/online-rooms/{id}/started'
    },
    setStream: {
      method: 'POST',
      path: '/online-rooms/{id}/stream/{streamId}',
      errorHandling: false
    },
    clearStream: {
      method: 'DELETE',
      path: '/online-rooms/{id}/stream/{streamId}',
      errorHandling: false
    },
    setLayout: {
      method: 'PUT',
      path: '/online-rooms/{id}/layout'
    },
    setAuto: {
      method: 'POST',
      path: '/online-rooms/{id}/auto'
    },
    delAuto: {
      method: 'DELETE',
      path: '/online-rooms/{id}/auto'
    },
    setSpeaker: {
      method: 'PUT',
      path: '/online-rooms/{id}/can-broadcast/{participantId}'
    },
    delSpeaker: {
      method: 'DELETE',
      path: '/online-rooms/{id}/can-broadcast/{participantId}'
    },
    setVisible: {
      method: 'POST',
      path: '/online-rooms/{id}/visible'
    },
    delVisible:{
      method: 'DELETE',
      path: '/online-rooms/{id}/visible'
    },
    like: {
      method: 'POST',
      path: '/online-rooms/{id}/like'
    },
    dislike: {
      method: 'POST',
      path: '/online-rooms/{id}/dislike'
    },
    getPDFPagesFromFile: {
      method: 'POST',
      path: '/online-rooms/{id}/pages'
    },
    getSocialFeedHistory: {
      method: 'GET',
      path: '/online-rooms/{id}/chat?page={page}&items=50'
    },
    getSocialFeedHistorySpeakers: {
      method: 'GET',
      path: '/online-rooms/{id}/chat/speakers?page={page}&items=50'
    },
    present: {
      method: 'POST',
      path: '/online-rooms/{id}/present'
    },
    stopPresent: {
      method: 'DELETE',
      path: '/online-rooms/{id}/present'
    },
    setParticipants: {
      method: 'PUT',
      path: '/online-rooms/{id}/speakers-order'
    },
    getQnaQuestions: {
      method: 'GET',
      path: '/online-rooms/{id}/questions'
    },
    getQnaAnswers: {
      method: 'GET',
      path: '/questions/{id}/answers'
    },
    setQnaQuestion: {
      method: 'POST',
      path: '/questions'
    },
    setQnaAnswer: {
      method: 'POST',
      path: '/answers'
    },
    setQnaUpVote: {
      method: 'POST',
      path: '/questions/{id}/vote'
    },
    undoQnaUpVote: {
      method: 'DELETE',
      path: '/questions/{id}/vote'
    },
    deleteQuestion: {
      method: 'DELETE',
      path: '/question/{id}'
    },
    deleteAnswer: {
      method: 'DELETE',
      path: '/answers/{id}'
    },
    setPublisher: {
      method: 'POST',
      path: '/online-rooms/{id}/user/{userId}/publisher'
    },
    deletePublisher: {
      method: 'DELETE',
      path: '/online-rooms/{id}/user/{userId}/publisher'
    },
  },

  sessions: {
    downloadLogs: {
      method: 'GET',
      path: '/manage/events/{id}/logs.zip'
    },
    clearChat: {
      method: 'DELETE',
      path: '/manage/sessions/{id}/chat'
    },
    clearEmotion: {
      method: 'DELETE',
      path: '/manage/sessions/{id}/emotion'
    },
    sendFileMessageChat: {
      method: 'POST',
      path: '/online-rooms/{id}/chat',
    },
    sendFileMessageSpeaker: {
      method: 'POST',
      path: '/online-rooms/{id}/chat/speakers',
    },
    // Common with "Virtual Room"
    get: {
      method: 'GET',
      path: '/sessions/{id}',
      errorHandling: true
    },
    heartbeat: {
      method: 'GET',
      path: '/sessions/{id}/status',
      errorHandling: false
    },

    setStream: {
      method: 'POST',
      path: '/sessions/{id}/stream/{streamId}',
      errorHandling: false
    },
    clearStream: {
      method: 'DELETE',
      path: '/sessions/{id}/stream/{streamId}',
      errorHandling: false
    },
    setLayout: {
      method: 'PUT',
      path: '/sessions/{id}/layout'
    },
    setAuto: {
      method: 'POST',
      path: '/sessions/{id}/auto'
    },
    delAuto: {
      method: 'DELETE',
      path: '/sessions/{id}/auto'
    },
    setSpeaker: {
      method: 'PUT',
      path: '/sessions/{id}/can-broadcast/{participantId}'
    },
    delSpeaker: {
      method: 'DELETE',
      path: '/sessions/{id}/can-broadcast/{participantId}'
    },
    setVisible: {
      method: 'POST',
      path: '/online-rooms/{id}/visible'
    },
    delVisible:{
      method: 'DELETE',
      path: '/online-rooms/{id}/visible'
    },
    like: {
      method: 'POST',
      path: '/sessions/{id}/like'
    },
    dislike: {
      method: 'POST',
      path: '/sessions/{id}/dislike'
    },
    getPDFPagesFromFile: {
      method: 'POST',
      path: '/sessions/{id}/pages'
    },
    getSocialFeedHistory: {
      method: 'GET',
      path: '/online-rooms/{id}/chat?page={page}&items=50'
    },
    getSocialFeedHistorySpeakers: {
      method: 'GET',
      path: '/online-rooms/{id}/chat/speakers?page={page}&items=50'
    },
    present: {
      method: 'POST',
      path: '/sessions/{id}/present'
    },
    stopPresent: {
      method: 'DELETE',
      path: '/sessions/{id}/present'
    },
    setParticipants: {
      method: 'PUT',
      path: '/sessions/{id}/speakers-order'
    },
    getQnaQuestions: {
      method: 'GET',
      path: '/sessions/{id}/questions'
    },
    getQnaAnswers: {
      method: 'GET',
      path: '/questions/{id}/answers'
    },
    setQnaQuestion: {
      method: 'POST',
      path: '/questions'
    },
    setQnaAnswer: {
      method: 'POST',
      path: '/answers'
    },
    setQnaUpVote: {
      method: 'POST',
      path: '/questions/{id}/vote'
    },
    undoQnaUpVote: {
      method: 'DELETE',
      path: '/questions/{id}/vote'
    },
    deleteQuestion: {
      method: 'DELETE',
      path: '/question/{id}'
    },
    deleteAnswer: {
      method: 'DELETE',
      path: '/answers/{id}'
    },
    // Other
    update: {
      method: 'PUT',
      path: '/manage/sessions/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/sessions'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/sessions/{id}'
    },
    reorder: {
      method: 'PUT',
      path: '/manage/events/{id}/sessions/reorder'
    },
    setAttending: {
      method: 'POST',
      path: '/sessions/{id}/attending'
    },
    delAttending: {
      method: 'DELETE',
      path: '/sessions/{id}/attending'
    },

    getRoom: {
      method: 'GET',
      path:'/posters/{presentationId}',
    },
    getWorkshop: {
      method: 'GET',
      path: '/sessions/{sessionId}/workshop'
    },



    feedback: {
      method: 'GET',
      path: '/sessions/{id}/feedback'
    },

    setSpeakers: {
      method: 'PUT',
      path: '/sessions/{id}/speakers'
    },
    setLive: {
      method: 'POST',
      path: '/sessions/{id}/live'
    },
    delLive: {
      method: 'DELETE',
      path: '/sessions/{id}/live'
    },
    setStarted: {
      method: 'POST',
      path: '/sessions/{id}/started'
    },
    delStarted: {
      method: 'DELETE',
      path: '/sessions/{id}/started'
    },
    getPDFPages: {
      method: 'GET',
      path: '/sessions/{id}/pages/{fileId}'
    },

  },
  feedback: {
    getFeedback: {
      method: 'GET',
      path: '/manage/feedback/{id}'
    },
    delete: {
      method: 'DELETE',
      path: '/manage/feedback/{id}'
    },
    update: {
      method: 'PUT',
      path: '/manage/feedback/{id}'
    },
    addNew: {
      method: 'POST',
      path: '/manage/feedback'
    },
    enable: {
      method: 'POST',
      path: '/manage/feedback/{id}/enable'
    },
    disable: {
      method: 'DELETE',
      path: '/manage/feedback/{id}/enable'
    },
    answer: {
      method: 'PUT',
      path: '/feedback/{code}'
    },
    getQuestions: {
      method: 'GET',
      path: '/feedback/{code}'
    },
    getAllFeedback: {
      method: 'GET',
      path:'/events/{id}/feedback'
    },
    getAnswers: {
      method: 'GET',
      path: '/manage/feedback/{id}/live'
    },
    setEnable: {
      method: 'POST',
      path: '/feedback/{code}/enabled'
    },
    delEnable: {
      method: 'DELETE',
      path: '/feedback/{code}/enabled'
    },
    setVisible: {
      method: 'POST',
      path: '/feedback/{code}/public'
    },
    delVisible: {
      method: 'DELETE',
      path: '/feedback/{code}/public'
    },
    setViewable: {
      method: 'POST',
      path: '/feedback/{code}/viewable'
    },
    delViewable: {
      method: 'DELETE',
      path: '/feedback/{code}/viewable'
    },
    setActive: {
      method: 'PUT',
      path: '/feedback/{code}/active'
    },
    delActive: {
      method: 'DELETE',
      path: '/sessions/{id}/active-feedback'
    },
    getAllAnswers: {
      method: 'GET',
      path: '/feedback/{code}/answers'
    },
    setPublicResults: {
      method: 'POST',
      path: '/feedback/{code}/public-results'
    },
    delPublicResults: {
      method: 'DELETE',
      path: '/feedback/{code}/public-results'
    }
  },
  timezones: {
      getTimeZones: {
        method: 'GET',
        path: '/timezones'
      }
	},
  grades: {
  	getPaperGrades: {
		method: "GET",
		path: "/manage/events/{eventId}/grades",
	  },
  },  
}

// Create all API endpoints
Object.keys(endpoints).forEach((key)=>api.createEntity(key,endpoints[key]))

export default api