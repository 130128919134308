import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Chip as MuiChip} from '@material-ui/core'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    backgroundColor: palette['very-light-pink'],
    height: '22px',
    lineHeight: '22px',
  },
    label: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
})

const Chip = withStyles(styles)(({classes, children, ...restProps}) => {
  return <><ThemeProvider theme={theme}>
    <MuiChip {...restProps} classes={{label: classes.label }} className={classes.root} />
  </ThemeProvider>
  </>
})

export default Chip