import {Link, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// import {IconSearchBorderDark} from 'Components/core/icons'
import IconButton from '@material-ui/core/IconButton'
import {FILES_URL} from 'config'
import MenuIcon from '@material-ui/icons/Menu'
import {palette} from 'theme'
import Divider from '@material-ui/core/Divider'
// import {useHttp} from 'api/core'
// import api from 'api'
import RoomsButton from 'components/core/Header/Rooms/RoomsButton'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Grid from '@material-ui/core/Grid'
import {NotificationSender} from 'components/core/Notifications'

const styles = theme => ({
  navLinks: {
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  userBlock: {
    backgroundColor: palette.white,
    height: '64px',
    maxWidth: '94px',
    width: '94px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    '& a': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:hover': {
      backgroundColor: palette['pale-grey'],
      transition: 'background-color 300ms linear'
    }
  },
  profileButton: {
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  avatar: {
    width: '36px',
    height: '36px',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: palette['pale-sky-blue'],
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  placeholder: {
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundColor: palette['pale-grey'],
    border: '1px solid',
    borderColor: palette['pale-sky-blue'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  menuNav: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  menuButton: {
    color: palette.aquamarine,
    [theme.breakpoints.up(1150)]: {
      display: 'none'
    }
  },
  hideMobile: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
})


const SessionMenu = withRouter(withStyles(styles)(({classes, userInfo, toggleDrawer, history, session, isPosterType, openPosterView, setOpenPosterView, setProfileDialogOpen}) => {
  const userName = userInfo && userInfo.info ? userInfo.info.firstName : ''
  const shortName = userInfo && userInfo.info && userInfo.info.firstName ? userInfo.info.firstName[0] : ''
  const shortLastName = userInfo && userInfo.info && userInfo.info.lastName ? userInfo.info.lastName[0] : ''
  const [anchorEl, setAnchorEl] = useState(null)
  // const [sessionStarted, setSessionStarted] = useState(session.status.started)

  // Route parameters
  // const {sessionId} = useParams()
  //
  // const [setStarted, , isLoadingSet, hasErrorSet] = useHttp()
  // const [delStarted, , isLoadingDel, hasErrorDel] = useHttp()

  const [sendAnnouncement, setSendAnnouncement] = useState(false)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const toggleSession = () => {
  //   if (!sessionStarted) {
  //     setSessionStarted(true)
  //     setStarted(api.entities.sessions.setStarted(null,null,{id:sessionId}))
  //   } else {
  //     setSessionStarted(false)
  //     delStarted(api.entities.sessions.delStarted(null,null,{id:sessionId}))
  //   }
  // }

  // useEffect(()=>{
  //   if (hasErrorSet) {
  //     setSessionStarted(false)
  //   }
  // },[hasErrorSet])
  //
  // useEffect(()=>{
  //   if (hasErrorDel) {
  //     setSessionStarted(true)
  //   }
  // },[hasErrorDel])

  useEffect(() => {
    handleClose()
  }, [history.location])

  return <Box display='flex' alignItems='center'>
    <Box className={classes.navLinks}>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menuNav}
      >
        {/* <Link to='/create-event'><MenuItem>Create Event</MenuItem></Link> */}
        {/* <Link to='/select-package'><MenuItem>Create Event</MenuItem></Link> */}
        <Link to='/joined-events'><MenuItem>Joined Events</MenuItem></Link>

        {/* <Link to='/search'><MenuItem>Search Events</MenuItem></Link> */}
        {/* <Link to='/my-tickets'><MenuItem>My tickets</MenuItem></Link> */}
        {/* <Link to='/favorites'><MenuItem>Favorites</MenuItem></Link> */}

        <Divider />
        <Link to='/manage-events'><MenuItem>Manage Events </MenuItem></Link>
        <Divider />
        {/* <Link to='/profile/settings'><MenuItem>Profile</MenuItem></Link> */}
        {/* <a href='https://docs.anymeets.com/' target={'blank'}><MenuItem>Help</MenuItem></a> */}
        <Link to='/sign-out'><MenuItem>Sign Out</MenuItem></Link>
      </Menu>

    </Box>

    {/*<Box mr={2}><Link to='/search'><IconSearchBorderDark/></Link></Box>*/}
    {/*<Box mr={2} className={classes.hideMobile}><Link to='/notifications'><IconBellBorderDark/></Link></Box>*/}

    <Box className={classes.hideMobile}>

      <Box className={classes.hideMobile}>
        <Grid item container alignItems='center' alignContent='center' spacing={2} style={{marginRight:16}}>
          {!isPosterType &&<Grid item>
            <RoomsButton session={session} />
          </Grid>}
          {isPosterType&&openPosterView&& <Grid item>
            <Button size='small' variant='contained' color='secondary' onClick={()=>setOpenPosterView(false)}>Back to Posters</Button>
          </Grid>}
          {session&&<Grid item>
            {session&&<Link to={`/event/${session.eventId}/agenda`} title={'Go back to event\'s Agenda'}><Button size='small' variant='outlined' startIcon={<ExitToAppIcon/>}> Leave Session </Button></Link>}
          </Grid>}

          {session&&(session.status.role===3||session.status.coach)&&<>
            <Grid item>
              {session&&<IconButton onClick={()=>setSendAnnouncement(true)}> <AnnouncementIcon/> </IconButton>}
            </Grid>
            <NotificationSender sessionId={session.id} isSession={true} open={sendAnnouncement} setOpen={setSendAnnouncement} />
          </>}

        </Grid>

        {/*{!isPosterType &&*/}
        {/*<Box mr={1} ml={3}>*/}
        {/*  <RoomsButton session={session} />*/}
        {/*</Box>}*/}
        {/*{isPosterType&&openPosterView&&*/}
        {/*<Box mr={1} ml={3}>*/}
        {/*  <Button size='small' variant='contained' color='secondary' onClick={()=>setOpenPosterView(false)}>Back to Posters</Button>*/}
        {/*</Box>}*/}
        {/*}*/}
      </Box>

      {/*{!isPosterType&&session&&(session.status.role===3||session.status.coach)&&<>*/}
      {/*<Box mr={2}>*/}
      {/*  <Button disabled={isLoadingSet||isLoadingDel} size='small' variant={sessionStarted?'outlined':'contained'} color='secondary' onClick={()=>toggleSession()}> {sessionStarted?'End Session':'Start Session'} </Button>*/}
      {/*</Box>*/}
      {/*  /!*<Box mr={2}>*!/*/}
      {/*  /!*  <Button disabled={isLoadingSetLive||isLoadingDelLive} size='small' variant={sessionLive?'outlined':'contained'} color='secondary' onClick={()=>toggleLive()}> {sessionLive?'Go backstage':'Go live'} </Button>*!/*/}
      {/*  /!*</Box>*!/*/}
      {/*</>}*/}

      {/*<Box mr={2}>*/}
      {/*  {session&&<Link to={`/event/${session.eventId}/agenda`} title={'Go back to event\'s Agenda'}><Button size='small' variant='outlined' startIcon={<ExitToAppIcon/>}> Leave Session </Button></Link>}*/}
      {/*</Box>*/}
      {/*  {session&&<IconButton> <FeedbackIcon/> </IconButton>}*/}
    </Box>
    <Box className={classes.userBlock}>
      <Box>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.profileButton}
        >
          {userInfo && userInfo.info && userInfo.info.pictureId ?
            <img className={classes.avatar} title={userName} alt={userName}
                 src={`${FILES_URL}/${userInfo.info.pictureId}`}/> :
            <Box width={36} height={36} className={classes.placeholder}> {shortName} {shortLastName} </Box>}
        </IconButton>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={toggleDrawer('right', true)}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
      </Box>
    </Box>
  </Box>
}))

export default SessionMenu
