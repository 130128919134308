import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { FormHelperText, Typography, Card, CardContent, Button, TextField } from '@material-ui/core'
import { palette, theme } from 'theme'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';
import { withRouter } from 'react-router-dom'
import { TERMS_PUBLISH, TIME_FADE_IN, DATE_DEFAULT_ENDDATE, DATE_DEFAULT_STARTDATE, LOCATION_DEFAULT_MAP_LOCATION } from 'config'
import Fade from '@material-ui/core/Fade'
import api from 'api'
import { useHttp } from 'api/core'
import Dialog from '@material-ui/core/Dialog'
import { ThemeProvider } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import { styled } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'



const styles = theme => ({
    page: {
        backgroundColor: palette['cerulean-blue'],
        minHeight: 'calc(100vh - 128px)'
    },
    title: {
        color: 'white',
        textAlign: 'center',
        fontWeight: "bold"
    },
    subtitle: {
        color: 'white',
        textAlign: 'center',
        fontSize: 18
    },
    packageCard: {
        height: "100%",
        textAlign: "center",
        padding: "5px",
        position: "relative",
        border: "2px solid #ffffff",
        borderRadius: "6px",
        opacity: 1,
        boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
        "&:hover": {
            boxShadow: "0 8px 12px 0px rgba(0, 0, 0, 0.4)",
            transform: "scale(1.05)",
            // backgroundColor: "#f0f0f0",
            // backgroundColor: palette['cerulean-blue'],
            // color: "#fff"
        },
    },
    button: {
        position: "absolute",
        bottom: "40px",
        left: "50%",
        padding: "10px",
        transform: "translateX(-50%)",
        width: "150px",
        fontSize: "14px",
        fontWeight: 600,
        // border: `3px solid ${ palette['cerulean-blue'] } !important`,
        // "&:hover": {
        //     color: "#fff",
        //     border: `3px solid ${ palette['cerulean-blue'] } !important`,
        //     background: `${ palette['cerulean-blue'] } !important`,
        // },
    },
    firstPackageButton: {
        color: "#ffffff",
        border: "3px solid #04a496 !important",
        backgroundColor: "#04a496",
        "&:hover": {
            color: "#04a496",
            border: `3px solid #04a496 !important`,
        },

    },
    secondPackageButton: {
        color: "#ffffff",
        border: "3px solid #ff6b77 !important",
        backgroundColor: "#ff6b77",
        "&:hover": {
            color: "#ff6b77",
            border: `3px solid #ff6b77 !important`,
        },

    },
    thirdPackageButton: {
        color: "#fff",
        border: `3px solid ${ palette['cerulean-blue'] } !important`,
        background: palette['cerulean-blue'],
        "&:hover": {
            color: palette['cerulean-blue'],
            border: `3px solid ${ palette['cerulean-blue'] } !important`,
            background: `#fff`,
        },

    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '40px 50px 50px 50px',
        backgroundColor: palette['cerulean-blue'],
        color: 'white',
        borderRadius: '6px'
    },
    termsList: {
        maxWidth: '770px'
    },
    row: {
        marginBottom: theme.spacing(3)
    },
    agreeButton: {
        backgroundColor: palette['aquamarine'],
        border: "2px solid #0dc7e5",
        color: "white",
        width: 190,
        "&:hover": {
            border: "2px solid #fff",
        }
    },
    cancelButton: {
        backgroundColor: palette['white'],
        border: "2px solid #fff",
        width: 150,
        "&:hover": {
            color: "#fff"
        }
    },
    eventMarker: {
        fontSize: 50
    },
    hovered: {
        opacity: 1,
        transition: "0.2s"
    },
    notHovered: {
        opacity: 0.7,
        transition: "0.2s",
        transform: "scale(.95)",
    },
})

const PackageSelection = withStyles(styles)(({ classes, history }) =>
{
    let latitude = "";
    let longitude = "";
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            latitude = position.coords.latitude;
                longitude = position.coords.longitude;
                console.log(latitude,longitude)
          },
          function (error) {
            console.error("Error getting location:", error);
          }
        );
    }
    
    const currentDate = new Date();
    const AvailabilityTimeout = useRef(null);
    const DateTime = currentDate.getTime();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fetchData, data] = useHttp()
    const [putRequest, putData] = useHttp()
    const [openTerms, setOpenTerms] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [packageType, setPackageType] = useState(null)
    const [acronymAvailability, setAcronymAvailability] = useState(null)
    const [getAvailability, availabilityData] = useHttp()
    const [event, setEvent] = useState({
        "general": {
            "name": '',
            "acronym": '',
            "description": '',
            "category": '2',
            "startDate": DATE_DEFAULT_STARTDATE,
            "endDate": DATE_DEFAULT_ENDDATE,
            "online": true,
            "slug": null,
            "venueLat": latitude !=="" ? latitude : LOCATION_DEFAULT_MAP_LOCATION.lat,
            "venueLon": longitude !=="" ? longitude : LOCATION_DEFAULT_MAP_LOCATION.lng,
            "venueName": "",
            "venueDetails": "",
            "address": "",
            "zipCode": "",
            "city": "",
            "country": "",
            "onlineStream": 1,
            "eventLanguage": 1,
            "timezoneId": 590,
            "activateSubmissions": false,
            "activateReviews": false,
            "papersToShow": 40,
            "postersToShow": 40,
            "emailsToSend": 500,

        },
        "layout": 1,
        "proceedingsAccess": 1,
        "sessionViewerAccess": 3,
        "access": 1,
        "registration": 1,
        "maxRegistrations": null,
        "subscriptionId": null, // null = free / no plan
        "externalRegistration": null,
        "externalRegistrationUrl": null,
        "externalRegistrationButtonLabel": null,
        "registrationInfoDesc": null,
        "registrationInfoCost": null,
        "registrationInstructions": null,
        "generateTitlePage": null,
        theme: 1,
        mailList: {
            "name": '',
            "emails": []
        }
    })
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) =>
    {
        setHoveredCard(index);
    };

    const handleMouseLeave = () =>
    {
        setHoveredCard(null);
    };

    const handleChange = (event) =>
    {
        setAccepted(event.target.checked)
    }

    const createEvent = () =>
    {
        let formDataObj = new FormData();

        const blob = new Blob([JSON.stringify(event)], { type: 'application/json' });
        formDataObj.append('event', blob)

        putRequest(api.entities.events.addNew(formDataObj, null, null, true))

    }
    useEffect(() =>
    {
        if (putData?.id)
        {
            fetchData(api.entities.events.publish(null, null, { id: putData.id }))
        }
    }, [putData])

    useEffect(() =>
    {
        if (data?.status === 201 && putData?.id)
        {
            history.push(`/manage-event/${ putData.id }/general`)
        }
    }, [data])

    useEffect(() =>
    {
        if (packageType === 1)
        {
            setEvent({
                ...event,
                general: {
                    ...event.general,
                    "activateSubmissions": false,
                    "activateReviews": false,
                }

            })
        }
        else if (packageType === 2)
        {
            setEvent({
                ...event,
                general: {
                    ...event.general,
                    "activateSubmissions": true,
                    "activateReviews": true,
                }

            })
        }


    }, [packageType])

    function handleCloseTerms()
    {
        setOpenTerms(false)
        setAccepted(false)
    }

    function handleEventName(e)
    {
        const newEvent = {
            ...event,
            general: {
                ...event.general,
                name: e.target.value
            }
        }
        setEvent(newEvent)
    }
    function handleEventAcronym(e)
    {
        const newValue = e.target.value.replaceAll(" ", "-")
        const acronymType = /^[A-Za-z0-9-]+$/;
        if (acronymType.test(e.target.value))
        {
            getAvailability(api.entities.events.getAcronymAvailability(null, { v: newValue }))
        } else 
        {
            setAcronymAvailability({ ...acronymAvailability, available: false, message: "Acronym must only include letters,numbers or hyphen" })
        }

        const newEvent = {
            ...event,
            general: {
                ...event.general,
                acronym: newValue
            }
        }
        setEvent(newEvent)
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    useEffect(() =>
    {
        if (availabilityData)
        {
            setAcronymAvailability({ ...acronymAvailability, available: availabilityData.available, message: availabilityData.available === false ? "Acronym is already in use" : null })
        }
        //eslint-disable-next-line
    }, [availabilityData])

    return (
        <>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.page}>
                <Fade in={true} timeout={TIME_FADE_IN}>

                    <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" pt={4} minHeight={window.innerHeight - 280}>
                        <Box display="flex" width="750px" flexDirection="column" pb={8}>
                            <Typography component="h2" variant="h5" className={classes.title}>
                                CREATE AN EVENT
                            </Typography>
                            <Typography component="h4" variant="h6" className={classes.subtitle}>
                                Start by selecting a package
                            </Typography>
                            {/* <Grid item xs={12} md={10}> */}
                            <Box display="flex" flexDirection="row" mt={6}>
                                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                                    <Box mr={2} style={{ width: "33%", height: 350 }}>
                                        <Card variant="outlined" className={`${ classes.packageCard } ${ hoveredCard === 1 ? classes.hovered : (hoveredCard === null ? classes.hovered : classes.notHovered) }`}
                                            onMouseEnter={() => handleMouseEnter(1)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <CardContent>
                                                <Typography style={{ fontSize: 20, fontWeight: 700, color: "#04a496", marginBottom: 35 }} color="primary" gutterBottom>
                                                    Basic Package
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                </Typography>
                                                <Typography variant="body2">
                                                    Set up and publish your event, whether you are organizing a meeting, workshop, seminar or conference, within minutes with our auto content and editable landing page.
                                                </Typography>
                                                <Button color="primary" variant='outlined' size="small" className={`${ classes.firstPackageButton } ${ classes.button }`} onClick={() => { setOpenTerms(true); setPackageType(1); }}>Instant  Access</Button>
                                            </CardContent>
                                            <div style={{ position: "absolute", bottom: -13, right: -9, opacity: 0.3 }}>
                                                <HtmlTooltip placement="top" arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Typography variant='p' color="inherit">This package does not contain <br />Papers Submissions & Papers Reviews</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <InfoIcon style={{ color: "#04a496", fontSize: 50 }} color="primary" variant="outlined" fontSize="small" />
                                                </HtmlTooltip>
                                            </div>

                                        </Card>
                                    </Box>
                                    <Box mr={2} style={{ width: "33%", height: 350 }}>
                                        <Card variant="outlined" className={`${ classes.packageCard } ${ hoveredCard === 2 ? classes.hovered : (hoveredCard === null ? classes.hovered : classes.notHovered) }`}
                                            onMouseEnter={() => handleMouseEnter(2)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <CardContent>
                                                <Typography style={{ fontSize: 20, fontWeight: 700, color: "#ff6b77", marginBottom: 35 }} color="primary" gutterBottom>
                                                    Full Package
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                </Typography>
                                                <Typography variant="body2">
                                                    On top of the basic features,<br />
                                                    Simplify & streamline your paper submission process with our own easy-to-use manage & review software, from submission to presentation.
                                                </Typography>
                                                <Button color="primary" variant='outlined' size="small" className={`${ classes.secondPackageButton } ${ classes.button }`} onClick={() => { setOpenTerms(true); setPackageType(2); }}>Full Access</Button>
                                                <div style={{ position: "absolute", bottom: -13, right: -9, opacity: 0.3 }}>
                                                    <HtmlTooltip placement="top" arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Typography variant='p' color="inherit">This package contains all the basic features and <br />Papers Submissions & Papers Reviews</Typography>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <InfoIcon style={{ color: "#ff6b77", fontSize: 50 }} color="primary" variant="outlined" fontSize="small" />
                                                    </HtmlTooltip>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                    <Box mr={2} style={{ width: "33%", height: 350, marginRight: "0px" }}>
                                        <Card variant="outlined" className={`${ classes.packageCard } ${ hoveredCard === 3 ? classes.hovered : (hoveredCard === null ? classes.hovered : classes.notHovered) }`}
                                            onMouseEnter={() => handleMouseEnter(3)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <CardContent>
                                                <Typography style={{ fontSize: 20, fontWeight: 700, marginBottom: 35 }} color="primary" gutterBottom>
                                                    Customized
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                </Typography>
                                                <Typography variant="body2">
                                                    <p>
                                                        Build from scratch & finetune all aspects of your event.
                                                    </p>
                                                    <p>
                                                        Full access to Anymeets toolbox, right from the beginning!
                                                    </p>
                                                </Typography>
                                                <Button color="primary" variant='outlined' size="small" className={`${ classes.thirdPackageButton } ${ classes.button }`} onClick={() => { history.push('/create-event') }}>Customize</Button>
                                                <div style={{ position: "absolute", bottom: -13, right: -9, opacity: 0.3 }}>
                                                    <HtmlTooltip placement="top" arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Typography variant='p' color="inherit">This package offers step-by-step event configuration of all features.</Typography>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <InfoIcon style={{ fontSize: 50 }} color="primary" variant="outlined" fontSize="small" />
                                                    </HtmlTooltip>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={5} style={{ textAlign: "center", color: "#fff" }} >
                                If you find difficult to select the right package, feel free to contact our support team at <a href="mailto:info@anymeets.com" style={{ color: "#fff" }} target="_blank">info@anymeets.com</a>, or send us a <a href="/contact" style={{ color: "#fff" }} target="_blank">message</a>
                            </Box>
                        </Box>
                    </Box>

                </Fade>
            </Grid>

            <Dialog onClose={handleCloseTerms} open={openTerms}>
                <Box className={classes.termsList}>
                    <ThemeProvider theme={theme}>
                        <Box display='flex' flexDirection='row'>

                            <Box className={classes.container}>
                                <Formik>
                                    <Form>
                                        <Grid container spacing={2} className={classes.row}>
                                            <Grid item xs={12} spacing={2}>
                                                <Typography variant='subtitle1' component='h3'> Enter event name *</Typography>
                                                <Field type='text' name='name' component={TextField} label='Event name' variant='filled' fullWidth onChange={handleEventName} />
                                            </Grid>

                                            <Grid item xs={12} spacing={2}>
                                                <Typography variant='subtitle1' component='h3'> Enter event acronym
                                                    <HtmlTooltip placement="top" arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Typography variant='p' color="inherit">A unique short text to distinguish from other public events.</Typography>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <InfoIcon style={{ fontSize: 16 }} color="secondary" variant="outlined" fontSize="large" />
                                                    </HtmlTooltip>
                                                    *
                                                </Typography>
                                                <Field type='text' name='acronym' component={TextField} label='Event acronym' variant='filled' fullWidth onChange={handleEventAcronym} />
                                                {acronymAvailability && acronymAvailability.available === false && (
                                                    <FormHelperText error variant="filled">
                                                        {" "}
                                                        <span style={{ fontWeight: 600, fontSize: "14px" }}>{acronymAvailability.message}</span>
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} className={classes.row}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1' component='h2'> Terms and Conditions</Typography>
                                                <textarea rows={10} name={"terms"} value={TERMS_PUBLISH} style={{ width: "100%", borderRadius: "5px" }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item container justify='center' alignItems='center' spacing={2}>
                                                    <Grid item >
                                                        <FormControlLabel
                                                            control={<Checkbox checked={accepted} onChange={(e) => handleChange(e)} name='accepted' />}
                                                            label={'I have read and agree to the above Terms and Conditions'}
                                                            disabled={isSubmitting}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Box display='flex' justifyContent='center' alignItems='center'>
                                            <Box p={1}>
                                                <Button variant='outlined' size='large' className={classes.cancelButton} onClick={handleCloseTerms}
                                                > Cancel </Button>
                                            </Box>
                                            <Box p={1}>
                                                <Button variant="outlined" size='large' className={classes.agreeButton} disabled={!accepted || !acronymAvailability?.available || event.general.name === ""} onClick={() => createEvent()}> Agree & Create Event </Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                </Formik>
                            </Box>
                        </Box>

                    </ThemeProvider>
                </Box>
            </Dialog>
        </>

    );
})

export default withRouter(PackageSelection)
