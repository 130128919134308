import React, {useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {palette} from "theme";
import {withRouter} from "react-router-dom";
import {useMetaData} from "components/core/MetaProvider";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import EventsIcon from "assets/Events_icon.png";
import FlexibleIcon from "assets/Flexible_icon.png";
import MetricsIcon from "assets/Metrics_icon.png";
import SupportIcon from "assets/Support_icon.png";
import BottomHero from "components/core/BottomHero";

const styles = (theme) => ({
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		color: "#000",
		fontSize: "45px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "-2px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#3d4d59",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		letterSpacing: "0.38px",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
	},
	pageContent: {
		marginTop: "100px",
	},
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "0px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		textAlign: "center",
		minHeight: "200px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "0px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
		color: "#000",
	},





});

const About = withStyles(styles)(({classes}) => {
  
  	const [, setMetaData] = useMetaData();

		useEffect(() => {
			setMetaData({title: "About"});
			// eslint-disable-next-line
		}, []);

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>About</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{
						textAlign: "center",
						color: "#4a4a4a",
						maxWidth: "614px",
						display: "inline-block",
					}}
				>
					{" "}
					<p>
						We created Anymeets to give conference organizers control of their
						own events. Anymeets offers a wide range of features that cover the
						needs of many types of events – scientific and academic conferences,
						symposiums, seminars, workshops, roundtables and more.
					</p>
					<p>
						Our team has a lot of experience organizing, coordinating, and
						managing conferences and events. We believe that while most
						conferences share some common elements, each one has unique
						characteristics. We offer tailored solutions upon discussion.
					</p>
				</Grid>
			</Grid>

			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.secondaryTitle}
					style={{color: "#3d4d59"}}
				>
					<Grid item>Why Anymeets</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{width: "768px"}}
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={FlexibleIcon} alt="Anymeets live event stream " />

								<h3 className={classes.iconTag}>Flexible site</h3>
								<p>Different colors and layouts</p>
								<p>All information in one place</p>
								<p>Interactive conference program</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={MetricsIcon} alt="Anymeets events metrics" />
								<h3 className={classes.iconTag}>Post-event metrics</h3>
								<p>Suggestions for future events</p>
								<p>Which sessions were the most popular?</p>
								<p>What did attendees like the most?</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={EventsIcon} alt="Anymeets Flexible site layouts" />
								<h3 className={classes.iconTag}>Online events</h3>
								<p>Live stream or record your sessions</p>
								<p>Attract a global audience</p>
								<p>Increase your participants</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={SupportIcon} alt="Anymeets support" />
								<h3 className={classes.iconTag}>24/7 support</h3>
								<p>
									Our highly skilled team is available round-the-clock to help
									you with your conference!
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter (About);
