import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
    },
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
        display: "block",
		cursor: 'pointer',
		textAlign: "center",
		lineHeight: "35px",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},        
	},    
});

const BottomHero = withStyles(styles)(({ classes }) => {
	return (
			<Grid container className={classes.horizontalHero}>
				<Grid item md={7} sm={6} align="left">
					<h2>
						Want to learn more?
						<br />
						Contact us to arrange a demo!
					</h2>
				</Grid>
				<Grid item md={5} sm={6} align="right">
					<Button className={classes.buttonTry} component={Link} to={`/contact`}>Learn more</Button>
				</Grid>
			</Grid>
	);
});

export default BottomHero;
