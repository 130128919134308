import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import {ReactComponent as ZoomIcon} from 'assets/icons/zoom.svg'
import {ReactComponent as HangoutsIcon} from 'assets/icons/hangouts.svg'
import {ReactComponent as AnymeetsIcon} from 'assets/icons/anymeets.svg'
import {ReactComponent as IconCheck} from 'assets/icons/check-circle-border-light.svg'
// import {ReactComponent as CallHangoutBorderDark} from 'assets/icons/call-hangout-border-dark.svg'
// import {ReactComponent as CallHangoutBorderLight} from 'assets/icons/call-hangout-border-light.svg'
// import {ReactComponent as ClockBorderDark} from 'assets/icons/clock-border-dark.svg'
// import {ReactComponent as ClockBorderLight} from 'assets/icons/clock-border-light.svg'
// import {ReactComponent as micBorderDark} from 'assets/icons/mic-border-dark.svg'
import {ReactComponent as MicBorderLight} from 'assets/icons/mic-border-light.svg'
// import {ReactComponent as MuteBorderDark} from 'assets/icons/mute-border-dark.svg'
import {ReactComponent as MuteBorderLight} from 'assets/icons/mute-border-light.svg'
// import {ReactComponent as PresentScreenBorderDark} from 'assets/icons/present-screen-border-dark.svg'
import {ReactComponent as PresentScreenBorderLight} from 'assets/icons/present-screen-border-light.svg'
// import {ReactComponent as SettingsBorderDark} from 'assets/icons/settings-border-dark.svg'
// import {ReactComponent as SettingsBorderLight} from 'assets/icons/settings-border-light.svg'
// import {ReactComponent as ShareScreenBorderDark} from 'assets/icons/share-screen-border-dark.svg'
import {ReactComponent as ShareScreenBorderLight} from 'assets/icons/share-screen-border-light.svg'
// import {ReactComponent as StopPresentScreenBorderDark} from 'assets/icons/stop-present-screen-border-dark.svg'
import {ReactComponent as StopPresentScreenBorderLight} from 'assets/icons/stop-present-screen-border-light.svg'
// import {ReactComponent as StopShareScreenBorderDark} from 'assets/icons/stop-share-screen-border-dark.svg'
import {ReactComponent as StopShareScreenBorderLight} from 'assets/icons/stop-share-screen-border-light.svg'
// import {ReactComponent as VideoBorderDark} from 'assets/icons/video-border-dark.svg'
import {ReactComponent as VideoBorderLight} from 'assets/icons/video-border-light.svg'
// import {ReactComponent as VideoOffBorderDark} from 'assets/icons/video-off-border-dark.svg'
import {ReactComponent as VideoOffBorderLight} from 'assets/icons/video-off-border-light.svg'


export { ReactComponent as IconAddBorderBlue } from 'assets/icons/add-border-blue.svg'
export { ReactComponent as IconAddBorderDark } from 'assets/icons/add-border-dark.svg'
export { ReactComponent as IconAddBorderLight } from 'assets/icons/add-border-light.svg'
export { ReactComponent as IconArrowDownBorderDark } from 'assets/icons/arrow-down-border-dark.svg'
export { ReactComponent as IconArrowDownBorderLight } from 'assets/icons/arrow-down-border-light.svg'
export { ReactComponent as IconArrowLeftBorderDark } from 'assets/icons/arrow-left-border-dark.svg'
export { ReactComponent as IconArrowLeftBorderGray } from 'assets/icons/arrow-left-border-gray.svg'
export { ReactComponent as IconArrowLeftBorderLight } from 'assets/icons/arrow-left-border-light.svg'
export { ReactComponent as IconArrowRightBorderBlue } from 'assets/icons/arrow-right-border-blue.svg'
export { ReactComponent as IconArrowRightBorderDark } from 'assets/icons/arrow-right-border-dark.svg'
export { ReactComponent as IconArrowRightBorderWhite } from 'assets/icons/arrow-right-border-white.svg'
export { ReactComponent as IconArrowLeftDoubleGray } from 'assets/icons/arrow-left-double-gray.svg'
export { ReactComponent as IconArrowRightDoubleGray } from 'assets/icons/arrow-right-double-gray.svg'
export { ReactComponent as IconBellBorderDark } from 'assets/icons/bell-border-dark.svg'
export { ReactComponent as IconBellBorderGray } from 'assets/icons/bell-border-gray.svg'
export { ReactComponent as IconBellBorderLight } from 'assets/icons/bell-border-light.svg'
export { ReactComponent as IconBookBorderLight } from 'assets/icons/book-border-light.svg'
export { ReactComponent as IconBookmarkBorderDark } from 'assets/icons/bookmark-border-dark.svg'
export { ReactComponent as IconBookmarkBorderGreen } from 'assets/icons/bookmark-border-green.svg'
export { ReactComponent as IconBookmarkBorderLight } from 'assets/icons/bookmark-border-light.svg'
export { ReactComponent as IconBookmarkFillGreen } from 'assets/icons/bookmark-fill-green.svg'
export { ReactComponent as IconCalendarBorderDark16px } from 'assets/icons/calendar-border-dark-16px.svg'
export { ReactComponent as IconCalendarBorderDark } from 'assets/icons/calendar-border-dark.svg'
export { ReactComponent as IconCalendarBorderLight } from 'assets/icons/calendar-border-light.svg'
export { ReactComponent as IconCameraBorderGray } from 'assets/icons/camera-border-gray.svg'
export { ReactComponent as IconCameraBorderGreen } from 'assets/icons/camera-border-green.svg'
export { ReactComponent as IconCheckCircleBorderDark } from 'assets/icons/check-circle-border-dark.svg'
export { ReactComponent as IconCheckCircleBorderLight } from 'assets/icons/check-circle-border-light.svg'
export { ReactComponent as IconClockBorderDark } from 'assets/icons/clock-border-dark.svg'
export { ReactComponent as IconClockBorderLight } from 'assets/icons/clock-border-light.svg'
export { ReactComponent as IconCommentBorderDark } from 'assets/icons/comment-border-dark.svg'
export { ReactComponent as IconDollarSignBorderDark } from 'assets/icons/dollar-sign-border-dark.svg'
export { ReactComponent as IconDollarSignBorderGray } from 'assets/icons/dollar-sign-border-gray.svg'
export { ReactComponent as IconDownloadBorderDark } from 'assets/icons/download-border-dark.svg'
export { ReactComponent as IconDownloadBorderGreen } from 'assets/icons/download-border-green.svg'
export { ReactComponent as IconFileBorderDark } from 'assets/icons/file-border-dark.svg'
export { ReactComponent as IconFileBorderCurrentColor } from 'assets/icons/file-border-dark-current.svg'
export { ReactComponent as IconFileBorderGreen } from 'assets/icons/file-border-green.svg'
export { ReactComponent as IconFileBorderLight } from 'assets/icons/file-border-light.svg'
export { ReactComponent as IconHeartBorderDark } from 'assets/icons/heart-border-dark.svg'
export { ReactComponent as IconHeartFillDark } from 'assets/icons/heart-fill-dark.svg'
export { ReactComponent as IconHelpCircleBorderDark } from 'assets/icons/help-circle-border-dark.svg'
export { ReactComponent as IconHelpCircleBorderLight } from 'assets/icons/help-circle-border-light.svg'
export { ReactComponent as IconInfoBorderDark } from 'assets/icons/info-border-dark.svg'
export { ReactComponent as IconInfoBorderLight } from 'assets/icons/info-border-light.svg'
export { ReactComponent as IconLockBorderDark } from 'assets/icons/lock-border-dark.svg'
export { ReactComponent as IconLockBorderGreen } from 'assets/icons/lock-border-green.svg'
export { ReactComponent as IconLockBorderLight } from 'assets/icons/lock-border-light.svg'
export { ReactComponent as IconLogoutBorderDark } from 'assets/icons/logout-border-dark.svg'
export { ReactComponent as IconLogoutBorderLight } from 'assets/icons/logout-border-light.svg'
export { ReactComponent as IconMailBorderDark } from 'assets/icons/mail-border-dark.svg'
export { ReactComponent as IconMailBorderGreen } from 'assets/icons/mail-border-green.svg'
export { ReactComponent as IconMailBorderLight } from 'assets/icons/mail-border-light.svg'
export { ReactComponent as IconMapPinBorderDark } from 'assets/icons/map-pin-border-dark.svg'
export { ReactComponent as IconMapPinBorderGray16px } from 'assets/icons/map-pin-border-gray-16px.svg'
export { ReactComponent as IconMapPinBorderGray } from 'assets/icons/map-pin-border-gray.svg'
export { ReactComponent as IconMapPinFillGreen } from 'assets/icons/map-pin-fill-green.svg'
export { ReactComponent as IconMenuBorderDark } from 'assets/icons/menu-border-dark.svg'
export { ReactComponent as IconNotesBorderDark } from 'assets/icons/notes-border-dark.svg'
export { ReactComponent as IconNotesBorderLight } from 'assets/icons/notes-border-light.svg'
export { ReactComponent as IconPlayCircleBorderDark } from 'assets/icons/play-circle-border-dark.svg'
export { ReactComponent as IconPlayCircleBorderGreen } from 'assets/icons/play-circle-border-green.svg'
export { ReactComponent as IconPlayCircleBorderWhite } from 'assets/icons/play-circle-border-white.svg'
export { ReactComponent as IconSearchBorderDark } from 'assets/icons/search-border-dark.svg'
export { ReactComponent as IconSearchBorderLight } from 'assets/icons/search-border-light.svg'
export { ReactComponent as IconSettingsBorderDark } from 'assets/icons/settings-border-dark.svg'
export { ReactComponent as IconSettingsBorderLight } from 'assets/icons/settings-border-light.svg'
export { ReactComponent as IconShareBorderGreen } from 'assets/icons/share-border-green.svg'
export { ReactComponent as IconShareBorderWhite } from 'assets/icons/share-border-white.svg'
export { ReactComponent as IconSidebarBorderDark } from 'assets/icons/sidebar-border-dark.svg'
export { ReactComponent as IconSidebarBorderGreen } from 'assets/icons/sidebar-border-green.svg'
export { ReactComponent as IconSidebarBorderLight } from 'assets/icons/sidebar-border-light.svg'
export { ReactComponent as IconSpeakersBorderDark } from 'assets/icons/speakers-border-dark.svg'
export { ReactComponent as IconSpeakersBorderGreen } from 'assets/icons/speakers-border-green.svg'
export { ReactComponent as IconSpeakersBorderLight } from 'assets/icons/speakers-border-light.svg'
export { ReactComponent as IconStarBorderDark } from 'assets/icons/star-border-dark.svg'
export { ReactComponent as IconStarBorderLight } from 'assets/icons/star-border-light.svg'
export { ReactComponent as IconStarFillDark } from 'assets/icons/star-fill-dark.svg'
export { ReactComponent as IconStatusOkFillGreen } from 'assets/icons/status-ok-fill-green.svg'
export { ReactComponent as IconTagBorderDark } from 'assets/icons/tag-border-dark.svg'
export { ReactComponent as IconTagBorderGray16 } from 'assets/icons/tag-border-gray-16.svg'
export { ReactComponent as IconTagBorderGray } from 'assets/icons/tag-border-gray.svg'
export { ReactComponent as IconTagBorderGreen } from 'assets/icons/tag-border-green.svg'
export { ReactComponent as IconTagBorderLight } from 'assets/icons/tag-border-light.svg'
export { ReactComponent as IconThumbsUpBorderLight } from 'assets/icons/thumbs-up-border.svg'
export { ReactComponent as IconUploadBorderDark } from 'assets/icons/upload-border-dark.svg'
export { ReactComponent as IconUploadBorderGreen } from 'assets/icons/upload-border-green.svg'
export { ReactComponent as IconUploadBorderLight } from 'assets/icons/upload-border-light.svg'
export { ReactComponent as IconUserBorderDark } from 'assets/icons/user-border-dark.svg'
export { ReactComponent as IconUserBorderLight } from 'assets/icons/user-border-light.svg'
export { ReactComponent as IconUserPhoto } from 'assets/icons/user-photo.svg'
export { ReactComponent as IconViewBorderDark } from 'assets/icons/view-border-dark.svg'
export { ReactComponent as IconViewBorderGray } from 'assets/icons/view-border-gray.svg'
export { ReactComponent as IconViewBorderGreen } from 'assets/icons/view-border-green.svg'
export { ReactComponent as IconViewBorderLight } from 'assets/icons/view-border-light.svg'
export { ReactComponent as IconXBorderDark } from 'assets/icons/x-border-dark.svg'
export { ReactComponent as IconXBorderLight } from 'assets/icons/x-border-light.svg'
export { ReactComponent as IconUserPicAdd } from 'assets/icons/user-pic-add.svg'
export { ReactComponent as IconUserPicAddLight } from 'assets/icons/user-pic-add-light.svg'
export { ReactComponent as IconSignOutBorderDark } from 'assets/icons/sign_out_border_dark.svg'
export { ReactComponent as IconManageBorderDark} from 'assets/icons/manage-border-dark.svg'
export { ReactComponent as IconPlusCircleBorderGray} from 'assets/icons/plus-circle-border-gray.svg'
export { ReactComponent as IconMinusCircleBorderGray} from 'assets/icons/minus-circle-border-gray.svg'
export { ReactComponent as IconTicketBorderLight} from 'assets/icons/ticket-border-light.svg'
export { ReactComponent as IconDollarSignBorderLight} from 'assets/icons/dollar-sign-border-light.svg'
export { ReactComponent as IconStatusOkFillGreen16} from 'assets/icons/status-Ok-Fill-Green-16.svg'
export { ReactComponent as IconStatusOkFillGrey16} from 'assets/icons/status-Ok-Fill-Grey-16.svg'
export { ReactComponent as IconAgendaBorderDark} from 'assets/icons/agenda-border-dark.svg'
export { ReactComponent as IconCoffeeBorderDark} from 'assets/icons/coffee-border-dark.svg'
export { ReactComponent as IconThumbsDownDark } from 'assets/icons/thumbs-down-dark.svg'
export { ReactComponent as IconThumbsUpDark } from 'assets/icons/thumbs-up-dark.svg'
export { ReactComponent as IconLive} from 'assets/icons/live.svg'

export { ReactComponent as IconAttending} from 'assets/icons/icon_attending-session.svg'
export { ReactComponent as IconFeedback} from 'assets/icons/icon_feedback.svg'
export { ReactComponent as IconPolls} from 'assets/icons/icon_live-polls.svg'
export { ReactComponent as IconChat} from 'assets/icons/icon_participants-chat.svg'
export { ReactComponent as IconPresenters} from 'assets/icons/icon_presenters.svg'
export { ReactComponent as IconQA} from 'assets/icons/icon_QA.svg'

export { ReactComponent as IconCheckoutForm} from 'assets/icons/icon_checkout-form.svg'
export { ReactComponent as IconRegistrations} from 'assets/icons/icon_participant-registrations.svg'
export { ReactComponent as IconPricing} from 'assets/icons/icon_pricing.svg'
export { ReactComponent as IconTickets} from 'assets/icons/icon_tickets.svg'

export { ReactComponent as IconDistributeWorkload} from 'assets/icons/icon_distribute-workload.svg'
export { ReactComponent as IconNotifications} from 'assets/icons/icon_notifications.svg'
export { ReactComponent as IconProceedings} from 'assets/icons/icon_proceedings.svg'

export { ReactComponent as IconSessions} from 'assets/icons/icon_sessions.svg'
export { ReactComponent as IconStayInTouch} from 'assets/icons/icon_stay-in-touch.svg'


export const IconZoom = (props) => <SvgIcon {...props}><ZoomIcon /></SvgIcon>
export const IconHangouts = (props) => <SvgIcon {...props}><HangoutsIcon /></SvgIcon>
export const IconAnymeets = (props) => <SvgIcon {...props}><AnymeetsIcon /></SvgIcon>
export const IconCheckCircled = (props) => <SvgIcon {...props}><IconCheck/></SvgIcon>

export const IconMic = (props) => <SvgIcon {...props}><MicBorderLight /></SvgIcon>
export const IconMicOff = (props) => <SvgIcon {...props}><MuteBorderLight /></SvgIcon>
export const IconCamera = (props) => <SvgIcon {...props}><VideoBorderLight /></SvgIcon>
export const IconCameraOff = (props) => <SvgIcon {...props}><VideoOffBorderLight /></SvgIcon>
export const IconPresentScreen = (props) => <SvgIcon {...props}><PresentScreenBorderLight /></SvgIcon>
export const IconPresentScreenOff = (props) => <SvgIcon {...props}><StopPresentScreenBorderLight /></SvgIcon>
export const IconShareScreen = (props) => <SvgIcon {...props}><ShareScreenBorderLight /></SvgIcon>
export const IconShareScreenOff = (props) => <SvgIcon {...props}><StopShareScreenBorderLight /></SvgIcon>
