import React from 'react'
import {Box, Grid, Radio} from '@material-ui/core'
import {themesEvent} from 'theme'
import {withStyles} from '@material-ui/core/styles';


const BlueRadio = withStyles({
  root: {
    color: themesEvent['theme1'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme1'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: themesEvent['theme2'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme2'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: themesEvent['theme3'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme3'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const PurpleRadio = withStyles({
  root: {
    color: themesEvent['theme8'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme8'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const AppleCaramelRadio = withStyles({
  root: {
    color: themesEvent['theme5'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme5'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const NauticalRadio = withStyles({
  root: {
    color: themesEvent['theme6'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme6'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CherryRadio = withStyles({
  root: {
    color: themesEvent['theme4'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme4'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BrownRadio = withStyles({
  root: {
    color: themesEvent['theme7'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme7'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BlackWhiteRadio = withStyles({
  root: {
    color: themesEvent['theme9'].palette.primary.main,
    '&$checked': {
      color: themesEvent['theme9'].palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const Palettes = ({selectedTheme,changeTheme,...props}) => {

  return <Box mt={3} mb={2} maxWidth={560}>
    <Grid container spacing={1} alignItems={'center'} alignContent={'center'} justify={'flex-start'}>
      <Grid item >
            <BlueRadio
              checked={selectedTheme === 1}
              onChange={() => changeTheme('theme',1)}
            />

      </Grid>
      <Grid item >
            <RedRadio
              checked={selectedTheme === 2}
              onChange={() => changeTheme('theme',2)}
            />
      </Grid>
      <Grid item >
        <GreenRadio
          checked={selectedTheme === 3}
          onChange={() => changeTheme('theme',3)}
        />
      </Grid>
      <Grid item >
        <CherryRadio
          checked={selectedTheme === 4}
          onChange={() => changeTheme('theme',4)}
        />
      </Grid>
      <Grid item >
        <AppleCaramelRadio
          checked={selectedTheme === 5}
          onChange={() => changeTheme('theme',5)}
        />
      </Grid>
      <Grid item >
        <NauticalRadio
          checked={selectedTheme === 6}
          onChange={() => changeTheme('theme',6)}
        />
      </Grid>
      <Grid item >
        <BrownRadio
          checked={selectedTheme === 7}
          onChange={() => changeTheme('theme',7)}
        />
      </Grid>
      <Grid item >
        <PurpleRadio
          checked={selectedTheme === 8}
          onChange={() => changeTheme('theme',8)}
        />
      </Grid>
      <Grid item >
        <BlackWhiteRadio
          checked={selectedTheme === 9}
          onChange={() => changeTheme('theme',9)}
        />
      </Grid>
    </Grid>
  </Box>

}

export default Palettes
