import React, {useEffect} from 'react'
import Box from '@material-ui/core/Box'
import {useMetaData} from 'components/core/MetaProvider'
import {theme} from 'theme'
import {withStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const styles = () => ({
  page: {
    minHeight: 'calc(100vh - 128px - 48px)',
    paddingBottom: '64px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 128px - 51px)'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 51px)'
    },
  },
  noFooter: {
    paddingBottom: 0,
    marginBottom: -(theme.spacing(6))
  }
})

const Page = ({classes, children, backgroundColor, staticContext, title='', noFooter, ...rest}) => {

  const inlineStyle = {
    backgroundColor: backgroundColor
  }

  const [,setMetaData] = useMetaData()

  useEffect(()=>{
    if (title) {
      setMetaData({title})
    }
    // eslint-disable-next-line
  },[])


  return  <Box style={inlineStyle} className={clsx(classes.page, noFooter&&classes.noFooter)} {...rest}> {children} </Box>
}

export default withStyles(styles)(Page)
