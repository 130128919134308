import {Link, useLocation, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// import {IconSearchBorderDark} from 'Components/core/icons'
import IconButton from '@material-ui/core/IconButton'
import {FILES_URL, LAYOUT_TYPES, TIME_FADE_IN} from 'config'
import MenuIcon from '@material-ui/icons/Menu'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import Button from 'components/core/Button'
import clsx from 'clsx'
import RoomsButton from 'components/core/Header/Rooms/RoomsButton'
import {useAuth} from 'auth/AuthController'
import {Button as MUIButton} from '@material-ui/core'
import SponsorManageButton from 'components/core/SponsorManageButton'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import {NotificationSender} from 'components/core/Notifications'
import { checkHost } from 'utils'
import language from "components/language";
import { useHttp } from "api/core";
import api from "api";

const styles = theme => ({
  navLinks: {
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      display: "inline-block",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  userBlock: {
    backgroundColor: palette.white,
    height: '64px',
    maxWidth: '94px',
    width: '94px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    '& a': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:hover': {
      backgroundColor: palette['pale-grey'],
      transition: 'background-color 300ms linear'
    }
  },
  profileButton: {
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  avatar: {
    width: '36px',
    height: '36px',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  placeholder: {
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundColor: palette['pale-grey'],
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  menuNav: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  menuButton: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up(1150)]: {
      display: 'none'
    }
  },
  hideMobile: {
    display: 'flex',
    width: "155px",
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  eventLinks: {
    [theme.breakpoints.down(1360)]: {
      display: 'none'
    }
  },
  eventLinksUnmanaged: {
    [theme.breakpoints.down(1180)]: {
      display: 'none'
    }
  },
  eventLinksMenu: {
    [theme.breakpoints.up(1360)]: {
      display: 'none'
    }
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const EventMenu = withRouter(withStyles(styles)(({classes, userInfo, toggleDrawer, history, setProfileDialogOpen, virtualRoomData, event, extras, canManage, hasRooms, isSponsor, isSponsorHost, sponsorId, isHost}) => {
  const userName = userInfo && userInfo.info ? userInfo.info.firstName : ''
  const shortName = userInfo && userInfo.info && userInfo.info.firstName ? userInfo.info.firstName[0] : ''
  const shortLastName = userInfo && userInfo.info && userInfo.info.lastName ? userInfo.info.lastName[0] : ''
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isAuthenticated] = useAuth()
  const [sendAnnouncement, setSendAnnouncement] = useState(false)
  const [getData, data, isLoadingData] = useHttp();
  const [contentsData, setContentsData] = useState([]);

  useEffect(() => {
    if (event) {
      getData(
        api.entities.events.getContents(null, null, {
          eventId: event.eventInfo.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    if (data) {
      setContentsData(data.content);
    }
  }, [data]); 

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    handleClose()
  }, [history.location])

  const q = useQuery()
  const isPreview = q.get('preview') === 'true'
  const previewSuffix = isPreview ? '?preview=true' : ''

  const {slug} = useParams()
  const host = checkHost()

  let baseURL = `/event/${event.eventInfo.id}`

  if (slug) {
    baseURL = `/${slug}`
  } else if (host) {
    baseURL = ``
  }

  function createNavLink(item) {
    switch (item.contentEnumType) {
      case 1:
        return (
            <Link
              to={`${baseURL}/info${previewSuffix}`}
            >
              {item.label}
            </Link>
        );
      case 2:
        return (
          event.sessionsCount !== 0 && (
              <Link
                to={`${baseURL}/agenda${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 3:
        return (
          event.participantsCount !== 0 && (
              <Link
                to={`${baseURL}/participants${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 4:
        return (
          event.keynoteSpeakers.length !== 0 && (
              <Link
                to={`${baseURL}/keynotespeakers${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 5:
        return (
          event.sponsors.length !== 0 && (
              <Link
                to={`${baseURL}/sponsors${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 6:
        return (
          event.eventInfo.hasRestaurants && (
              <Link
                to={`${baseURL}/restaurants${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 7:
        return (
          event.eventInfo.hasHotels && (
              <Link
                to={`${baseURL}/hotels${previewSuffix}`}
              >
                {" "}
                {item.label}{" "}
              </Link>
          )
        );
      case 8:
        return (
          event.organizers.length !== 0 && (
              <Link
                to={`${baseURL}/organizers${previewSuffix}`}
              >
                {item.label}
              </Link>
          )
        );
      case 9:
        return (
          event.eventInfo.hasExtras &&
          extras.map((extra) => (
              <Link
                to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
              >
                {extra.title}
              </Link>

          ))
        );
      case 10:
        return (
            <Link
              to={`${baseURL}/general-page/${item.id}${previewSuffix}`}             
            >
              {item.label}
            </Link>
        );
      case 11:
        return (
          (event.eventInfo.showProceedings === 1 ||
            (event.eventInfo.showProceedings === 3 &&
              event.eventInfo.registered)) && (
            <Link to={`${baseURL}/proceedings${previewSuffix}`}>
              {item.label}
            </Link>
          )
        );      
      default:
        return "";
    }
  }


  return <Box display='flex' alignItems='center'>
    <Box className={classes.navLinks}>      

    {(event&&extras&&event.eventInfo.layout===LAYOUT_TYPES.SINGLE_PAGE)&&<Fade in={true} timeout={TIME_FADE_IN}><Box className={clsx(canManage?classes.eventLinks:classes.eventLinksUnmanaged)}>
      {data &&
        contentsData.map((item) => {
          return createNavLink(item);
        })}      

      {/* <Link to={`${ baseURL }/info${ previewSuffix }`}> {language.about} </Link>

      {event.sessionsCount !== 0 &&
          <Link to={`${ baseURL }/agenda${ previewSuffix }`}> {language.agenda} </Link>
      }

      {event.participantsCount !== 0 &&
          <Link to={`${ baseURL }/participants${ previewSuffix }`}> {language.speakers} </Link>
      }

      {event.keynoteSpeakers && event.keynoteSpeakers.length !== 0 &&
          <Link to={`${ baseURL }/keynotespeakers${ previewSuffix }`}> {language.keynoteSpeakers} </Link>
      }

      {event.sponsors && event.sponsors.length !== 0 &&
          <Link to={`${ baseURL }/sponsors${ previewSuffix }`}> {language.sponsors} </Link>
      }

        {((event.eventInfo.showProceedings  === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) &&
        <Link to={`${baseURL}/proceedings${previewSuffix}`}> {language.proceedings} </Link>
      }

      {event.eventInfo.hasExtras&&extras&&extras.map((extra,index)=>
        <Link key={index} to={`${baseURL}/extra/${extra.id}${previewSuffix}`}> {extra.title} </Link>
      )}

      {event.eventInfo.hasHotels&&
        <Link to={`${baseURL}/hotels${previewSuffix}`}> {language.hotels} </Link>
      }

      {event.eventInfo.hasRestaurants&&
          <Link to={`${ baseURL }/restaurants${ previewSuffix }`}> {language.restaurants} </Link>
      } */}

      {/* <Link to={`${baseURL}/social-feed${previewSuffix}`}>{language.socialFeed}</Link> */}

      </Box></Fade>}

      {isAuthenticated && <>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menuNav}
        >

          {(event && extras && event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE) && <><Fade in={true}
                                                                                               timeout={TIME_FADE_IN}><Box
              className={classes.eventLinksMenu}>
            {data &&
              contentsData.map((item) => {
                return createNavLink(item);
              })}                
            {/* <Link to={`${baseURL}/info${previewSuffix}`}> <MenuItem>{language.info}</MenuItem></Link>

            {event.sessionsCount !== 0 &&
                <Link to={`${ baseURL }/agenda${ previewSuffix }`}><MenuItem>{language.agenda} </MenuItem></Link>
            }

            {event.participantsCount !== 0 &&
            <Link to={`${baseURL}/participants${previewSuffix}`}><MenuItem>{language.speakers}</MenuItem></Link>
            }

            {event.keynoteSpeakers && event.keynoteSpeakers.length !== 0 &&
            <Link to={`${baseURL}/keynotespeakers${previewSuffix}`}><MenuItem>{language.keynoteSpeakers}</MenuItem></Link>
            }

            {event.sponsors && event.sponsors.length !== 0 &&
                <Link to={`${ baseURL }/sponsors${ previewSuffix }`}><MenuItem>{language.sponsors} </MenuItem></Link>
            }

            {((event.eventInfo.showProceedings  === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) &&
            <Link
                  to={`${ baseURL }/proceedings${ previewSuffix }`}><MenuItem> {language.proceedings}</MenuItem></Link>
            }

            {event.eventInfo.hasExtras && extras && extras.map((extra,index) =>
              <Link key={index} 
                to={`${baseURL}/extra/${extra.id}${previewSuffix}`}><MenuItem>{extra.title}</MenuItem></Link>
            )}

            {event.eventInfo.hasHotels &&
                <Link to={`${ baseURL }/hotels${ previewSuffix }`}><MenuItem>{language.hotels}</MenuItem></Link>
            }

            {event.eventInfo.hasRestaurants &&
            <Link
                  to={`${ baseURL }/restaurants${ previewSuffix }`}><MenuItem>{language.restaurants}</MenuItem></Link>
            }

            <Link
                to={`${ baseURL }/social-feed${ previewSuffix }`}><MenuItem>{language.socialFeed}</MenuItem></Link> */}

            <Divider/>
          </Box></Fade></>}

          {isAuthenticated && <>
            {/* <Link to='/create-event'><MenuItem>Create Event</MenuItem></Link> */}
            {/* <Link to='/select-package'><MenuItem>Create Event</MenuItem></Link> */}
            <Link to='/joined-events'><MenuItem>Joined Events</MenuItem></Link>

            {/* <Link to='/search'><MenuItem>Search Events</MenuItem></Link> */}
            {/* <Link to='/my-tickets'><MenuItem>My tickets</MenuItem></Link> */}
            {/* <Link to='/favorites'><MenuItem>Favorites</MenuItem></Link> */}

            <Divider/>
            <Link to='/manage-events'><MenuItem>Manage Events </MenuItem></Link>
            <Divider/>
            {/* <Link to='/profile/settings'><MenuItem>Profile</MenuItem></Link> */}
            {/* <Link to='/help'><MenuItem>Help</MenuItem></Link> */}
            <Link to='/sign-out'><MenuItem>Sign Out</MenuItem></Link>

          </>
          }

        </Menu>
      </>
      }
    </Box>

    {/*<Box mr={2}><Link to='/search'><IconSearchBorderDark/></Link></Box>*/}
    {/*<Box mr={2} className={classes.hideMobile}><Link to='/notifications'><IconBellBorderDark/></Link></Box>*/}

    {isSponsor&&isSponsorHost&&<SponsorManageButton data={event} />}

    {(isSponsor || (event&&event.eventInfo.live && event.eventInfo.registered && hasRooms)) && <Box>
      <Box mr={1} ml={3}>
        <RoomsButton eventId={event.eventInfo.id} canManage={canManage} isSponsor={isSponsor} sponsorId={sponsorId} isHost={isHost} />
      </Box>
    </Box>}

    {canManage&&<Box className={classes.hideMobile}>
      <Box mr={2} ml={1}>
        <Link to={`/manage-event/${event.eventInfo.id}/general`}><Button size='smaller' variant='outlined'> Manage event </Button></Link>
      </Box>
    </Box>}

    {canManage&&<>
      <Box mr={2} ml={1}>
        <IconButton onClick={()=>setSendAnnouncement(true)}> <AnnouncementIcon/> </IconButton>
      </Box>
      <NotificationSender eventId={event.eventInfo.id} isSession={false} virtualRoomData={virtualRoomData} open={sendAnnouncement} setOpen={setSendAnnouncement} />
    </>}

    {/*<Box mr={2}>*/}
    {/*  <Link to='/create-event'><Button color='primary' size='smaller'> Create event </Button></Link>*/}
    {/*</Box>*/}

    <Box className={classes.userBlock}>
      {!isAuthenticated&&<>
        <Box className={classes.registerBlock}>
          <Box> <Link to='/login'> <MUIButton component={Box} className={classes.registerButton} color='primary'>SIGN
            IN</MUIButton></Link> </Box>
        </Box>
      </>}
      {isAuthenticated&&<>
      <Box>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.profileButton}
        >
          {userInfo && userInfo.info && userInfo.info.pictureId ?
            <img className={classes.avatar} title={userName} alt={userName}
                 src={`${FILES_URL}/${userInfo.info.pictureId}`}/> :
            <Box width={36} height={36} className={classes.placeholder}> {shortName} {shortLastName} </Box>}
        </IconButton>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={toggleDrawer('right', true)}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
      </Box>
      </>}
    </Box>
  </Box>
}))

export default EventMenu
