import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import {
  IconArrowLeftBorderLight,
  IconArrowRightBorderWhite
} from 'components/core/icons'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  nav: {
    position: 'fixed',
    width: '100%',
    top: '45%',
    boxSizing: 'border-box',
    left: 0,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      marginTop: theme.spacing(2)
    }
  },
  nextBtn: {
    zIndex: 2,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none'
  },
  prevBtn: {
    zIndex: 2,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none'
  }
})

const CreateEventNavigation = withStyles(styles)( ({submitForm, errors, history, prevRoute, nextRoute, submit=true, classes}) => {

  const nextPage = () => {
    if (submit) {
      submitForm().then(() => {
        // history.push(nextRoute)
      })
    }

  }

  const prevPage = () => {
    if (submit) {
      submitForm().then(() => {
        history.push(prevRoute)
      })
    } else {
      history.push(prevRoute)
    }
  }

  return <Box display='flex' flexDirection='row' flexBasis='100%' justifyContent='space-between' alignItems='flex-end' pr={4} pl={4} mb={6} className={classes.nav}>
    <Box display='flex'  >
      {prevRoute?<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' className={classes.prevBtn} onClick={()=>{prevPage()}}><IconArrowLeftBorderLight/> &nbsp; Previous</Box>:''}
    </Box>
    <Box display='flex'  >
      {nextRoute?<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' className={classes.nextBtn} onClick={()=>{nextPage()}}>Next &nbsp;<IconArrowRightBorderWhite/> </Box>:''}
    </Box>
  </Box>
})

export default withRouter(CreateEventNavigation)
