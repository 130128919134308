import React from 'react'
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import DropCard from "components/core/Header/Dropdown/DropCard";
import VirtualSession from 'assets/ui-screens/ui-virtual-event.png'
import SettingUp from 'assets/ui-screens/ui-set-up-event.png'
import Submissions from 'assets/ui-screens/ui-submission.png'

const styles = theme => ({
  wrapper: {
    // position: 'fixed',
    // top: 64,
    left: 0,
    width: '100%',
    paddingTop: 19
  },
  main: {
    backgroundColor: 'white',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    minHeight: '200px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  container: {
    marginTop: 48,
    paddingBottom: 48
  }
})

const FullWidthMenu = withRouter(withStyles(styles)(({classes}) => {


  // const styleProps = useSpring({opacity: 1, from: {opacity: 0}, config: {duration: 100}})

  return <Box className={classes.wrapper}>
      <Box style={{marginTop: -5, overflowY: 'hidden', paddingBottom: 10}}>
        <Box className={classes.main}>
          <Container className={classes.container} maxWidth='md'>
            <Grid container spacing={2} justify={'center'} alignContent='center' alignItems='center'>
              <Grid item xs={4}>
                <DropCard
                  title={'Setting up'}
                  description={''}
                  image={SettingUp}
                  link='/setting-up'
                />
              </Grid>
              <Grid item xs={4}>
                <DropCard
                  title={'The virtual session'}
                  description={''}
                  image={VirtualSession}
                  link='/the-virtual-session'
                />
              </Grid>
              <Grid item xs={4}>
                <DropCard
                  title={'Submissions and reviews'}
                  description={''}
                  image={Submissions}
                  link='/submissions-reviews'
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
  </Box>
}))

export default FullWidthMenu
