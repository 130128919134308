import React from 'react'
import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Form, Formik} from 'formik'
import {Typography} from '@material-ui/core'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {withRouter} from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import Grid from '@material-ui/core/Grid'
import {themesEvent} from 'theme'
import PalettePreview from 'components/pages/ManageEvent/PalettePreview'
import Palettes from 'components/pages/ManageEvent/Palettes'

const styles = theme => ({
  title: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  },
  form: {
    width: '100%',
  },
  description: {
    color: 'white',
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  showMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  option: {
    marginBottom: theme.spacing(2)
  }
})

const CreateEventLayout = withStyles(styles)(({classes, setFormData, formData, prevRoute, nextRoute, history}) => {

  return <><Formik
    initialValues={formData}
    // validationSchema={Schema}
    onSubmit={(values, {setSubmitting}) => {
      setFormData({...formData, ...values})
      history.push(nextRoute)
    }}>
    {({values, setFieldValue, submitForm, errors}) => (<>
        <Box className={classes.hideMobile}>
          <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
        </Box>
      <Fade in={true} timeout={TIME_FADE_IN}><Form className={classes.form}>
        <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={4} minHeight={window.innerHeight-280}>
          <Box display='flex' flexDirection='column' pb={8}>
            <Typography component='h2' variant='h5' className={classes.title}>Event Layout</Typography>
            {/*<Box mt={3} mb={2} display='flex' flexDirection='row' justifyContent='center' alignContent='center'>*/}
            {/*  <Box mr={2}>*/}
            {/*    <OptionCard text='Top Menu' icon={<IconFileBorderDark/>} onClick={() => {*/}
            {/*      setFieldValue('layout', 1)*/}
            {/*    }} selected={values.layout === 1}/>*/}
            {/*    /!*<Box className={classes.description}>View Example</Box>*!/*/}
            {/*  </Box>*/}
            {/*  <Box mr={2}>*/}
            {/*    <OptionCard text='Left Menu' icon={<IconSidebarBorderDark/>} onClick={() => {*/}
            {/*      setFieldValue('layout', 2)*/}
            {/*    }} selected={values.layout === 2}/>*/}
            {/*    /!*<Box className={classes.description}>View Example</Box>*!/*/}
            {/*  </Box>*/}
            {/*  <Box mr={2}>*/}
            {/*    <OptionCard text='Left Sidebar' icon={<IconSidebarBorderDark/>} onClick={() => {*/}
            {/*      setFieldValue('layout', 3)*/}
            {/*    }} selected={values.layout === 3}/>*/}
            {/*    /!*<Box className={classes.description}>View Example</Box>*!/*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Grid item container justify='center' alignItems='center' alignContent='center' spacing={2}>
              <Box display='flex' mt={3} mb={2} maxWidth={430}>
                <Grid item container spacing={2} justify={'center'}>
                  <ThemeProvider theme={themesEvent[`theme${values.theme}`]}>
                    <Grid item xs={12} sm={6}>
                      <Grid item container justify='center' alignItems='center' alignContent='center' spacing={2}>
                        <Grid item className={classes.option}>
                          <PalettePreview layout={1} selected={values.layout === 1} themeSelected={values.theme} onClick={() => {
                            setFieldValue('layout', 1)
                          }}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item container justify='center' alignItems='center' alignContent='center' spacing={2}>
                        <Grid item className={classes.option}>
                          <PalettePreview layout={2} selected={values.layout === 2} themeSelected={values.theme} onClick={() => {
                            setFieldValue('layout', 2)
                          }}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item container justify='center' alignItems='center' alignContent='center' spacing={2}>
                        <Grid item className={classes.option}>
                          <PalettePreview layout={3} selected={values.layout === 3} themeSelected={values.theme} onClick={() => {
                            setFieldValue('layout', 3)
                          }}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item container justify='center' alignItems='center' alignContent='center' spacing={2}>
                        <Grid item className={classes.option}>
                          <PalettePreview layout={4} selected={values.layout === 4} themeSelected={values.theme} onClick={() => {
                            setFieldValue('layout', 4)
                          }}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Box>
            </Grid>
            <Grid item justify='center' alignItems='center' alignContent='center' spacing={2}>
              <Grid item>
                <Typography component='h2' variant='h5' className={classes.title}>Theme</Typography>
              </Grid>
              <Grid item>
                <Palettes selectedTheme={values.theme} changeTheme={setFieldValue}/>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.showMobile}>
          <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
        </Box>
      </Form></Fade></>
    )}
  </Formik>
  </>
})

export default withRouter(CreateEventLayout)
