import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Form, Formik} from 'formik'
import Box from '@material-ui/core/Box'
import {FormHelperText, Typography} from '@material-ui/core'
import {DatePicker, TimePicker} from '@material-ui/pickers'
import * as Yup from 'yup'
import {withRouter} from 'react-router-dom'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {IconCalendarBorderDark, IconClockBorderDark} from 'components/core/icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'

const styles = theme => ({
  title: {
    textAlign: 'center',
    color: 'white'
  },
  form: {
    width: '100%'
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  showMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
})

const Schema = Yup.object().shape({
  general: Yup.object().shape({
    startDate: Yup.date().required('This field is required'),
    endDate: Yup.date().when(
      'startDate',
      (startDate, schema) => (startDate && schema.min(startDate))).required('This field is required')
  })})

const CreateEventDateTime = withStyles(styles)(({classes, formData, setFormData, history, prevRoute, nextRoute}) => {

  return <>
      <Formik
        initialValues={formData}
        validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          setFormData({...formData, ...values})
          history.push(nextRoute)
        }}>
        {({ isSubmitting, values, setFieldValue, submitForm, errors}) => (<>
          <Box className={classes.hideMobile}>
            <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute} />
          </Box>
          <Fade in={true} timeout={TIME_FADE_IN}><Form className={classes.form}>
            <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={4} minHeight={window.innerHeight-280}>
              <Box display='flex' width='350px' flexDirection='column' pb={8}>
            <Typography component='h2' variant='h5' className={classes.title}>Event Date & Time</Typography>
            <Box mt={3} mb={2}>
              <DatePicker disablePast autoOk={true} okLabel="" name='general.startDate' label='Start Date' inputVariant='filled' onChange={(value)=>{setFieldValue("general.startDate", value.toISOString()); if (value.toISOString() > values.general.endDate){ setFieldValue("general.endDate", value.toISOString()); }}} value={values.general.startDate} fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconCalendarBorderDark/>
                  </InputAdornment>
                )
              }} />
            </Box>
            <Box mb={2}>
                <TimePicker name='general.startDate' autoOk={true} okLabel="" label='Start Time' inputVariant='filled' onChange={(value) => { setFieldValue('general.startDate', value.toISOString()); if (value.toISOString() > values.general.endDate){ setFieldValue("general.endDate", value.toISOString()); }}} value={values.general.startDate} fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconClockBorderDark/>
                  </InputAdornment>
                )
              }} />
            </Box>
            <Box mb={4}> </Box>
            <Box mt={3} mb={2}>
              <DatePicker disablePast autoOk={true} okLabel="" minDate={values.general.startDate} name='general.endDate' label='End Date' inputVariant='filled' onChange={(value)=>{setFieldValue("general.endDate", value.toISOString());}} value={values.general.endDate} fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconCalendarBorderDark/>
                  </InputAdornment>
                )
              }} />
            </Box>
            <Box mb={2}>
              <TimePicker name='general.endDate' autoOk={true} okLabel="" label='End Time' inputVariant='filled' onChange={(value)=>{setFieldValue('general.endDate',value.toISOString())}} value={values.general.endDate} fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconClockBorderDark/>
                  </InputAdornment>
                )
              }} />
            </Box>
                {errors&&errors.general&&errors.general.endDate&&<FormHelperText error> The ending date/time must be later than the starting date/time </FormHelperText>}
              </Box>
            </Box>
            <Box className={classes.showMobile}>
              <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
            </Box>
          </Form>
          </Fade></>
        )}
      </Formik>
</>
})

export default withRouter(CreateEventDateTime)
