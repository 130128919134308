import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {DEV_MODE, SERVICE_URL} from 'config'
import * as SockJS from 'sockjs-client'
import * as StompJs from 'stompjs'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {useAuth} from 'auth/AuthController'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'

const styles = theme => ({

})

export const NotificationViewer = withStyles(styles)(({classes, notification})=>{
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    if (notification) {
      enqueueSnackbar(notification.message.message, {variant: 'info', autoHideDuration:10000,anchorOrigin:{vertical:'top',horizontal:'center'}})
    }
    // eslint-disable-next-line
  },[notification])

  return <> </>
})

export const NotificationSender =  withStyles(styles)(({classes, open=false, sessionId, eventId, isSession, setOpen, virtualRoomData})=>{
  const [, , , userInfo] = useAuth()
  const [notificationMessage, setNotificationMessage] = useState('')

  const chatClient = useRef()
  const chatHeaders = {
    'Content-Type': 'text/plain',
    'Authorization': `Bearer ${userInfo.access_token}`
  }

  const handleClose = ()=>{
    setOpen(false)
  }

  const handleChange =(e) => {
    const value = e.target.value
    setNotificationMessage(value)
  }
  const onConnected = () => {
    // setConnecting(false)
  }

  const onError = () => {
    // in case of error disable chat feed and input
    DEV_MODE&&console.log('error')
  }

  const connectToWs = () => {
    const socket = new SockJS(`${SERVICE_URL}/stompws`)
    chatClient.current = StompJs.over(socket)
    if (!DEV_MODE) chatClient.current.debug = null
    chatClient.current.connect(chatHeaders, onConnected, onError)
    chatClient.current.reconnect_delay = 2000
  }

  useEffect(()=>{
    connectToWs()
    return ()=>{
      if (chatClient.current&&chatClient.current.connected) chatClient.current.disconnect(null, chatHeaders)
    }
    // eslint-disable-next-line
  },[])

  const handleSend = () => {
    if (chatClient.current&&chatClient.current.connected) {
      if (virtualRoomData) {
        chatClient.current.send(`/app/online-room/${eventId}/notification`, {}, notificationMessage)
      } else {
        chatClient.current.send(isSession?`/app/session/${sessionId}/notifications`:`/app/event/${eventId}/notification`, {}, notificationMessage)
      }
    }
    setOpen(false)
  }

  useEffect(()=>{
    if (!open) {
      setNotificationMessage('')
    }
  },[open])

  return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Send a notification</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Box color={palette['greyish-brown']}>
          {`Your message will be sent ${virtualRoomData?`room`:isSession?'session':'event'} wide as a notification.`}
        </Box>
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Notification message"
        type="text"
        fullWidth
        value={notificationMessage}
        onChange={handleChange}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSend} color="primary">
        Send
      </Button>
    </DialogActions>
  </Dialog>
})

export const NotificationEventReceiver =  withStyles(styles)(({topic})=>{
  const [, , , userInfo] = useAuth()
  const [notification, setNotification] = useState()
  const [clientHandler, setClientHandler] = useState()
  const chatClient = useRef()
  const chatHeaders = {
    'Content-Type': 'text/plain',
    'Authorization': `Bearer ${userInfo.access_token}`
  }

  const onConnected = () => {
    clientHandler.subscribe(topic, onMessageReceived)
  }

  const onError = () => {
    // in case of error disable chat feed and input
    DEV_MODE&&console.log('error')
  }

  const connectToWs = () => {
    const socket = new SockJS(`${SERVICE_URL}/stompws`)
    const client = StompJs.over(socket)
    if (!DEV_MODE) client.debug = null

    chatClient.current = client
    setClientHandler(chatClient.current)
  }

  useEffect(() => {
    if (clientHandler) {
      clientHandler.connect(chatHeaders, onConnected, onError)
      clientHandler.reconnect_delay = 2000
    }

    // eslint-disable-next-line
  }, [clientHandler])

  useEffect(()=>{
    connectToWs()
    return ()=>{
      if (chatClient.current&&chatClient.current.connected) chatClient.current.disconnect(null, chatHeaders)
    }
    // eslint-disable-next-line
  },[])

  const onMessageReceived = (message) => {
    const newMessage = JSON.parse(message.body)
    if (newMessage.type===2) {
      setNotification(newMessage)
    }
  }

  return <NotificationViewer notification={notification}/>
})
