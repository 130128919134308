import React, {useState} from 'react'
import {withStyles} from '@material-ui/styles'
import {isChrome, isFirefox,browserName} from 'react-device-detect'
import {palette} from 'theme'
import {Alert} from '@material-ui/lab'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  mainBox : {
    backgroundColor: palette['very-light-pink'],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #cdcdcd',
    zIndex: 99999
  },
  appIcon: {
    borderRadius: '15%',
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    width: '48px',
    height: '48px',
    border: '1px solid #efefef',
    boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.03)',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  name: {
    padding: theme.spacing(1),
    paddingBottom: 0,
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  publisher: {
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5),
    fontSize: '12px',
    color: palette['greyish-brown'],
  },
  price: {
    padding: theme.spacing(1),
    paddingTop: 0,
    fontSize: '14px',
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  link: {
    paddingRight: theme.spacing(2),
    textAlign: 'right',
    '& a': {
      fontSize: '16px',
      color:'#0070c9'
    }
  }
})


const BrowserSuggestion = ({classes}) => {

  const [isOpen, setOpen] = useState(!localStorage.getItem('hasClosedBrowserBanner'))

  const closeBanner = () => {
    localStorage.setItem('hasClosedBrowserBanner', true)
    setOpen(false)
  }

  if (!isChrome&&!isFirefox&&isOpen) {
    return <Alert severity="info"
                  action={
                    <Button color="inherit" size="small" onClick={closeBanner}>
                      Close
                    </Button>
                  }
    >
      {`It seems like you are using ${browserName}. You might have trouble accessing certain features of Anymeets. For the best virtual experience please use Google Chrome or Mozilla Firefox.`}
    </Alert>
  }

  return <> </>
}

export default withStyles(styles)(BrowserSuggestion)
