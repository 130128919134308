import React from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import {Link} from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {
  IconCheckCircleBorderDark, IconDollarSignBorderDark, IconHelpCircleBorderDark, IconInfoBorderDark,
   IconUserBorderDark
} from 'components/core/icons'
import ListItemText from '@material-ui/core/ListItemText'

import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'

const styles = theme => ({
  avatarMobile: {
    width: '28px',
    height: '28px',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: palette['pale-sky-blue'],
  },
  fullWidth: {
    width: '100%'
  },
  fullName: {
    fontWeight: 'bold'
  },
  registerButton: {
    display: 'flex',
    height: '64px',
    width: '94px',
    textTransform: 'none'
  },
  navLinks: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    }
  },
  navLinksDrawer: {
    color: palette['greyish-brown'],
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(4),
    }
  },
})

const MobileGuestMenu = withStyles(styles)(({classes}) => {

  return  <Box className={classes.navLinksDrawer} flexDirection='column'>
    <Box display='flex' p={2} alignItems='center'>
      <IconUserBorderDark/>
      <Box pl={1} className={classes.fullName}>Hello again!</Box>
    </Box>
    <Box display='flex' width='100%'>
      <List className={classes.fullWidth}>
        <Link to='/register'>
          <ListItem button key={'Register'}>
            <ListItemIcon><IconCheckCircleBorderDark/></ListItemIcon>
            <ListItemText primary={'Register'}/>
          </ListItem>
        </Link>
        <Link to='/pricing'>
          <ListItem button key={'Pricing'}>
            <ListItemIcon><IconDollarSignBorderDark/></ListItemIcon>
            <ListItemText primary={'Pricing'}/>
          </ListItem>
        </Link>
        <Link to='/about'>
          <ListItem button key={'About'}>
            <ListItemIcon><IconInfoBorderDark/></ListItemIcon>
            <ListItemText primary={'About'}/>
          </ListItem>
        </Link>
        <a href='https://docs.anymeets.com/' target={'blank'}>
          <ListItem button key={'Support'}>
            <ListItemIcon><IconHelpCircleBorderDark/></ListItemIcon>
            <ListItemText primary={'Support'}/>
          </ListItem>
        </a>
      </List>
    </Box>
  </Box>
})

export default MobileGuestMenu
