import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    paddingTop: 16,
    borderRadius: 4,
    cursor: 'pointer',
    transition: 'background-color 200ms linear',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  link: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  imageWrapper: {
    minHeight: 160
  }
})

export default function DropCard({title,description,image, link}) {
  const classes = useStyles();

  return <Grid item container spacing={2} justify='center' alignContent='center' alignItems='center' className={classes.root}>
            <a href={link} className={classes.link}> </a>
              {image&&<Grid item container spacing={2} justify='center' alignContent='center' alignItems='center' className={classes.imageWrapper}>
                <Grid item>
                  <img src={image} alt={'Online session viewer'} width={'200px'}/>
                </Grid>
              </Grid>}
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6" component="h2" align='center'>
                  {title}
                </Typography>              </Grid>
              {description &&<Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p" align='center'>
                  {description}
                </Typography>              </Grid>}

            </Grid>

}
