import React, {useCallback, useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import SplashScreen from 'assets/splash-screen-illustration.png'
import {withStyles} from '@material-ui/core/styles'
import {Field, Form, Formik} from 'formik'
import Button from 'components/core/Button'
import {useAuth} from 'auth/AuthController'
import {palette, theme} from 'theme'
import Box from '@material-ui/core/Box'
import {Link, Redirect, useLocation, withRouter} from 'react-router-dom'
import {Typography} from '@material-ui/core'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import api from 'api'
import {useHttp} from 'api/core'
import {useSnackbar} from 'notistack'
import Page from 'components/core/Page'

const styles = () => ({
  title: {
    marginBottom: '15px',
    textAlign: 'center'
  },
  page: {
    backgroundColor: palette['pale-grey'],
    minHeight: 'calc(100vh - 112px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 48px)'
    }
  },
  subtleLink: {
    display: 'inline-block',
    fontSize: '14px',
    textDecoration: 'underline',
    color: palette['brown-grey'],
    textAlign: 'right'
  },
  otherOptions: {
    textAlign: 'center',
  },
  loginForm: {
    margin: '0 auto',
    maxWidth: '330px',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    }
  },
  splashImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

// Form Schema Validation
const Schema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('This field is required'),
  password: Yup.string().required('This field is required')
})

const Login = withStyles(styles)(({classes, history}) => {
  const [fetchLoginData, loginData, isSubmitting, hasError] = useHttp()
  const [fetchActivationData, activationData] = useHttp()
  const [credentials, setCredentials] = useState()
  const [isAuthenticated, login] = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const q = useQuery()
  const to = q.get('returnUrl')

  // If login data received, handle in Auth Controller
  useEffect(()=>{
    if (!isAuthenticated&&loginData) {
      login(loginData)
    }
  },[isAuthenticated, loginData, login, to])

  const requestActivation = (email) => {
    fetchActivationData(api.entities.account.resendConfirmation({email}))
  }

  useEffect(()=>{
    if (activationData) {
      if (activationData.sendEmailFailed) {
        enqueueSnackbar('It seems were unable to send the activation e-mail. Please try again.', {variant:'error', action:resendActivation})
      } else {
        enqueueSnackbar(`We've sent you an email. You'll have to open it up to activate your account.`, {variant: 'success'})
      }
    }
    // eslint-disable-next-line
  },[activationData])

  // eslint-disable-next-line
  const resendActivation = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={()=>{requestActivation(credentials.email)}}> Send activation e-mail </Button>,[credentials])

  useEffect(()=>{
    if (hasError) {
      if (hasError.message) {
        if (hasError.message==='User is disabled') {
          enqueueSnackbar('It seems like you haven\'t activated your account just yet.', {variant:'error', action:resendActivation})
        }
      }
    }
    // eslint-disable-next-line
  },[hasError])

  return <Page title='Login' backgroundColor={palette['pale-grey']}>
      {isAuthenticated?<Redirect to={`${to?to:'/dashboard'}`}/>:''}
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} md={4} lg={3}>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Schema}
          onSubmit={values => {
            setCredentials({...values})
            fetchLoginData(api.entities.account.login(values))
          }}
        >
          {() => (
              <Form>
                <Box className={classes.loginForm}>
                <Typography component='h2' variant='h5' className={classes.title}>Sign in</Typography>
                <Box mb={2}>
                  <Field type='email' name='email' component={TextField} label='Email' variant='filled' fullWidth disabled={isSubmitting}/>
                </Box>
                <Box mb={2}>
                  <Field type='password' name='password' component={TextField} label='Password' variant='filled' fullWidth disabled={isSubmitting}/>
                  <Box mt={1} mb={4} textAlign='right'> <Link to='/forgot-password' className={classes.subtleLink}>Forgot Password</Link> </Box>
                </Box>
                  <Box display='flex' justifyContent='center' alignContent='center'>
                    <Button type='submit' color='primary'> Submit </Button>
                  </Box>
                {/* <Box m={4} className={classes.otherOptions}>
                  New around here? <br/>
                  <Link to='/register' className={classes.subtleLink}>Create an account</Link>
                </Box> */}
                </Box>
              </Form>
          )}
        </Formik>
      </Grid>
      <Grid item className={classes.splashImage}>
        <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
          <Box display='flex'>
            <img src={SplashScreen}  alt={''}/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Page>
})

export default withRouter(Login)
