import React, {useContext, useEffect, useState} from 'react'
import 'utils'

const useAuthController = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState()

  const logout = () =>{
    setIsAuthenticated(false)
    localStorage.removeItem('access_token')
  }

  // Check access token and keep user data
  const login = (data) => {
    if (data.hasOwnProperty('access_token')) {
      setUserInfo({
        access_token: data.access_token
      })
      localStorage.setItem('access_token',data.access_token)
    }
    setIsAuthenticated(true)
  }

  useEffect( ()=>{
    if (userInfo) {
      localStorage.setItem('info', JSON.stringify(userInfo))
    }
  },[userInfo])

  // Check if user has a valid token already
  useEffect(()=>{
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      setIsAuthenticated(true)
      if (!userInfo) {
        setUserInfo(JSON.parse(localStorage.getItem('info')))
      }
    }
    // eslint-disable-next-line
  }, [])

  return [
    isAuthenticated,
    login,
    logout,
    userInfo,
    setUserInfo
  ]
}

/***
 * The actual authorization context, provides the state of authentication and methods to login and logout
 * @type {React.Context<undefined>}
 */
const authContext = React.createContext()

export const useAuth = () => {
  return useContext(authContext)
}

export const ProvideAuth = ({children}) => {
  const authHook = useAuthController()
  return <authContext.Provider value={authHook}>
    {children}
  </authContext.Provider>
}
