import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Button as MuiButton} from '@material-ui/core'
import clsx from 'clsx'
// import UpgradeOverlay from 'components/core/UpgradeOverlay'

const styles = theme => ({
 root: {
  position: 'relative',
  borderRadius: '25px',
  height: '50px',
  textTransform: 'none',
  overflow: 'hidden',
  fontWeight: 'bold'
 },
 big: {
  minWidth: '300px',
  [theme.breakpoints.down(300)]: {
   minWidth: '100%',
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 small: {
  minWidth: '200px',
  [theme.breakpoints.down(200)]: {
   minWidth: '100%',
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 smaller: {
  minWidth: 'initial',
  height: '34px'
 },
 contained: {
  color: 'white'
 },
 red:{
  '&:hover': {
   borderColor: 'red',
   backgroundColor: 'rgba(255,0,0,0.08)'
  },
  color: 'red',
  borderColor: 'red'
 },
 containedRed:{
   '&:hover': {
    borderColor: 'red',
    backgroundColor: 'rgba(255,0,0,1)'
   },
   backgroundColor: 'rgba(255,0,0,0.8)',
   color: 'white',
   borderColor: 'red'
  },
 white:{
  color: 'white',
  borderColor: 'white',
  '&:hover': {
   borderColor: 'white',
   backgroundColor: 'rgba(255,255,255,0.08)'
  },
 },
 fillWidth: {
  minWidth: '136px',
  top: '-1px'
 },
 backgroundWhite: {
  backgroundColor: 'white',
  '&:hover': {
   backgroundColor: 'white'
  }
 },
 autoWidth: {
  minWidth: 'initial',
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4)
 }
})

const Button = withStyles(styles)(({classes, children, size='big', eventId, autoWidth, icon, background, fillWidth, upgradeTier, variant='contained', colour='', color='secondary', ...restProps}) => {
 const buttonClasses = clsx({
  [classes.root]: true,
  [classes.contained]: variant === 'contained',
  [classes.red]: colour === 'red',
  [classes.containedRed]: colour === 'red' && variant === 'contained',
  [classes.white]: colour === 'white',
  [classes.big]: size === 'big',
  [classes.small]: size === 'small',
  [classes.smaller]: size === 'smaller',
  [classes.fillWidth]: size === 'smaller' && fillWidth,
  [classes.backgroundWhite]: background==='white',
  [classes.autoWidth]: autoWidth
 })

 return <>
  <MuiButton disableElevation variant={variant} color={color} {...restProps} className={buttonClasses}>
   {icon&&<>{icon} &nbsp;</>}
    {children}
   {/* {upgradeTier&&<UpgradeOverlay eventId={eventId}/>} */}
  </MuiButton>
 </>
})

export default Button
