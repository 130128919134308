import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {Typography} from '@material-ui/core'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import Grid from '@material-ui/core/Grid'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {withRouter} from 'react-router-dom'
import Map from 'components/core/Map'
import MapSearchBox from 'components/core/MapSearchBox'
import {LOCATION_DEFAULT_MAP_LOCATION,TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import Switch from '@material-ui/core/Switch'
import parse from "html-react-parser";


const styles = (theme) => ({
  form: {
    width: "100%",
    color: "white",
  },
  title: {
    textAlign: "center",
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  showMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  anyurl: {
    marginRight: "-5px",
    verticalAlign: "revert",
    fontWeight: "600",
    lineHeight: "41px",
    height: "40px",
    display: "inline-block",
    padding: "5px",
    background: "#ddd",
    position: "relative",
    boxShadow: "-3px 8px 12px 0 rgb(0 0 0 / 10%)",
    borderRadius: "5px 0 0 5px",
    zIndex: "1",
    color: "#4a4a4a",
  },
});

const initialAddress = {
  postal_code: '',
  address: '',
  country: '',
  city: ''
}

const initialLatLng = {
  lat: LOCATION_DEFAULT_MAP_LOCATION.lat,
  lng: LOCATION_DEFAULT_MAP_LOCATION.lng
}

const LocationBasedSchema = Yup.object().shape({
  general: Yup.object().shape({
    venueName: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    online: Yup.string().required('This field is required')
  })
})

const slugSchema = Yup.object().shape({
  general: Yup.object().shape({
    slug: Yup.string().required('This field is required')
  })
})

const CreateEventLocation = withStyles(styles)(({classes, formData, setFormData, prevRoute, nextRoute, history}) => {
  const [address, setAddress] = useState(initialAddress)
  const [latLng, setLatLng] = useState(initialLatLng)
  const [schema, setSchema] = useState(formData.general.online ? slugSchema : LocationBasedSchema);

  const DummyUpdateComp = ({setFieldValue}) => {

    useEffect(()=>{
      if (latLng) {
        setFieldValue('general.venueLat',latLng.lat)
        setFieldValue('general.venueLon',latLng.lng)
      }
    },[setFieldValue])

    useEffect(()=>{
      if (address) {
        setFieldValue('general.address',address.address)
        setFieldValue('general.zipCode',address.zipCode)
        setFieldValue('general.city',address.city)
        setFieldValue('general.country',address.country)
      }
    },[setFieldValue])

    return <> </>
  }

  const setOnline = (value, setFieldValue) => {
    setFieldValue('general.online', value)
    setSchema(value ? slugSchema : LocationBasedSchema );
  }

  return <>
      <Formik
        initialValues={formData}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting}) => {
          setFormData({...formData, ...values, })
          history.push(nextRoute)
        }}>
        {({isSubmitting, setFieldValue, values, submitForm, errors, touched}) => (<>
            <Box className={classes.hideMobile}>
              <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
            </Box>
          <Fade in={true} timeout={TIME_FADE_IN}><Form className={classes.form}>
            <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={4} minHeight={window.innerHeight-280}>
              <Box display='flex' width='800px' flexDirection='column' pb={8}>
            <Typography component='h2' variant='h5' className={classes.title}>Event Location</Typography>
            <Box mt={3} mb={4} textAlign='center'>
                {/* Does the event have a physical venue? */}
                Is this an online only event?
              <Switch disabled={isSubmitting} onChange={(e)=>{setOnline(e.target.checked, setFieldValue)}} checked={values.general.online}/>
            </Box>
                {values.general.online&&<>
                <Box mb={2} mt={14}>
                  <Grid container>
                    <Grid item xs='auto' md={1} lg={2}> </Grid>
                    <Grid item xs={12} md={10} lg={10}>
                 
                    
                      <Typography variant="p" className={classes.anyurl}
                      >https://www.anymeets.com/</Typography>
                      <Field
                        type="text"
                        size="small"
                        name="general.slug"
                        component={TextField}
                        label="Custom URL"
                        variant="filled"
                        disabled={isSubmitting}
                        style={{maxWidth: "410px"}}
                        fullWidth
                      />
                      <p>
                        <i> {parse("Your event will be assigned the URL: <b>anymeets.com/my-event.</b><br/>This cannot be changed once it has been assigned.")}</i>
                      </p>
                     
                                      
                      {/* <Field type='text' name='general.conferenceLink' component={TextField} label='Conference link' variant='filled' fullWidth disabled={isSubmitting}/> */}
                    </Grid>
                  </Grid>
                </Box>

                </>}
                {!values.general.online&&<>
            <Box mb={2}>
              <Box mb={2}><Grid container>
                <Grid item xs={4}>
                  Type address, city or Zip code to locate venue in map
                </Grid>
                <Grid item xs={8}>
                  <MapSearchBox setLatLng={setLatLng} setVenueName={(name)=>{setFieldValue('general.venueName',name)}}/>
                </Grid>
              </Grid>
              </Box>
              <Box>
                <div style={{height: '250px', width: '100%'}}>
                  <Map address={address} setAddress={setAddress} latLng={latLng} setLatLng={setLatLng} height='250px' />
                  <DummyUpdateComp setFieldValue={setFieldValue}/>
                </div>
              </Box>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  Fill in address details if necessary
                </Grid>
                <Grid item xs={4}>
                  <Field type='text' name='general.venueName' component={TextField} label='Venue name' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={4}>
                  <Field type='text' name='general.venueDetails' component={TextField} label='Venue Details'
                         variant='filled' fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={4}> </Grid>
                <Grid item xs={5}>
                  <Field type='text' name='general.address' component={TextField} label='Address' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={3}>
                  <Field type='text' name='general.zipCode' component={TextField} label='Zip Code' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={4}> </Grid>
                <Grid item xs={3}> <Field type='text' name='general.city' component={TextField} label='City'
                                          variant='filled' fullWidth disabled={isSubmitting}/> </Grid>
                <Grid item xs={5}> <Field type='text' name='general.country' component={TextField} label='Country'
                                          variant='filled' fullWidth disabled={isSubmitting}/> </Grid>
              </Grid>
            </Box>
                </>}
              </Box>
            </Box>
            <Box className={classes.showMobile}>
              <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
            </Box>
          </Form></Fade></>
        )}
      </Formik>
</>
})

export default withRouter(CreateEventLocation)
