import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Page from 'components/core/Page'
import Hero from 'components/core/Hero'
import SubFooter from 'components/core/Footer/SubFooter'
import Button from 'components/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { IconCheckCircled } from 'components/core/icons'


const styles = theme => ({
  root: {
    width: theme.spacing(96),
  },
  contactTitle: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing: '-0.5px'
  },
  contactMoto: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '0.2px'
  },
  pageContent: {
    marginTop: "50px",
  },
  pricingTitle: {
    fontFamily: "Nunito , Karla",
    fontSize: '48px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000',
    display: 'block',
    padding: '20px 0',
    "@media (max-width: 768px)": {
      padding: '38px 0',
      fontSize: '34px'
    }
  },
  MuiSvgIcon: {
    position: 'absolute',
    bottom: '6px',
    right: '7px',
    fontSize: '18px',
  },

  tab: {
    '& .MuiTabPanel': {
      padding: '17px 0',
    }
  },
  MuiListItem: {
    padding: 0,
    color: '#666666',
  },
  iconChecked: {
    fontSize: '0.9em',
    marginRight: '12px',
    position: 'absolute',
    top: '7px',
    left: '-2px'
  },
  label: {
    display: 'block'
  },
  typeTitle: {
    margin: '15px 10px 40px',
    fontSize: '16px',
    textAlign: 'justify',
    lineHeight: '30px'
  },
  listText: {
    fontSize: '15px',
  }
})

const Pricing = withStyles(styles)(({ classes }) =>
{
  return <>
    <Page title='Pricing'>
      <Grid item xs={12} className={classes.pageContent}>
        <span className={classes.pricingTitle}>Anymeets pricing</span>
      </Grid>
      <Container style={{ maxWidth: '1024px' }}>
        <Box marginBottom={'0px'}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: '0 29px 0 0', textAlign: 'center', }}>
              <Box sx={{ width: '100%', maxWidth: '768px', display: 'inline-block', textAlign: 'left', typography: 'p', fontSize: "10px", padding: '30px 20px 10px' }}>
                <Typography className={classes.typeTitle} align={'center'}>Organizing a medium size event is free at Anymeets. We don’t lock you in subscription plans. You only pay as you go according to your needs. A typical event at Anymeets is for FREE.</Typography>
                <Typography align={'left'}>Here is what you get:</Typography>
                <List style={{ paddingBottom: "50px" }}>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="Unlimited collaborators/sessions/days / free tickets" />
                  </ListItem>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="All live streaming options except tokbox are free*" />
                  </ListItem>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="Paper submission workflows: You can now handle the paper/poster submission workflows through the anymeets platform. The first  40 submissions are free. For every extra submission that you approve to be reviewed, you pay $3. If you have junk submissions that you want to delete, you will not be charged. Also, you don’t need to buy credits ahead of time. Submissions will be accepted on the platform until the deadline, regardless of the number of credits you have. If after the deadline you have 60 submissions and you reject 10 as irrelevant then you buy 10 credits so that you can move on to the review of the remaining 50 papers." />
                  </ListItem>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="If you want to have a gallery with posters the first 40 posters are free. Every additional poster costs $8." />
                  </ListItem>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="You can use anymeets to contact your attendees via email. The first 500 email messages are free. The cost for every additional email is  $0.1" />
                  </ListItem>
                  <ListItem disablePadding alignItems='flex-start'>
                    <Box mr={2}><IconCheckCircled /> </Box>
                    <ListItemText classes={{ primary: classes.listText }} primary="You get unlimited registration for free events or for events that we don’t handle the registrations. If you choose to use anymeets for paid registrations the cost is $3 commission/ticket + Stripe charges" />
                  </ListItem>
                </List>
                <Typography className={classes.typeTitle} align={'center'}>*Tokbox is a high-end streaming service that offers zero latency. It is more expensive than zoom, but it is embedded into our platform which means that the users never leave our site. You have more control over analytics on the attendance of the conference. Also if you heavily rely on sponsor views, using tokbox ensures that the users watch all the messages transmitted during the talks.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
    <Hero backgroundColor={'#107AAD'} minHeight={400}>
      <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
        <Grid item lg={12}>
          <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
        </Grid>
        <Grid item lg={8}>
          <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a
            demo</Typography>
        </Grid>
        <Grid item>
          <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
        </Grid>
      </Grid>
    </Hero>
    <SubFooter />
  </>
})

export default Pricing
