import React, {useState} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Dialog from '@material-ui/core/Dialog'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Button from 'components/core/Button'
import {TIME_FADE_IN} from 'config'

const styles = theme => ({
  container: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    width: '100%',
    // maxWidth: '430px',
    paddingTop: '40px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      maxWidth: 'initial'
    }
  },
  editor: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    '& .ReactCrop': {
      margin: '0 auto',
      boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    }
  },
  btns: {
    textAlign: 'center'
  }
})

const getCroppedImg = (image, crop, fileName, size) => {
  const width = crop.width<size.width?size.width:crop.width
  const height = crop.height<size.width?size.height:crop.height
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height,
  )

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = fileName
      resolve(blob)
    })
  })
}

const CropImage = withStyles(styles)(({classes, file, image, size, open=false, setOpen, setImage}) => {
  const [crop, setCrop] = useState({
    aspect : size.width/size.height,
    width: size.width
  })
  const [imageCropped, setImageCropped] = useState(null)

  const save = async () => {
    const croppedImage = await getCroppedImg(imageCropped, crop, file.name, size)
    setImage(croppedImage)
  }

  const cancel = () => {
    setOpen(false)
  }

  const onImageLoaded = image => {
    setImageCropped(image)
  }

  return <>
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <Grid container className={classes.container}>

          <Grid xs={12}>
            <Typography variant='h5' component='h1'> Crop the image for upload </Typography>
          </Grid>

          <Grid xs={12}>
              <Box className={classes.editor}>
               <Fade in={true} timeout={TIME_FADE_IN}>
                 <ReactCrop keepSelection={true}
                src={image} crop={crop} onChange={newCrop=>setCrop(newCrop)} onImageLoaded={onImageLoaded} />
               </Fade>
              </Box>
          </Grid>
          <Grid item md={3} xs='auto'> </Grid>
          <Grid container item md={6} spacing={3} className={classes.btns}>
            <Grid item xs={12} md={6}>
              <Button variant='outlined' size='small' colour='white' onClick={()=>cancel()}> Cancel </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button size='small' type='submit' onClick={()=>save()}> Save Changes </Button>
            </Grid>
          </Grid>

        </Grid>
      </Dialog>
    </>
})

export default CropImage
