import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const Hero = ({classes, animate, tagLine, image, children, minHeight = 700, title, taglineMarginTop = 6,subtitle,backgroundColor = '#f8f8f8', pt=0}) => {

  const styles = theme => ({
    hero: {
      minWidth: '100%',
      minHeight: minHeight,
      backgroundSize: 'cover',
      backgroundImage: `url(${image})`,
      backgroundColor: backgroundColor,
      [theme.breakpoints.down('xs')]: {
        minHeight: 'initial',
        height: 'calc(100vh - 64px)'
      }
    },
    tagLine: {
      maxWidth: '590px',
      color: 'white',
      fontSize: '46px',
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: theme.spacing(taglineMarginTop),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        padding: theme.spacing(3),
        marginBottom: 0,
      }
    },
    title: {
      maxWidth: '590px',
      color: '#666666',
      fontSize: '46px',
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        padding: theme.spacing(1),
        marginBottom: 0,
      }
    },
    subtitle: {
      maxWidth: '450px',
      color: '#666666',
      fontSize: '24px',
      textAlign: 'center',
      fontWeight: 'normal',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        padding: theme.spacing(3)
      }
    },
  })

  const HeroBox = withStyles(styles)(({classes, tagLine, children}) => {
    return <Grid container className={classes.hero} direction='column' justify='center' alignItems='center'>

      {tagLine  &&
      <Grid item className={classes.tagLine}>
        {tagLine}
      </Grid>}

        {/*{tagLine && animate && <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={300} offset={0}>*/}
        {/*<Grid item className={classes.tagLine}>*/}
        {/*  {tagLine}*/}
        {/*</Grid>*/}
        {/*</ScrollAnimation>}*/}

      {title &&
      <Grid item className={classes.title}>
        {title}
      </Grid>}
      {subtitle &&
      <Grid item className={classes.subtitle}>
        {subtitle}
      </Grid>}
      <Grid item lg={10} style={{marginBottom: '-4px'}}>
        {children}
      </Grid>
    </Grid>
  })

  return <HeroBox tagLine={tagLine} children={children} minHeight={minHeight}/>
}

export default Hero
