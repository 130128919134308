import React, {useEffect, useState} from 'react'
import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import {palette} from '@material-ui/system'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import {useHttp} from 'api/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import RoomsDialog from 'components/core/RoomsDialog'
import api from 'api'
import {IconAnymeets, IconHangouts, IconZoom} from 'components/core/icons'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Button from '@material-ui/core/Button'
import {theme} from 'theme'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
const styles = theme => ({
  container: {
    // padding: theme.spacing(2),
    borderRadius: '6px',
    width: 320,
    overflow: 'hidden',
    border: `1px solid ${palette['very-light-pink']}`
  },
  edit: {
    marginLeft: '-20px'
  }
})

const Rooms = withStyles(styles)(({classes, setEditRoom, setNewRoom, eventId, session, canManage, isSponsor, sponsorId})=>{

  const [rooms, setRooms] = useState()
  const [getRoomData, roomData ] = useHttp()

  useEffect(()=>{
    // getData and set rooms


    if (isSponsor&&sponsorId) {
      getRoomData(api.entities.virtualRooms.getAllForSponsor(null,null,{id:sponsorId}))
    } else if (session) {
      getRoomData(api.entities.virtualRooms.getAllBySession(null,null,{id:session.id}))
    } else {
      getRoomData(api.entities.virtualRooms.getAllByEvent(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (roomData) {
      setRooms(roomData)
    }
  },[roomData])

  //TODO: Handle error

  if (!rooms) {
    return <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height={'100%'}>
      <Box display='flex' p={6}>
        <CircularProgress size={'5rem'} color='secondary' />
      </Box></Box>
  }

  return <Grid container spacing={2}>

    {session&&session.role!==1&&<Grid item container justify='flex-end' alignItems='center' alignContent='center'>
      <Grid item>
        <Box pt={2} pr={2}>
          <Button variant='outlined' size='small' color='secondary' onClick={()=>setNewRoom(true)}>{session?'Create breakout room':'Create room'}</Button>
        </Box>
      </Grid>
    </Grid>}

    {(!session)&&canManage&&<Grid item container justify='flex-end' alignItems='center' alignContent='center'>
      <Grid item>
        <Box pt={2} pr={2}>
          <Button variant='outlined' size='small' color='secondary' onClick={()=>setNewRoom(true)}>{session?'Create breakout room':'Create room'}</Button>
        </Box>
      </Grid>
    </Grid>}

    <Grid item xs={12}>
      {rooms&&rooms.length===0&&<>
          <Box p={2} pb={3} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
            <Box display='flex'> No room available </Box>
          </Box>
      </>}

      <List disablePadding>
        {rooms.map((room,i)=>{
          return <ListItem button component='a' href={`/virtual-room/${room.code}`} target='_blank'>
            <ListItemIcon>
              {room.type===1&&<IconAnymeets/>}
              {room.type===2&&<IconZoom/>}
              {room.type===3&&<IconHangouts/>}
            </ListItemIcon>
            <ListItemText
              primary={room.label}
              // secondary={secondary ? 'Secondary text' : null}
            />

            {room.canManage&&<ListItemSecondaryAction >
              <IconButton edge="end" aria-label="join" onClick={()=>setEditRoom(room.id)}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>}

          </ListItem>
        })}
      </List>
    </Grid>
  </Grid>

},[])

const RoomsButton = withStyles(styles)(({classes, eventId, session, canManage, isSponsor, sponsorId, isHost}) => {

  const [editRoom, setEditRoom] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [newRoom, setNewRoom] = useState()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseRoom = (refresh) => {
    setEditRoom(false)
    setNewRoom(false)
  }

  const setEditRoomId = (id) => {
    setEditRoom(id)
    handleClose()
  }

  const setNewRoomToggle = (value) => {
    setNewRoom(value)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  
  return <>
    {(editRoom||newRoom)&&<RoomsDialog open={(!!editRoom)||newRoom} isSponsor={isSponsor} sponsorId={sponsorId} roomId={editRoom} handleClose={handleCloseRoom} key={editRoom} eventId={eventId} newRoom={newRoom} session={session} />}
    <Button aria-describedby={id} variant="outlined" size='small' color="primary" startIcon={<ChatBubbleOutlineIcon />} onClick={handleClick}>
      {session?'Breakout rooms':'Rooms'}
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 200,
        horizontal: 'center',
      }}
    >
      <Box className={classes.container}>
       <ThemeProvider theme={theme}><Rooms setEditRoom={setEditRoomId} eventId={eventId} setNewRoom={setNewRoomToggle} session={session} canManage={canManage||isHost} isSponsor={isSponsor} sponsorId={sponsorId} /></ThemeProvider>
      </Box>
    </Popover>
  </>
})

export default RoomsButton
