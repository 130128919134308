import React, {useEffect, useRef, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import CloseIcon from '@material-ui/icons/Close';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import {imageFile} from 'utils'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {useHttp} from 'api/core'
import api from 'api'
import {IconAnymeets, IconHangouts, IconZoom} from 'components/core/icons'

const styles = theme => ({
  paper: {
    backgroundColor: 'white',
    color: palette['greyish-brown']
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    borderRadius: '6px',
  },
  listBox: {
    borderRadius: '6px',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    minHeight: 250
  },
  avatar: {
    position: 'relative',
    minWidth: theme.spacing(6)
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  publicOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.2)',
    textAlign: 'center',
    fontSize: 24,
    zIndex: 222
  },
  faded: {
    opacity: 0.2
  },
  attendeesContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingTop: theme.spacing(2)
  },
  tabsBar: {
    backgroundColor: palette['very-light-pink']
  },
  hostButton:{
    marginRight:theme.spacing(4)
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RoomsDialog = withStyles(styles)(({classes, open, roomId, newRoom,isSponsor,sponsorId, handleClose, eventId, session}) => {

  const [room, setRoom] = useState()
  const [roomType, setRoomType] = useState(1)
  const [loadingAttendees, setLoadingAttendees] = useState()
  const [isPublic, setPublic] = useState(false)
  const [isEnabled, setEnabled] = useState(true)
  const [zoomLink, setZoomLink] = useState('')
  const [hangoutsLink, setHangoutsLink] = useState('')
  const [options, setOptions] = useState([])
  const [attendees, setAttendees] = useState([])
  const [hosts,setHosts] = useState([])
  const [inputValue, setInputValue] = useState()
  const [waitList, setWaitList] = useState()
  // eslint-disable-next-line
  const [getRoomData, roomData, isLoading, hasError] = useHttp()
  // eslint-disable-next-line
  const [saveRoom, roomSaveData, isLoadingSave, hasErrorSave] = useHttp()
  const [getAttendees, attendeesData, isLoadingAttendees] = useHttp()

  const searchTimeout = useRef()

  useEffect(()=>{
    if (roomId) {
      getRoomData(api.entities.virtualRooms.getOne(null,null,{id:roomId}))
    } else if (newRoom) {
      setRoom({
        type: 1,
        'label': '',
        'link': '',
        enabled: true,
        public: false,
        capacity: 0
      })
    }
    // eslint-disable-next-line
  },[roomId])

  useEffect(()=>{
    if (roomData) {
      setRoom(roomData)
      setRoomType(roomData.type)
      setEnabled(roomData.enabled)
      setPublic(roomData.public)
      setAttendees(roomData.invitees?roomData.invitees:[])
      setHosts(roomData.hosts?roomData.hosts:[])
      setWaitList(roomData.capacity!==0)
      if (roomData.type===2) {
        setZoomLink(roomData.link)
      } else if (roomData.type===3) {
        setHangoutsLink(roomData.link)
      }
    }
  },[roomData])

  const setType = (e, value) => {
    setRoomType(value)
  }

  const onAttendeeSelectChange =(e,t,r) => {
    // console.log(t)
    // console.log(r)
    if (r==='select-option') {
      if (!attendees.find((a)=>a.id===t.id)) {
        const newAttendees = [...attendees]
        newAttendees.push(t)
        setAttendees(newAttendees)
      }
    }
  }

  const onAttendeeInputChange = (e,t,r) => {
    // console.log(t)
    // console.log(r)

    if (t.length>2) {
      clearInterval(searchTimeout.current)
      searchTimeout.current = setTimeout(()=>{
        if (session) {
          getAttendees(api.entities.events.searchAttendeesInSession(null,{term:t},{id:session.status.onlineRoomId}))
        } else {
          getAttendees(api.entities.events.searchAttendees(null,{term:t},{id:eventId}))
        }
      },600)
      setLoadingAttendees(true)
    } else if (t.length===0) {
      clearInterval(searchTimeout.current)
      setOptions([])
      setLoadingAttendees(false)
    }
  }

  useEffect(()=>{
    if (attendeesData) {
      setLoadingAttendees(false)
      setOptions(attendeesData)
    }
  },[attendeesData])

  const onInputValueChange = (e) => {
    setInputValue('')
  }

  const removeIndex = (i) => {
    const newAttendees = [...attendees]
    newAttendees.splice(i, 1)
    setAttendees(newAttendees)
  }

  const removeHostIndex = (i) => {
    const newHosts = [...hosts]
    newHosts.splice(i, 1)
    setHosts(newHosts)
  }

  const save = () => {

    const payLoad = {
      ...room,
      enabled: isEnabled,
      public: session?true:isPublic,
      type: roomType,
      link: roomType===1?'':room.link,
      invitees: attendees.map((a)=>a.id),
      hosts:hosts.map((h)=>h.id)
    }

    if (newRoom) {

      if (isSponsor) {
        saveRoom(api.entities.virtualRooms.addForSponsor(payLoad,null,{id:sponsorId}))
        return
      }

      if (session) {
        saveRoom(api.entities.virtualRooms.addSession(payLoad,null,{id:session.id}))
      } else {
        saveRoom(api.entities.virtualRooms.add(payLoad,null,{id:eventId}))
        return
      }

    } else {
      saveRoom(api.entities.virtualRooms.save(payLoad,null,{id:roomId}))
    }
  }

  useEffect(()=>{
    if (roomSaveData) {
      handleClose(true)
    }
    //eslint-disable-next-line
  },[roomSaveData])

  const handleChangeName = (e) => {
    const value = e.target.value
      // .replace(/\W/g, '')
    // setRoom({...room, label: value})
    let regexp = /^[a-zA-Z0-9-_ ]+$/;
    if ((value.search(regexp) !== -1) || value==='') setRoom({...room, label: value})
  }

  const handleChangeRoomURLZoom = (e) => {
    const value = e.target.value
    setRoom({...room, link: value})
    setZoomLink(value)
  }

  const handleChangeRoomURLHangouts = (e) => {
    const value = e.target.value
    setRoom({...room, link: value})
    setHangoutsLink(value)
  }

  const changeCapacity = (e) => {
    const value= e.target.value
    if (!isNaN(parseInt(value))) {
      setRoom({...room, capacity: parseInt(value)})
    } else {
      setRoom({...room, capacity: parseInt(0)})
    }
  }

  const handleChangeHost
    = (host,position,isAttendee) =>{
    if (!isAttendee) {
      const newAttendees = [...attendees]
      newAttendees.push(host)
      setAttendees(newAttendees)
      const newHosts = [...hosts]
      newHosts.splice(position, 1)
      setHosts(newHosts)
    }
    else
    {
      const newAttendees = [...attendees]
      newAttendees.splice(position, 1)
      setAttendees(newAttendees)
      const newHosts = [...hosts]
      newHosts.push(host)
      setHosts(newHosts)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='visibility-selection'
      classes={{paper:classes.paper}}
    >
      {!room&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height={'100%'}>
        <Box display='flex' p={20}>
          <CircularProgress size={'5rem'} color='secondary' />
        </Box></Box>}

      {room &&
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position='static' color='secondary' className={classes.tabsBar}>
              <Tabs value={roomType} onChange={setType} aria-label='room types' variant="scrollable" scrollButtons="auto">
                <Tab icon={<IconAnymeets />} label='Anymeets Live' value={4} />
                <Tab icon={<IconAnymeets />} label='Anymeets' value={1} />
                <Tab icon={<IconZoom />} label='Zoom' value={2} />
                <Tab icon={<IconHangouts />} label='Hangouts' value={3} />
              </Tabs>
            </AppBar>
            <TabPanel value={roomType} index={1}>

            </TabPanel>
            <TabPanel value={roomType} index={2}>
              <TextField fullWidth label={'Zoom Link'} value={zoomLink} variant='outlined' onChange={handleChangeRoomURLZoom}/>
            </TabPanel>
            <TabPanel value={roomType} index={3}>
              <TextField fullWidth label={'Hangouts Link'} value={hangoutsLink} variant='outlined' onChange={handleChangeRoomURLHangouts}/>
            </TabPanel>
            <TabPanel value={roomType} index={4}>

            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={'Room name'} variant='outlined' value={room.label} onChange={handleChangeName}/>
          </Grid>

          <Grid item container justify='center' alignContent='center' alignItems='center' >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEnabled}
                    onChange={(e)=>setEnabled(e.target.checked)}
                    name='enabled'
                    color='primary'
                  />
                }
                label='Enabled'
              />
            </Grid>

            {!session&&<Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublic}
                    onChange={(e)=>setPublic(e.target.checked)}
                    name='public'
                    color='primary'
                  />
                }
                label='Public'
              />
            </Grid>}

            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={waitList}
                    onChange={(e)=>{
                      setWaitList(e.target.checked)
                      if (!e.target.checked) {
                        setRoom({...room, capacity: 0})
                      }
                    }}
                    name='WaitList'
                    color='primary'
                  />
                }
                label='Wait list'
              />
            </Grid>

            <Grid item xs={2}>
              <TextField fullWidth label={'Capacity'} value={room.capacity} onChange={changeCapacity} variant='outlined' disabled={!waitList}/>
            </Grid>
          </Grid>

          {<Grid item container className={classes.attendeesContainer}>

            <Box display='flex' flexDirection='column' position='relative' width={'100%'} mb={2}>
              <Grid item xs={12}>
                <Autocomplete
                  key={attendees.length}
                  id='attendee'
                  options={options}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 300 }}
                  renderInput={params => <TextField {...params} label='Search attendees' variant='outlined' value={inputValue} onChange={onInputValueChange}/>}
                  onChange={onAttendeeSelectChange}
                  onInputChange={onAttendeeInputChange}
                  loading={loadingAttendees||isLoadingAttendees}
                />
              </Grid>
              <Box p={1}> </Box>
              <Grid item xs={12}>
                <List className={classes.listBox}>
                  {hosts.map((h,i)=>{
                    return <ListItem key={i}>
                      <ListItemAvatar className={classes.avatar}>
                        <Avatar alt={`${h.name}`}
                                src={h.imageId?`${imageFile(h.imageId)}`:null}
                                className={classes.smallAvatar} />
                      </ListItemAvatar>
                      <ListItemText>
                        {h.name}
                      </ListItemText>
                      <ListItemSecondaryAction className={classes.hostButton}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!hosts.find((host) => host.id === h.id)}
                              onChange={(e)=>handleChangeHost(h,i,e.target.checked)}
                              name='enabled'
                              color='primary'
                            />
                          }
                          label='Host'
                        />
                      </ListItemSecondaryAction>
                      <ListItemSecondaryAction >
                        <IconButton edge='end' aria-label='join' onClick={()=>removeHostIndex(i)} >
                          <CloseIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  })}
                  {attendees.map((a,i)=>{
                    return <ListItem key={i}>
                      <ListItemAvatar className={classes.avatar}>
                        <Avatar alt={`${a.name}`}
                                src={a.imageId?`${imageFile(a.imageId)}`:null}
                                className={classes.smallAvatar} />
                      </ListItemAvatar>
                      <ListItemText>
                        {a.name}
                      </ListItemText>
                      <ListItemSecondaryAction className={classes.hostButton}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={false}
                              onChange={(e)=>handleChangeHost(a,i,e.target.checked)}
                              name='enabled'
                              color='primary'
                            />
                          }
                          label='Host'
                        />
                      </ListItemSecondaryAction>
                      <ListItemSecondaryAction >
                        <IconButton edge='end' aria-label='join' onClick={()=>removeIndex(i)} >
                          <CloseIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  })}
                  {attendees.length===0&&<Box p={2} minHeight={210} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
                    <Box display='flex'> No attendees selected </Box>
                  </Box>}
                </List>
              </Grid>
              <Grid item container>

              </Grid>
            </Box>


          </Grid>}

          <Grid item container justify='center' alignContent='center' alignItems='center' spacing={2}>
            <Grid item>
              <Button variant='outlined' color='secondary' disabled={isLoadingSave} onClick={()=>handleClose()}> Cancel </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary' onClick={()=>save()} disabled={isLoadingSave}> Save </Button>
            </Grid>
          </Grid>

        </Grid>
      </Box>
      }
    </Dialog>
  )
})

export default RoomsDialog
