import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete'
import {TextField as MUITextField} from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import {palette} from 'theme'

const styles = () => ({
  suggestions: {
    position: 'absolute',
    zIndex: 3,
    color: palette['brown-grey'],
    backgroundColor: 'white',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '6px'
  },
  autoCompleteBox: {
    position: 'relative',
  }
})

const MapSearchBox = withStyles(styles)(({classes, setLatLng, setVenueName, setVenueAddress}) => {

  const InputComponent = (props) => <MUITextField label='Search location' variant='filled' fullWidth {...props} />

  const SuggestionsComponent = () => (active, suggestions, onSelectSuggestion) =>
  {
    return <Box className={classes.suggestions}>
      <List dense={false}>
      {
        suggestions.map((suggestion,i) => (<ListItem button key={i} onClick={(event) => onSelectSuggestion(suggestion, event)}>
            <ListItemText primary={suggestion.description} />
          </ListItem>
        ))
      }
        </List>
    </Box>
  }

  const selectPlace = (result) =>
  {
    if (["route", "geocode"].some(item => result.types.includes(item)))
    {
      setVenueAddress && setVenueAddress(result.terms[0].value) 
      console.log(result)
    }
    else
    { 
      setVenueName && setVenueName(result.terms[0].value) // Set venue name is a guess- we do not have a "place name" 
      console.log(result)
    }
    geocodeByPlaceId(result.place_id).then((results) => {
      getLatLng(results[0]).then(({lat, lng}) => {
        setLatLng({lat,lng})
      })
    })
  }

  return <Box className={classes.autoCompleteBox}>
    <GooglePlacesAutocomplete
      debounce={1000}
      loader={<></>}
      onSelect={(result) => {
        selectPlace(result)
      }}
      renderInput={InputComponent}
      renderSuggestions={SuggestionsComponent()}
    />
  </Box>

})

export default MapSearchBox
